import React from 'react';
import { Link } from 'react-router-dom';
import HeaderVR from '../common/HeaderVR'
class Philanthropy extends React.Component {

  constructor(props) {
    super(props);   
    this.state = {     
 
    };
  
  }
 


    render() {

      
        return (
   
          
            <div className="w-bg FrontEnd Newskyward-design" style={{height:'100vh'}} ref={this.myRef}>
             <HeaderVR/> 
             <div className="conSnapScroll" >
                  
             <div className="showroom-section Join-section" style={{background:'url(/default.png) no-repeat  center center', backgroundSize:'cover'}}>
                    <div className="caption">
                                 {/* <img src="/virtual-logo.png" className="mb-4"/> */}
                                 <iframe width="95%" height="90%" src="https://skyward360tours.com/tour/4465-Douglas-Ave-8H-Riverdale-NY/public/4f6f62ed-6aed-4d09-87f0-98f3c5cc7439/282e1b06-3ebc-417a-9fd1-ed604a4a2eaa" frameborder="0"></iframe>
                   </div>
                    </div>

                    <div className="bgstrip tours-section">
                        <div className="row clearfix">
                             <div className="col-lg-4 img-block">
                                
                                        <img src="/mb1.png"/>
                                                              
                            </div>
                            <div className="col-lg-8">
                            <div className="text-block">
                                <p className="title"> Launch your business into the future with ultra-high definition 3D tours. Welcome to the future of real estate.</p>

                             <div className="row">
                                 <div className="col-md-6">
                                      <div className="d-flex align-items-center">
                                          <img src="/tour2-img.png"/>
                                          <div class="ml-3 headtxt">
                                          THE MOST<br/>
                                                ACCESSIBLE<br/>
                                                HOME TOUR<br/>
                                                APP ON THE<br/>
                                                MARKET
                                          </div>
                                       </div> 
                                       <p className="info">No 360 camera? No problem. Our app works seamlessly with panoramic photos taken on your smartphone, making it easy for anyone to create tours with no extra equipment.</p> 

                                 </div>

                                 <div className="col-md-6">
                                      <div className="d-flex align-items-center">
                                          <img src="/tour1-img.png"/>
                                          <div class="ml-3 headtxt">
                                          FAST, USER-FRIENDLY,<br/>
                                            AND<br/>
                                            RESPONSIVE  
                                          </div>
                                       </div> 
                                       <p className="info">Create listings with just a few
clicks and share them instantly
to clients via SMS, social media,
or email, with unique URLs for
each address.</p> 

                                 </div>
                             </div>   
                              
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-2 joinInfo" id="experience">
                    <div className="container workbody" >
                          
                          
                            <div className="experience-section jointheGiving">
                                <div className="exp-content">
                                <h1>SUBSCRIPTION PRICING</h1>
                                         <div className="row">
                                           <div className="col-md-4 infoWrap">                                            
                                               <h3>FREE</h3>
                                               <div className="price-widget">
                                                   <span className="price">$ 0</span>
                                                  
                                               </div>
                                               <ul>
                                                   <li className="bold">1 Active Tour</li>
                                                   <li>Mobile App</li>
                                                   <li>Share via SMS or email via dedicated URL</li>
                                                   <li>Up to 12K resolution</li>
                                                   <li>Tour Analytics</li>
                                               </ul>
                                             
                                            </div>

                                            <div className="col-md-4 infoWrap">                                            
                                               <h3>LITE</h3>
                                               <div className="price-widget">
                                                   <span className="price">$ 8</span>
                                                   <span className="duration">MONTH-TO-MONTH</span>
                                               </div>
                                               <ul>
                                                   <li className="bold">5 Active Tours</li>
                                                   <li className="bold">Unlimited Archived Tours for future use</li>
                                                   <li>Mobile App</li>
                                                   <li>Share via SMS or email via dedicated URL</li>
                                                   <li>Up to 12K resolution</li>
                                                   <li>Tour Analytics</li>
                                               </ul>
                                             
                                            </div>

                                            <div className="col-md-4 infoWrap">                                            
                                               <h3>UNLIMITED</h3>
                                               <div className="price-widget">
                                                   <span className="price">$ 39</span>
                                                   <span className="duration">MONTH-TO-MONTH</span>
                                               </div>
                                               <ul>
                                                   <li className="bold">Unlimited Active Tours</li>
                                                   <li className="bold">Unlimited Archived Tours for future use</li>
                                                   <li className="bold">Personalized logos and branding on your tours</li>
                                                   <li>Mobile App</li>
                                                   <li>Share via SMS or email via dedicated URL</li>
                                                   <li>Up to 12K resolution</li>
                                                   <li>Tour Analytics</li>
                                               </ul>
                                             
                                            </div>


                                          
                                         
                                        </div>

                                        <a href="https://skyward360tours.com/signup" target="_blank" className="btnlight mt-5">Create Free Account</a> 

                                </div>
                             
                            </div>
                          
                        </div>
                       
                  
                    </div>

                    <div className="bgstrip virtual-section">
                        <div className="row clearfix">
                             <div className="col-md-6">
                                <div className="imgwrap" >
                                        <img src="/mb-2.png"/>
                                </div>                                
                            </div>
                            <div className="col-md-6">
                            <div className="text-info">
                               <h2>SKYWARD<br/>
                            360˚ TOURS APP<br/>
                            </h2>
                            <p className="text-white">
                            Designed to affordably streamline the home showing process, Skyward 360 Tours elevates your work by allowing you to create quick and easy virtual tours of your listings. This gives prospective tenants and buyers an immersive showing experience prior to ever scheduling a showing. This translates to a higher level of trust with your clients and ultimately saves you time and money with more deals closed on fewer showings. Download the app today and create your first virtual tour for free.
                            </p> 
                            <a href="https://apps.apple.com/us/app/skyward-360-tours/id1496753654" target="_blank" className="link">DOWNLOAD HERE >></a>   
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="Newskyward-design">
                    <footer style={{top:'-12px'}}>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> 
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                     
                    </ul>   
                    <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img src="/home-icon.png"/></li>
                       </ul>
                       <img src="/NYCFC_AP3_Web.jpg"/>
                       <div className="logo"></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1'> *The Skyward Team @ Keller Williams NYC and Skyward Steel, LLC is committed to adhering to the guidelines of The New York State Fair Housing Regulations. <a style={{textDecoration:'underline'}} href="https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf" target="_blank">To view The Fair Housing Notice-Please click here</a>
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="https://kwnyc.com/app/uploads/2022/03/Standardized-Operating-Procedure-for-Purchasers-of-Real-Estate.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>
<br></br>
*The website to the Team's brokerage (Keller Williams NYC) is <a href="https://kwnyc.com" style={{textDecoration:'underline'}} target="_blank">www.kwnyc.com.</a>
</p>
                   </div>
               </footer> 
              </div>


              </div>
              
    </div>

                );
    }
}
export default Philanthropy;
