import React from 'react';
import { Link, BrowserRoute,Route } from 'react-router-dom';
//import DatePicker from "react-datepicker";
import ReactDOM from 'react-dom';
import Header from '../common/Header';
import Webcam from "react-webcam";
import { Redirect } from 'react-router';
import { Button, FormGroup, FormControl,Checkbox, Radio, Row, Col, Form } from "react-bootstrap";
import {postApiData, getApiData, getUserId, getToken, setOnBoardingDone, getOnBoardingDone,getBaseUrl, deleteApiData, manageRedirect, getOnboardingData} from '../../services/Api';
import {clientApllicationStatus} from '../../services/Const';
import { getCurrentFormatDate , sliceFileName} from '../../services/Util';
import PlaidLink from 'react-plaid-link';
import Beforeunload  from '../../services/Beforeunload';
import Modal from 'react-responsive-modal';
import PlaidDetails from './application/PlaidDetails';
//import WebcamCont from '../../services/Webcam';
//import Autocomplete from 'react-google-autocomplete';
import Moment from 'moment';
import {validatePhone,socialSecurityNumber} from '../../services/validation';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './stripe';
//import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-date-picker';
import {confirmAlert} from 'react-confirm-alert';             // Import
import 'react-confirm-alert/src/react-confirm-alert.css';    // Import css

import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import CryptoJS from "crypto-js";
import ClientUrbanform from './Urbanform';
import {isEmail} from 'validator';

const containerBank = 'one-page-document';
const containerAdditional = 'client-additional-document';


//for USA 
const searchOptions = {
  componentRestrictions: {country: "us"}
  }

const searchOptionsNewYork = {
  componentRestrictions: {country: "us"}
  }

  const styleNormal = {
    border: '1px solid #004f6b',
    background: '#004f6b',
    color: '#fff',
    }
   
class Application1 extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            id:(this.props.match.params.id)?this.props.match.params.id:0,
        };
        
    }
    render() {
        return (
                <div>
                     {/* <Beforeunload onBeforeunload={() => "You'll lose your data!"}  > */}
                        <Content contentProp = {this.props.content} editId={this.state.id}/>
                    {/* </Beforeunload> */}
                </div>
                );
    }
    
    getReloadMessage(){
        return "You'll loose your data!";
    }
}
  
var plaid_pdf_response;
class Content extends React.Component {

    state = {
        value: new Date(),
      }
    
      onChange = value => this.setState({ value })

    constructor(props) {
super(props);
var board = getOnboardingData();
        this.state = {
                id:(props.editId)?props.editId:0,
                is_id_set:false,
                appId:0,
                apartment_address: "",
                unit_number: "",
                lease_months: "12",
                date_of_birth: '',
                start_date: new Date(),
                social_security_number: "",
                social_security_number_encrypt:'',
                gov_issued_id: "",
                current_address: "",
                landlord_management_company_name:"",
                is_pets:'0',
                pets_description:"",
                current_employer: "",
                job_title:"",
                unit_search_id:'',
                unit_number:'',
                employer_phone_number:"",
                is_student:false,
                is_plaid:0,
                is_bank_doc_manual: '0',
                bank_statement:[],
                is_stripe_completed:'0',
                card_holder_name:'',
                signature:(board!=null && board!=undefined)?board.signature:'',
                clientId:getUserId(),
                mymsg: "",
                final_redirect:false,
                myerrormsg:"",
                stage:'application_form_section_one',
                address: '',
                gov_id_file:'',
                onboarding_signature:(board!=null && board!=undefined)?board.signature:'',
                onboarding_name:(board!=null && board!=undefined)?board.name:'',
                onboarding_id:(board!=null && board!=undefined)?board.id:'',
                onboarding_completed:(board!=null && board!=undefined)?board.is_completed:'',
                imagescr:"",
                bank_file_one:'',
                additional_file:'',
                webCamStatus:false,
                uploadType:0,
                stipeFiles1:null,
                stipeFiles2:null,
                stipeFiles3:null,
                stipeFileName1:"",
                stipeFileName2:"",
                stipeFileName3:"",
                filesList:[],
                bankFiles:[],
                bank_manual_id:0,
                bank_manual_file_1:'',
                bank_manual_file_1_real:'',
                bank_manual_file_1_img:'',
                bank_manual_file_2:'',
                bank_manual_file_2_real:'',
                bank_manual_file_2_img:'',
                bank_manual_file_3:'',
                bank_manual_file_3_real:'',
                bank_manual_file_3_img:'',
                additional_manual_id:0,
                additional_manual_file_1:'',
                additional_manual_file_1_real:'',
                additional_manual_file_1_img:'',
                additional_manual_file_2:'',
                additional_manual_file_2_real:'',
                additional_manual_file_2_img:'',
                additional_manual_file_3:'',
                additional_manual_file_3_real:'',
                additional_manual_file_3_img:'',
                showLoader:false,
                plaidError:'',
                device_type:'web',
                searchMsg:false,
                listingCreator:[],
                unitListingCreator:[],
                building_id:'',
                building_id_byUnit:'',
                additional_document:'',
                application_template:'',
                additional_document_step:false,
                additional_doc_count:0,
                additional_doc_info_loaded:false,
                add_doc_error:'',
                uploaded_additional_document:[],
                uploaded_additional_document_count:0,
                validationCheck:false,
                finalErr:'',
                uploadedPdf:'',
                filename:''	,
                street_addr:'',
                address1:'',
                state:'',
                city:'',
                zip:'',
                supervisor_name:'',
                supervisor_name_error:'',
                annual_income:'',
                annual_income_error:'',
                length_of_employment:'',
                length_of_employment_error:'',
                current_rent_amount:'',
                current_rent_amount_error:'',
                long_live:'',
                long_live_error:'',
                key:'',
                iv:'',
                displaySwitch:false,
                university_name:'',
                major_program:'',
                hourly_course_load:'',
                intended_graduation_date:'',
                university_name_error:'',
                major_program_error:'',
                hourly_course_load_error:'',
                intended_graduation_date_error:'',
                uploaded_student_doc_document:[],
                is_skyward_runs_credit:0,
                agent_id:0,
                usStates:['AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','MD','MA','MI','MN','MS','MO','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'],
                agent_broker_name:'',
                agent_broker_name_error:'',
                agent_broker_firm:'',
                agent_broker_email:'',
                agent_email_error:'',
                agent_broker_phone:'',
                agent_broker_phone_error:'',
                unit_error:'',
                request_start_date_error:'',
                add_name_error:'',
                add_name:'',
                email_address_error:'',
                email_address:'',
                landlord_contact_error:'',
                landlord_contact:'',
                co_applicant:'',
                co_applicant_error:'',
                other_occupants_error:'',
                other_occupants:'',
                current_salary_error:'',
                current_salary:'',
                work_address_error:'',
                work_address:'',
                fileUpload: ['bank_manual_file_0'],
                submitDisabled:true,
                agentBrokerFlag:false,
                apartmentFlag:false,
                landlordFlag:false,
                currentAddressFlag:false,
                currentlyWorkFlag:false,
                studentFlag:false,
                petsFlag:false,
                disclousreCheck:0,
                linkingLoader: false,
                fileUploadFlag:false,
                fileUploadFour: ['four_manual_file_0',
                                'four_manual_file_1',
                                'four_manual_file_2',
                                'four_manual_file_3',
                                'four_manual_file_4',
                                'four_manual_file_5',
                                'four_manual_file_6',
                                'four_manual_file_7',
                                'four_manual_file_8',
                                'four_manual_file_9',
                                'four_manual_file_10',
                                'four_manual_file_11',
                                'four_manual_file_12',
                                'four_manual_file_13',
                                'four_manual_file_14',
                                'four_manual_file_15',
                                'four_manual_file_16',
                            ],
                fileUploadFlag_error:'',
        };
        this.list= [];
        

        var handleStripeSubmit  = this.handleStripeSubmit.bind(this);
//        this.handleDOBChange = this.handleDOBChange.bind(this);
        this.appendInput = this.appendInput.bind(this);
        this.appendInputFour = this.appendInputFour.bind(this);
        this.saveOnePageApplication = this.saveOnePageApplication.bind(this);
        
}



//To make upload doc option dynamic
appendInput(){
    var newInput = `bank_manual_file_${this.state.fileUpload.length}`;
    this.setState(prevState => ({ fileUpload: prevState.fileUpload.concat([newInput]) }));
    
}

//To make upload doc option dynamic
appendInputFour(){
    var newInput = `four_manual_file_${this.state.fileUploadFour.length}`;
    this.setState(prevState => ({ fileUploadFour: prevState.fileUploadFour.concat([newInput]) }));
    
}


disableSubmit(){
    console.log("hit me-uploadedPdf--");
    
    //Agent broker section
    if(this.state.agent_email!='' && this.state.agent_broker_phone!='' && this.state.agent_broker_name!=''){
        this.setState({agentBrokerFlag:true});
    }
    else{
        this.setState({agentBrokerFlag:false});
    }
    

    //apartment section
    if(this.state.apartment_address!='' && this.state.unit_search_id!='' && this.state.lease_months!=''){
        this.setState({apartmentFlag:true});
    }
    else{
        this.setState({apartmentFlag:false});
    }

    //landlord section
    if(this.state.add_name!='' && this.state.email_address!='' && this.state.your_phone!='' 
            && this.state.date_of_birth!='' && this.state.social_security_number!='' && (this.state.uploadedPdf!='' || this.state.gov_issued_id!='' || this.state.imagescr!='')){
                this.setState({landlordFlag:true});
            }
    else{
        this.setState({landlordFlag:false});
    }        

    //Current Address section
    if(this.state.current_address!='' && this.state.city!='' && this.state.state!='' 
            && this.state.zip!='' && this.state.current_rent_amount!='' && this.state.landlord_management_company_name!=''
            && this.state.long_live!='' && this.state.landlord_contact!='' && this.state.co_applicant!=''){
                this.setState({currentAddressFlag:true});
            }
    else{
        this.setState({currentAddressFlag:false});
    }
   
    
    if(this.state.is_pets!='0'){
        if(this.state.pets_description!=""){
            this.setState({petsFlag:true})
        }
        else{
            this.setState({petsFlag:false})
        }
    }else{
        this.setState({petsFlag:true})
    }   
    
    //Is student Or employee section
    if(this.state.is_student){
        if(this.state.job_title!='' && this.state.university_name!='' && this.state.major_program!='' && this.state.hourly_course_load!=''
        ){
            this.setState({studentFlag:true,currentlyWorkFlag:true});
        }
        else{
            this.setState({studentFlag:false,currentlyWorkFlag:true});
        }
    }
    else{
        if(this.state.current_employer!='' && this.state.job_title!='' && this.state.employer_phone_number!=''
        && this.state.current_salary!='' && this.state.work_address!='' 
        && this.state.supervisor_name!='' && this.state.annual_income!='' 
        && this.state.length_of_employment!=''){
            this.setState({currentlyWorkFlag:true,studentFlag:true});
        }
        else{
            this.setState({currentlyWorkFlag:false,studentFlag:true});
        }
    }

    //check for uploaded document atleast one
    if(this.state.fileUpload.length>0){
        let file = this.state.fileUpload[0];
        console.log("filename---",this.state[file+'_real']);
        if(this.state[file] && this.state[file+'_real']){
            this.setState({fileUploadFlag:true})
        }else{
            this.setState({fileUploadFlag:false})
        }
    }
    else{
        this.setState({fileUploadFlag:false})
    }

    //this.checkSubmitDisabled();
    
}

checkSubmitDisabled=()=>{
    //this.disableSubmit()
    
    if(this.state.agentBrokerFlag && this.state.apartmentFlag && this.state.landlordFlag && 
        this.state.currentAddressFlag && this.state.petsFlag && this.state.currentlyWorkFlag &&
        this.state.signature!='' && this.state.disclousreCheck && this.state.fileUploadFlag ){
        //this.setState({submitDisabled:false});
        return false;
    }
    else{
        return true;
        //this.setState({submitDisabled:true});
    }
}

onOpenModal1 = () => {
    this.setState({ open1: true });
  };
 
  onCloseModal1 = () => {
    this.setState({ open1: false });
  };

  handleApartmentChange = apartment_address => {
    console.log("apartment_address---",apartment_address);
        this.setState({ apartment_address });
        var db = true;
        if (apartment_address==='') {
               this.setState({apartment_address_error: 'Apartment Address is required.'});
               db = false;
        }else if (((this.isAddress(apartment_address)==false) || !apartment_address.replace(/\s/g, '').length)) {
               this.setState({apartment_address_error: 'Apartment Address is not valid.'});
               db = false;
        }else{
               this.setState({ apartment_address_error: ''});
               
        }
        return db;
  };
  handleApartmentSelect = apartment_address => {
      
    geocodeByAddress(apartment_address)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
       this.setState({apartment_address: 
        apartment_address.substring(0,apartment_address.lastIndexOf(','))
    });

  };

  handleWorkAddressChange = work_address => {
    console.log("work_address---",work_address);
        this.setState({ work_address });
        var db = true;
        if (work_address==='') {
               this.setState({work_address_error: 'Address is required.'});
               db = false;
        }else if (((this.isAddress(work_address)==false) || !work_address.replace(/\s/g, '').length)) {
               this.setState({work_address_error: 'Apartment Address is not valid.'});
               db = false;
        }else{
               this.setState({work_address_error: ''});
               this.disableSubmit();
        }
        return db;
  };
  handleWorkAddressSelect = work_address => {
      
    geocodeByAddress(work_address)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
       this.setState({work_address: 
        work_address.substring(0,work_address.lastIndexOf(','))
    });

  };
  handleCurrentChange = current_address => {
    console.log("current_address---",current_address);
        this.setState({ current_address });
  };
  handleCurrentSelect = current_address => {
    geocodeByAddress(current_address)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
       this.setState({current_address: current_address});

       
       var addarray=current_address.split(',');
       addarray=addarray.reverse();
       if(addarray[4])
       this.setState({address1:addarray[4].trim()});
       if(addarray[1])
       this.setState({state:addarray[1].trim()});
       if(addarray[2])
       this.setState({city:addarray[2].trim()});
       if(addarray[3])
       this.setState({current_address:addarray[3].trim()});
       var addressVal='';

       if(addarray[1] && addarray[2] && addarray[3])
         addressVal=addarray[3].trim()+', '+addarray[2].trim()+', '+addarray[1].trim()
       if(addarray[1] && addarray[2] && addarray[3] && addarray[4]){
         addressVal=addarray[4].trim()+', '+addarray[3].trim()+', '+addarray[2].trim()+', '+addarray[1].trim()
         this.setState({current_address:addarray[4].trim()+', '+addarray[3].trim()});
       }

     
       this.getZipFromAddress(addressVal);
      // this.setState({current_address: this.state.street_addr});

  };
  handleCityChange= event =>{
    this.formValidate(event.target.id,event.target.value);

    this.setState({ city:event.target.value });
  }
  handleStateChange= event =>{
    this.formValidate(event.target.id,event.target.value)
    this.setState({ state:event.target.value });
  }
  handleZipChange= event =>{
      this.formValidate(event.target.id,event.target.value)
    this.setState({ zip:event.target.value });
  }
  async checkDuplicateApplication() {
    let where = [{
     url: "/applications/checkDuplicateAddress"
     }];
     var method = 'post';
     let data = {'apartment_address':this.state.apartment_address,
         'unit_number':this.state.unit_number,
         'clientId':getUserId(),
     };
    postApiData(where, data, true).then(res => {
    
         if (res.data){
            if(res.data=="Applicaion Not exist")
            {
                this.setState({myerrormsg: ''});
            }
         }
     }).catch(error => {
        if(error.response.data.error.message=='Listing Exists'){
            this.setState({myerrormsg: <span>Similar application exists. Continue on the application from <a href="/client/dashboard">here</a></span>});
        }
     console.log('in catch', error.response.data.error.message);
     });  
    }  
async saveApplication(stage,isDraft=false) {
       let where = [{
        url: "/applications"
        }];
        var method = 'post';
        let data = {'apartment_address':this.state.apartment_address,
            'unit_number':this.state.unit_number,
            'lease_months':this.state.lease_months,
            'stage':stage,
            'building_id':this.state.building_id,
            'additional_doc_count':this.state.additional_doc_count,
            'start_date':this.state.start_date,
            'clientId':getUserId(),
            "is_skyward_runs_credit":this.state.is_skyward_runs_credit,
            "agent_id":this.state.agent_id,
        };
       postApiData(where, data, true).then(res => {
            if (res.data){
              this.setState({id:res.data.id,is_id_set:true});
            }
        }).catch(error => {
            if(error.response.data.error.message=='Listing Exists'){
                this.setState({myerrormsg: <span>Similar application exists. Continue on the application from <a href="/client/dashboard">here</a></span>});
            }
        console.log('in catch', error);
        });
        /**
         * get building info
         */
        this.getBuildingDetails(this.state.building_id,false);
}
getZipFromAddress(address) 
 {
    let where = [{
     url: '/showings/onegetzipnumber'
     }];
     var method = 'post';
     let data = {'address':address};
    postApiData(where, data, method,true).then(res => {
      
         if (res.data){
           this.setState({zip:res.data.zip});
           this.setState({state:res.data.state});
         }
     }).catch(error => {
     console.log('in catch', error);
     });

}
getBuildingDetails(building_id,checkState){
    console.log("checkState",this.state.stage,checkState)
    let where = [{
        url: "/getBuildingById",
        where: {
            "building_id":building_id
            ,"client_id":this.state.clientId
            ,"app_id":this.state.id
        },
        }];
       getApiData(where,true).then(res => {
           console.log("res.data getBuildingDetails",res.data);
           //is_skyward_runs_credit management_screen==1 mean no payment required and 0 means payment required
            if (res.data){
                console.log("additional doc result[]",res.data[1])
              this.setState({
                  additional_document:res.data[1],
                  application_template:res.data[0].application_template
                  ,is_skyward_runs_credit:(res.data[0].management_screen==1)?res.data[0].management_screen:0
                  //,is_stripe_completed:res.data[0].is_skyward_runs_credit
                },()=>{
                    console.log("this.state.additional_document",this.state.additional_document);
                    this.getAppBuildingDoc(this.state.id);
                  if(res.data[1]!=undefined && res.data[1].length>0){
                    let countOfadditionalDoc=res.data[1];
                    let additional_document_step_trueFalse=(countOfadditionalDoc.length>0)?true:false;
                    if(countOfadditionalDoc.length>0){
                        this.setState({additional_document_step:additional_document_step_trueFalse,additional_doc_count:countOfadditionalDoc.length,additional_doc_info_loaded:true},()=>{
                            if(checkState==true){this.manageTabStage(this.state.stage);}
                        });
                    }
                  }else if(checkState==true){
                      if(this.state.stage=='additional_doc_step'){
                          this.setState({
                            stage:'application_form_section_six' 
                          },()=>{
                            this.manageTabStage(this.state.stage);
                          });
                      }else{
                        this.manageTabStage(this.state.stage);
                      }
                  }else{
                    // this.manageTabStage(this.state.stage);
                  }
              });
            }
        }).catch(error => {
        console.log('in catch', error);
        });
}
async updateApplication(stage,isDraft=false,isFinal = false) {
    if(this.state.id){
        var url = "/applications/"+this.state.id;
             var method = 'post';
             //Encrypting SSN number to save in database
            let key = encHex.parse("0123456789abcdef0123456789abcdef");
            let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
           
             this.state.social_security_number_encrypt=aes.encrypt(this.state.social_security_number, key, {iv:iv, padding:padZeroPadding}).toString();
             //let decrypted = aes.decrypt(encrypted, key, {iv: iv}).toString(CryptoJS.enc.Utf8);
             let data = {
            'apartment_address':this.state.apartment_address,
            'unit_number':this.state.unit_number,
            'lease_months':this.state.lease_months,
            'date_of_birth':this.formatDate(this.state.date_of_birth),
            'social_security_number':this.state.social_security_number_encrypt,
            'gov_issued_id':this.state.gov_issued_id,
            'uploadTypeFlag':this.state.uploadType,
            'imageData':this.state.imagescr,
            'current_address':this.state.current_address,
            'city':this.state.city,
            'state':this.state.state,
            'agent_id':this.state.agent_id,
            'zip':this.state.zip,
            'bank_file_one':this.state.bank_file_one,
            'additional_file':this.state.additional_file,
            'landlord_management_company_name':this.state.landlord_management_company_name,
            'current_employer': this.state.current_employer,
            'job_title':this.state.job_title,
            'employer_phone_number':this.state.employer_phone_number,
            'is_student':(this.state.is_student)?'1':'0',
            'is_plaid':this.state.is_plaid,
            'is_bank_doc_manual':this.state.is_bank_doc_manual,
            'is_additional_document':this.state.is_additional_document,
            'is_stripe_completed':this.state.is_stripe_completed,
            'signature':this.state.signature,
            'stage':stage,
            'building_id':this.state.building_id,
            'start_date':this.state.start_date,
            'additional_doc_count':this.state.additional_doc_count,
            'supervisor_name':this.state.supervisor_name,
            'annual_income':this.state.annual_income,
            'length_of_employment':this.state.length_of_employment,
            'current_rent_amount':this.state.current_rent_amount,
            'long_live':this.state.long_live,
            'university_name':this.state.university_name,
            'major_program':this.state.major_program,
            'hourly_course_load':this.state.hourly_course_load,
            'intended_graduation_date':this.state.intended_graduation_date,
            'clientId':getUserId(),
            "is_skyward_runs_credit":this.state.is_skyward_runs_credit,
            
        };
         if(isFinal==true){
            data.completed_date = getCurrentFormatDate();
            data.status = '2';
         }
       let where = [{
        url: url
        }];
      postApiData(where, data, true).then(res => {
        if (res.data){
            this.setState({id:res.data.id});
        }
        if(isFinal===true){
            this.setState({mymsg: "Saved Successfully.",final_redirect:true});
        }
        if(isDraft===true){
             this.setState({mymsg: "Data Saved As Draft."});
        }
        }).catch(error => {
        console.log('in catch', error);
        });
        this.getBuildingDetails(this.state.building_id,false);
    }    
}
formatDate(value){
    var d = new Date(value);
    var stDate = d.getFullYear()+'-'+("0" + (d.getMonth() + 1)).slice(-2)+'-'+d.getDate();
    return stDate;
}
async getOnboardingData(){
      let clientRelWhere = [{
        url: "/onboardings/findOne",
        where: {"where":{"clientId":getUserId()}},
    }];
this.setState({showLoader:true});
        getApiData(clientRelWhere, true).then(res => {
            this.setState({onboarding_id:res.data.id, 
                        onboarding_name:res.data.first_name,
                        onboarding_completed:res.data.is_completed,
                        agent_id:res.data.agent_id,
                        showLoader:false
            });
            if(this.state.signature == ''){
                 this.setState({
                        signature:res.data.signature
                 });
            }
        }).catch(error => {
           console.log('in onboarding catch',error);
        });
}
async getApplicationData(){
    this.setState({showLoader:true});
 let clientRelWhere = [{
        url: "/applications/"+this.state.id
       
    }]; 
        getApiData(clientRelWhere, true).then(res => {
           
            let key = encHex.parse("0123456789abcdef0123456789abcdef");
            let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
            var ssnumber='';

            if(res.data.social_security_number.length>11 || res.data.social_security_number.length!='') // checking if SSN is not encrypted
              ssnumber=aes.decrypt(res.data.social_security_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8)
            else
              ssnumber=res.data.social_security_number;
               
            this.setState({id:res.data.id, 
                        appId:res.data.id,
                        apartment_address:res.data.apartment_address,
                        unit_number:res.data.unit_number,
                        lease_months:res.data.lease_months,
                        social_security_number:ssnumber,
                        gov_issued_id:res.data.gov_issued_id,
                        imagescr:res.data.imageData,
                        uploadType:res.data.uploadTypeFlag,
                        current_address:res.data.current_address,
                        city:res.data.city,
                        state:res.data.state,
                        zip:res.data.zip,
                        landlord_management_company_name:res.data.landlord_management_company_name,
                        is_pets:res.data.is_pets,
                        pets_description:res.data.pets_description,
                        current_employer: res.data.current_employer,
                        job_title:res.data.job_title,
                        employer_phone_number:res.data.employer_phone_number,
                        is_student:res.data.is_student,
                        is_plaid:res.data.is_plaid,
                        is_additional_document:res.data.is_additional_document,
                        is_bank_doc_manual:res.data.is_bank_doc_manual,
                        is_stripe_completed:res.data.is_stripe_completed,
                        stage:res.data.stage,
                        bank_file_one:res.data.bank_file_one,
                        building_id:res.data.building_id,
                        additional_file:res.data.additional_file,
                        supervisor_name:res.data.supervisor_name,
                        annual_income:res.data.annual_income,
                        length_of_employment:res.data.length_of_employment,
                        current_rent_amount:res.data.current_rent_amount,
                        long_live:res.data.long_live,
                        start_date:(res.data.start_date!='0000-00-00')?res.data.start_date:'',
                        university_name:res.data.university_name,
                        major_program:res.data.major_program,
                        hourly_course_load:res.data.hourly_course_load,
                        intended_graduation_date:(res.data.intended_graduation_date && res.data.intended_graduation_date!='0000-00-00')?res.data.intended_graduation_date:'',
                        
            });
            console.log("this.state.is_skyward_runs_credit",this.state.is_skyward_runs_credit)
            if(this.state.is_stripe_completed && this.state.is_skyward_runs_credit==0){
                  document.getElementById('stripe_button_container_success').style.display = "block";
                  document.getElementById('stripe_button_container_unsuccess').style.display = "none";
              }else if(this.state.is_skyward_runs_credit==0){
                  //stripe_button_container_unsuccess
                   document.getElementById('stripe_button_container_unsuccess').style.display = "block";
              }
              if(res.data.building_id!=''){
                console.log("building id is ",res.data.building_id)
                this.getBuildingDetails(res.data.building_id,true);
              }else{
                  console.log("building id is null",res.data.building_id)
                 
                this.manageTabStage(this.state.stage);
              }
              this.getAppBuildingDoc(this.state.id);
            this.getBankUploadFile();
            this.getAdditionalUploadFile();
             document.getElementById("lease_months_"+this.state.lease_months).checked = true;
             //this.manageTabStage(this.state.stage);

             this.showPetsCont(this.state.is_pets);
             this.showStudentCont(this.state.is_student);
             //console.log('Date==>',res.data.date_of_birth);
             //console.log("sasas",Moment(res.data.date_of_birth).format('MM/DD/YYYY'));
             if(res.data.date_of_birth !==null){
              
              this.setState({
                        date_of_birth:Moment(res.data.date_of_birth).format('MM/DD/YYYY')
               }); 
             }
             this.getGovIdImagePath(this.state.gov_issued_id);
             this.showAdditionalUpload(this.state.is_additional_document);
             //this.showBankContainer(this.state.is_bank_doc_manual);
             this.getAdditionalPath(this.state.additional_file);
        }).catch(error => {
           console.log('in catch',error);
        });
}
manageTabStage(tab){
      document.getElementById('application_form_section_one').style.display = "block";
      document.getElementById('application_form_section_two').style.display = "block";
      document.getElementById('application_form_section_three').style.display = "block";
      document.getElementById('application_form_section_four').style.display = "block";
      document.getElementById('application_form_section_five').style.display = "block";
      document.getElementById('application_form_section_six').style.display = "block";
      document.getElementById('application_form_section_seven').style.display = "block";
      document.getElementById('application_form_section_eight').style.display = "block";
      document.getElementById('application_form_section_nine').style.display = "block";
      document.getElementById('application_form_section_student_upload').style.display = "none";
      
     if(tab){
        this.setState({showLoader:false});
       document.getElementById(tab).style.display = "block"; 
      
    }else{
        this.setState({showLoader:false});
        document.getElementById('application_form_section_one').style.display = "block";
    }
      
}
componentDidMount(){

    const script = document.createElement("script");

    script.src = "https://static.zdassets.com/ekr/snippet.js?key=f856968b-f939-4952-8eb0-7acc2d89e798";
    script.async = true;
    script.id = "ze-snippet";
    window.scrollTo(0, 0);
    document.body.appendChild(script);
    
    if( document.querySelector('.react-date-picker__inputGroup__day')!=null){
        document.querySelector('.react-date-picker__inputGroup__day').placeholder = 'DD';
        document.querySelector('.react-date-picker__inputGroup__month').placeholder = 'MM';
        document.querySelector('.react-date-picker__inputGroup__year').placeholder = 'YYYY';
    }
    
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        //alert('mobile');
        this.setState({device_type:'mobile'});
    }

    this.listingOpt();
    let apartment_address = '';
    let unit_number = '';
    let buildingId ='';

    if (localStorage && localStorage.getItem('apartment_address') && localStorage.getItem('unit_number') ) {
        apartment_address = JSON.parse(localStorage.getItem('apartment_address'));
        unit_number = JSON.parse(localStorage.getItem('unit_number'));
        buildingId =JSON.parse(localStorage.getItem('building_id'))
      }
     this.setState({apartment_address: apartment_address, unit_number:unit_number, building_id:buildingId},()=>
     {
         /* Checking if Application on same Address already exist*/
        if(localStorage.getItem('apartment_address') && !this.state.id)
        {
        this.checkDuplicateApplication();
        } 
     });

    //pet section disabled on load
    this.setState({pets_description:''});
    document.getElementById("is_pets_0").checked = true;
    document.getElementById('pets_wrapper_cont').style.display = "none";
     
}

    componentWillMount() {
       // this.getOnboardingData();
        if(this.state.id){
            localStorage.removeItem('apartment_address');
            localStorage.removeItem('unit_number');
            localStorage.removeItem('building_id');
            this.getApplicationData();
        }

    }
    componentWillUnmount() {
        //this.getOnboardingData();
        if(this.state.id){
            this.getApplicationData();
        }
    }
    isString(str){
        if(/^[a-zA-Z.' ' ]+$/.test(str))
        return true;
        else 
        return false;
    }
    isAddress(str){
        if(/^[a-zA-Z0-9\s,.@{}():?/$*+!~`^%#&_';="-]*$/.test(str))
        return true;
        else 
        return false;
    }

    isPhoneNumber(phone){
        var reg = /^\d{3}-\d,{3}-\d{4}$/;
        if(reg.exec(phone))
            return true;
        else
            return false;  
    }

validateApartmentAddress(value){
     var aa = true;
     if (value.length ==0) {
                 this.setState({apart_add_error: 'Apartment Address is required.'});
                aa = false;
     }else if (( (this.isAddress(value)==false) || !value.replace(/\s/g, '').length)) {
                    this.setState({apart_add_error: 'Apartment Address is not valid.'});
                aa = false;
     }else if(value.length >= 100){
        this.setState({apart_add_error: 'Apartment Address length is too long.'});
        aa = false;
     }else{
                   this.setState({ apart_add_error: ''});
     }
     this.disableSubmit();
     return aa;
}
validateUnitNumber(value){
     var un = true;
     if (value.length ==0) {
            this.setState({unit_error: 'Unit Number is required.'});
         un = false;
     }else if(value.length >= 30){
        this.setState({unit_error: 'Unit Number length is too long.'});
        un = false;
     }else{
            this.setState({ unit_error: ''});
     }
     this.disableSubmit();
     return un;
}
validateLeaseMonth(value){
     var lm = true;
     if (value==='') {
            this.setState({lease_month_error: 'Lease Month is required.'});
         lm = false;
     }else{
            this.setState({ lease_month_error: ''});
     }
     this.disableSubmit();
     return lm;
}
validateDateOfBirth(value){
    var db = true;
     if (value==='') {
            this.setState({dob_error: 'Lease Month is required.'});
         db = false;
     }else{
            this.setState({ dob_error: ''});
     }
     this.disableSubmit();
     return db;
}
validateSocialSecurityNumber(value){
     let res= socialSecurityNumber(value);
    
            if(res.status==false){
                    this.setState({social_sn_error: 'Social Security Number is not valid.'});
                    document.getElementById("social_security_number").value=res.value;
                   // this.setState({social_security_number: res.value});
                    
            }
            else if(value==''){
                this.setState({social_sn_error: 'Social Security Number required.'});
                document.getElementById("social_security_number").value=res.value;
            }else{
                    this.setState({social_sn_error: ''});
                    document.getElementById("social_security_number").value=res.value;
            }
            this.disableSubmit();
            return res.status;
}  
validateCurrentAddress(value){
    var db = true;
     if (value==='') {
            this.setState({current_address_error: 'Current Address is required.'});
            db = false;
     }else if (((this.isAddress(value)==false) || !value.replace(/\s/g, '').length)) {
            this.setState({current_address_error: 'Current Address is not valid.'});
            db = false;
     }else{
            this.setState({ current_address_error: ''});
            
     }
     this.disableSubmit();
     return db;
     
} 
validateCity(value){
    var db = true;
     if (value==='') {
            this.setState({city_error: 'City is required.'});
            db = false;
    
     }else{
            this.setState({ city_error: ''});
     }
     return db;
     
} 
validateState(value){
    var db = true;
     if (value==='') {
            this.setState({state_error: 'State is required.'});
            db = false;
    
     }else{
            this.setState({ state_error: ''});
     }
     this.disableSubmit();
     return db;
     
}
validateZip(value){
    var db = true;
     if (value==='') {
            this.setState({zip_error: 'Zip is required.'});
            db = false;
    
     }else{
            this.setState({ zip_error: ''});
     }
     this.disableSubmit();
     return db;
     
}
validateLandlord(value){
    var db = true;
     if (value==='') {
            this.setState({landlord_error: 'Landlord is required.'});
         db = false;
     }else if(value.length >= 30)
     {
        this.setState({landlord_error: 'Length is too long.'});
        db = false;
     }else{
            this.setState({ landlord_error: ''});
     }
     this.disableSubmit();
     return db;
} 
validateCurrentEmployer(value){
    var db = true;
     if (value==='') {
            this.setState({current_employer_error: 'Current Employer is required.'});
         db = false;
     }else  if(value.length >= 30)
     {
        this.setState({current_employer_error: 'Current Employer length is too long. '});
        db = false;
     }
     else{
            this.setState({ current_employer_error: ''});
     }
     this.disableSubmit();
     return db;
} 
validateJobTitle(value){
    var db = true;
     if (value==='') {
            this.setState({job_title_error: 'Job Title is required.'});
         db = false;
     }else if(value.length >=30)
     {
        this.setState({job_title_error: 'Job Title length is too long.'});
        db = false;
     }
     else{
            this.setState({ job_title_error: ''});
     }
     this.disableSubmit();
     return db;
} 
validateEmpPhoneNumber(value){
    let res=validatePhone(value);
                if(res.status==false){
                    this.setState({employer_phone_number_error: 'Phone number is not valid.'});
                    document.getElementById("employer_phone_number").value=res.value;
                }
                else if(value==''){
                    this.setState({employer_phone_number_error: 'Phone number is required.'});
                    document.getElementById("employer_phone_number").value=res.value;
                }
                else{
                    this.setState({employer_phone_number_error: ''});
                    document.getElementById("employer_phone_number").value=res.value;
                }
                this.disableSubmit();
            return res.status;
} 
validateSignature(value){
    var signature = true;
    if (value.length ==0) {
        this.setState({signatureorerror: 'Field is required.'});
        signature = false;
    }else if (this.isString(value)==false) {
        this.setState({signatureorerror: 'Signature name is not valid.'});
        signature = false;
    }else{
        this.setState({ signatureorerror: ''});
    }
    this.disableSubmit();
    return signature;
}

//Agent broker Validation
validateAgentBroker(value){
    var db = true;
     if (value==='') {
            this.setState({agent_broker_name_error: 'Agent/Broker Name is required.'});
         db = false;
     }else  if(value.length >= 30)
     {
        this.setState({agent_broker_name_error: 'Agent/Broker name is too long. '});
        db = false;
     }
     else{
            this.setState({ agent_broker_name_error: ''});
     }
    //  this.disableSubmit();
     return db;
} 
handleAgentBrokerName = event => {
    this.formValidate(event.target.id,event.target.value);
    this.setState({
                [event.target.id]: event.target.value,
        },()=>{
            this.disableSubmit();
        });
        
}

handleAgentBrokerFirm = event => {
    this.setState({
                [event.target.id]: event.target.value,
        });
    
}

handleAgentEmail = event => {
    this.formValidate(event.target.id,event.target.value);
    this.setState({
                [event.target.id]: event.target.value
        },()=>{
            this.disableSubmit();
        });
    
}
validateAgentEmail(value){
    var db = true;
     if (value==='') {
            this.setState({agent_email_error: 'Agent/Broker email is required.'});
         db = false;
     }else  if(!isEmail(value))
     {
        this.setState({agent_email_error: 'Email is not valid.'});
        db = false;
     }
     else{
            this.setState({ agent_email_error: ''});
     }
    //  this.disableSubmit();
     return db;
}

handleAgentBrokerPhone = event => {
    this.formValidate(event.target.id,event.target.value);
    this.setState({
                [event.target.id]: event.target.value
        },()=>{
            this.disableSubmit();
        });
    
}


//Section for apartment would you like to apply for today
handleUnitNumber = event => {
    this.formValidate(event.target.id,event.target.value);
    this.setState({
            [event.target.id]: event.target.value
    },()=>{
        this.disableSubmit();
    });
    
   
}

//Section for "Your future landlord would like to know a little more about you"
validateAddName(value){
    var db = true;
     if (value==='') {
            this.setState({add_name_error: 'Name is required.'});
         db = false;
     }else  if(value.length >= 30)
     {
        this.setState({add_name_error: 'Name is too long. '});
        db = false;
     }
     else{
            this.setState({ add_name_error: ''});
            
     }
     this.disableSubmit();
     return db;
} 
handleAddName = event => {
    this.formValidate(event.target.id,event.target.value);
    this.setState({
            [event.target.id]: event.target.value
    },()=>{
        this.disableSubmit();
    });
    
}

handleEmailAddress = event => {
    this.formValidate(event.target.id,event.target.value);
    this.setState({
            [event.target.id]: event.target.value
    },()=>{
        this.disableSubmit();
    });
    
}
validateEmailAddress(value){
    var db = true;
     if (value==='') {
            this.setState({email_address_error: 'Email is required.'});
         db = false;
     }else  if(!isEmail(value))
     {
        this.setState({email_address_error: 'Email is not valid.'});
        db = false;
     }
     else{
            this.setState({ email_address_error: ''});
     }
     this.disableSubmit();
     return db;
}

 
validateCoApplicant(value){
    var db = true;
     if (value==='') {
            this.setState({co_applicant_error: 'Co-applicant is required.'});
         db = false;
     }else  if(value.length >= 30)
     {
        this.setState({co_applicant_error: 'Co-applicant is too long. '});
        db = false;
     }
     else{
            this.setState({ co_applicant_error: ''});
     }
     this.disableSubmit();
     return db;
} 

validatePhoneFunc(value,id){
    var db = true;
    let res=validatePhone(value);
    switch(id){
        case 'landlord_contact':
            if(res.status==false){
                 this.setState({landlord_contact_error: 'Phone number is not valid.'});
                document.getElementById(id).value=res.value;
                db = false;
            }
            else if (value==='') {
                this.setState({landlord_contact_error: 'Phone is required.'});
                document.getElementById(id).value=res.value;
             db = false;
            }
            else{
                this.setState({landlord_contact_error: ''});
                document.getElementById(id).value=res.value;
            }
            break;   
        case 'your_phone': 
            if(res.status==false){
                this.setState({your_phone_error: 'Phone number is not valid.'});
                document.getElementById(id).value=res.value;
                db = false;
            }
            else if (value==='') {
                this.setState({your_phone_error: 'Phone is required.'});
                document.getElementById(id).value=res.value;
            db = false;
            }
            else{
                this.setState({your_phone_error: ''});
                document.getElementById(id).value=res.value;
            }
            break; 
        case 'agent_broker_phone':
            if(res.status==false){
                this.setState({agent_broker_phone_error: 'Phone number is not valid.'});
                document.getElementById(id).value=res.value;
                db = false;
            }
            else if (value==='') {
                this.setState({agent_broker_phone_error: 'Agent/Broker phone is required.'});
                document.getElementById(id).value=res.value;
            db = false;
            }
            else{
                this.setState({agent_broker_phone_error: ''});
                document.getElementById(id).value=res.value;
            }
            break;
    }
    // this.disableSubmit();         
    return db;
}

//Section for pets
validatePetDescription(value){
    var db = true;
     if (value==='') {
            this.setState({pets_description_error: 'Pet description is required.'});
         db = false;
     }else  if(value.length >= 500)
     {
        this.setState({pets_description_error: 'Pet description is too long. '});
        db = false;
     }
     else{
            this.setState({pets_description_error: ''});
     }
     this.disableSubmit();
     return db;
} 

    formValidate(key,value){
    switch(key){
        case 'apartment_address':     
            this.validateApartmentAddress(value);
            break;
        case 'unit_number':        
             this.validateUnitNumber(value);
            break;
        case 'lease_months':  
             this.validateLeaseMonth(value);
            break;
        case 'date_of_birth':
            this.validateDateOfBirth(value);
            break;
        case 'social_security_number':
            this.validateSocialSecurityNumber(value);
            break;
        case 'current_address':
            this.validateCurrentAddress(value);
            break;
        case 'city':
            this.validateCity(value);
            break;
        case 'state':
            this.validateState(value);
            break;
        case 'zip':
            this.validateZip(value);
            break;           
        case 'landlord_management_company_name':
            this.validateLandlord(value);
            break;
        case 'employer_phone_number':
            this.validateEmpPhoneNumber(value);
            break;
        case 'signature':  
             this.validateSignature(value);
            break;
        case 'current_employer':
            this.validateCurrentEmployer(value);
            break;
        case 'job_title':
            this.validateJobTitle(value);
            break;
        case 'agent_broker_name':
            this.validateAgentBroker(value);
            break;
        case 'agent_broker_email':
            this.validateAgentEmail(value);
            break;
        case 'agent_broker_phone':
            this.validatePhoneFunc(value,key);
            break;
        case 'unit_search_id':        
             this.validateUnitNumber(value);
            break;
        case 'add_name':        
             this.validateAddName(value);
            break;
        case 'email_address':        
             this.validateEmailAddress(value);
            break;
        case 'your_phone':        
             this.validatePhoneFunc(value,key);
            break;
        case 'landlord_contact':        
             this.validatePhoneFunc(value,key);
            break;
        case 'co_applicant':        
             this.validateCoApplicant(value);
            break;
        case 'pets_description':        
             this.validatePetDescription(value);
            break;
        }
    }
    validateStepOne(){
         return this.state.apartment_address.length > 0  && this.state.unit_number.length > 0 && this.state.unit_number.length < 30 && this.state.lease_months && this.state.myerrormsg==''
         && (this.state.displaySwitch ) ;
    }
    validateStepTwo(){
        
         return (this.state.date_of_birth && (this.state.dob_error=='' || this.state.dob_error==undefined) && this.state.social_security_number.length == 11 && (this.state.gov_issued_id.length>0 || this.state.imagescr || this.state.uploadedPdf));
    }
    validateStepThree(){
        if(this.state.city && this.state.state && this.state.current_rent_amount)
         return this.state.current_address.length > 0 && this.state.current_rent_amount.length > 0 && this.state.city.length > 0 && this.state.state.length > 0 && this.state.landlord_management_company_name.length > 0 && this.state.landlord_management_company_name.length < 30 && this.state.long_live.length>0;
         else
         return false;
    }
    validateStepFour(){
        if(this.state.is_pets == '0'){
              return true;  
            }else{
                if(this.state.pets_description)
                {
                  return this.state.is_pets > 0 && this.state.pets_description.trim() !='';
                }
        }
    }
    validateStepFive(){
        if(this.state.is_student){
                return true;  
            }else{
                if(this.state.current_employer && this.state.job_title)
                return this.state.current_employer.trim().length > 0 && this.state.current_employer.length < 30 && this.state.job_title.trim().length > 0 && this.state.job_title.length < 30 && this.state.employer_phone_number.length > 0;
                else
                  return false;
        }
    }
    validateStepSix(){
         if((this.state.is_bank_doc_manual ==false || this.state.is_bank_doc_manual=='0') && this.state.is_plaid){
              return true;
         }else{
             return ((this.state.bank_manual_file_1 || this.state.bank_manual_file_2 || this.state.bank_manual_file_3) && (this.state.is_bank_doc_manual ==true || this.state.is_bank_doc_manual=='1'));
         }
    }
    validateStepSupervisor(){
        var val=false
        if(this.state.is_student){
            if(this.state.university_name!=undefined && this.state.university_name.trim().length>0 && (/\d/.test(this.state.university_name)==false))
            {
                if(this.state.major_program!=undefined && this.state.major_program.trim().length>0)
                {
                    if(this.state.hourly_course_load!=undefined && this.state.hourly_course_load.trim().length>0)
                    {
                        
                        // if(this.state.intended_graduation_date.trim().length>0)
                        // {
                        //     console.log("here true");
                        //     // this.setState({
                        //     //     university_name_error:'',
                        //     //     major_program_error:'',
                        //     //     hourly_course_load_error:'',
                        //     //     intended_graduation_date_error:''
                        //     // });
                        //     return true
                        // }else{
                        //     console.log("here false");
                        //     // this.setState({
                        //     //     intended_graduation_date_error:'Required'
                        //     // });
                        //     return false
                        // }
                        return true
                    }else{
                        // this.setState({
                        //     hourly_course_load_error:'Required'
                        // });
                        return false
                    }
                }else{
                   
                    return false
                }
            }else{
                // this.setState({
                //     university_name_error:'Required'
                // });
                return false
                
            }
    }else{

        if(/\d/.test(this.state.supervisor_name)){
            val=false;    
        }
        if(this.state.annual_income!='' && this.state.annual_income)
        {  
            if(this.state.annual_income.trim().length>0 && this.state.annual_income>0 && this.state.annual_income<99999999)
            {  
               
            if(this.state.length_of_employment)
            {  
              if(this.state.length_of_employment.trim().length>0 && this.state.length_of_employment>0 && this.state.length_of_employment<999)
              {
                return true
              }
              else
              {
                return false  
              }
            }
          }
          else
          {
            return false
          }
        }      
        else
        {
         return false
        }

    }

        
       
        //return val;
    }

    validateStepStudentDoc(){
        console.log("I am in validation=>",this.state.uploaded_additional_document);
        if(this.state.uploaded_additional_document!=undefined){
        let flag=0;
        for (var i = 0; i < this.state.uploaded_additional_document.length; i++) {
           // console.log("this.state.uploaded_additional_document[i]['fileName']",this.state.uploaded_additional_document[i]['fileName']);
                if(this.state.uploaded_additional_document[i]['fileName'] == 'Acceptance letter')
                {
                    flag=flag+1;
                }else if(this.state.uploaded_additional_document[i]['fileName'] == 'Student ID')
                {
                    flag=flag+1;
                }
                // if(flag==2){
                //     break;
                // }
        }

        return (flag==2)?true:false;
    }else{
        return false;
    }

    }

    validateStepAddDoc(){
        if(this.state.additional_doc_count<=this.state.uploaded_additional_document_count)
        {
            return true;
        }else{
            return false;
        }
    }
    validateStepSeven(){
        if(this.state.is_additional_document!='' && (this.state.additional_manual_file_1 !='' || this.state.additional_manual_file_2 !=''|| this.state.additional_manual_file_3!='')){
           return true;
       }else if(this.state.is_additional_document=='0'){        
        return  true
       }
       else{
           return false
       }
    }
    validateStripe(){
        if((this.state.is_stripe_completed ==true || this.state.is_skyward_runs_credit==1) && this.state.signature)
        {
            return true;
        }
    }

    checkDocUploaded=(id,cb)=>
    {
    let where = [{
        url: "/getDocList",
        where: {"app_id":id},
        }];
        getApiData(where,true).then(res => {
            if (res.data){
                
                if(this.state.additional_doc_count<=res.data.length)
                    {
                        cb(null,true);
                       
                    }else{
                        cb(null,false);
                    }
                    
            }
        }).catch(error => {
            cb(true,null);
        console.log('in catch', error);
        });
    }
    
    saveData(stage,isDraft=false,final=false){
        if(this.state.id){
             this.updateApplication(stage,isDraft,final);
        }else{
           this.saveApplication(stage,isDraft);
        }

        
    }
    setSaveMessage(){
       this.setState({mymsg: "Draft Saved Successfully."}); 
    }
    removeSaveMessage(){
        this.setState({mymsg: ""}); 
    }
    saveAsDraft = param =>e =>{
       this.setSaveMessage(); 
    }

stepOne = param =>e =>{
            e.preventDefault();
            if(param[2]==true)
             {
                 this.setBuildingId(param)
             }else{
                 this.stepOneHelper(param);
             }           
     }
     setBuildingId(param){
         let whereFind = [{
             url: "/searchInsertBuilding",
             where: {
                 "where":{
                     "apartment_address":this.state.apartment_address,
                     "unit_number":this.state.unit_number,
                         }
                     }
          }];
         getApiData(whereFind,true).then(res => {
             if(res){
                 this.setState({
                     building_id:res.data.building_id
                 },()=>{
                     this.stepOneHelper(param)
                     localStorage.removeItem('apartment_address');
                     localStorage.removeItem('unit_number');
                 })
                     
             }else{
                 console.log("Something went wrong");
             }
                 
         })
         .catch(error => {
             console.log(error);
         }); 
     }
     stepOneHelper(param){
           var aa = this.validateApartmentAddress(this.state.apartment_address);
           var un = this.validateUnitNumber(this.state.unit_number);
           var lm = this.validateLeaseMonth(this.state.lease_months);
            if(aa && un && lm){
                 this.nextStep(param[0],param[1]);
                 this.saveData(param[1]);
            }  
    }
    stepTwo = param =>e =>{
           e.preventDefault();
          // console.log("building_id",this.state.building_id);
           this.removeSaveMessage();
           var db = this.validateDateOfBirth(this.state.date_of_birth);
           var ssn = this.validateSocialSecurityNumber(this.state.social_security_number);
            if(db && ssn){
                this.saveData(param[1]);
                if(param[2]===true){
                    this.setSaveMessage();
                }else{
                    this.setState({"webCamStatus":false});
                     this.nextStep(param[0],param[1]);
                     this.removeSaveMessage();
                }
            }  
    }
    stepThree = param =>e =>{
           e.preventDefault();
           this.setState({current_rent_amount_error:'',long_live_error:''});
           this.removeSaveMessage();
           var ca = this.validateCurrentAddress(this.state.current_address);
           var st = this.validateCity(this.state.city);
           var cy = this.validateState(this.state.state);
           var zp = this.validateZip(this.state.zip);
           var ll = this.validateLandlord(this.state.landlord_management_company_name);
            if(ca && ll && st && cy && zp){
                 this.saveData(param[1]);
                 if(param[2]===true){
                    this.setSaveMessage();
                }else{
                     this.nextStep(param[0],param[1]);
                     this.removeSaveMessage();
                }
            }  
    }
    stepFour = param =>e =>{
           e.preventDefault();
           this.removeSaveMessage();
         
             this.saveRaw({'is_pets':this.state.is_pets,'pets_description':this.state.pets_description,'stage':param[1]});
                 if(param[2]===true){
                    this.setSaveMessage();
                }else{
                     this.nextStep(param[0],param[1]);
                     this.removeSaveMessage();
        }
    }
    stepFive = param =>e =>{
        console.log("param=>",param);
           e.preventDefault();
           this.removeSaveMessage();
            var jt = this.validateJobTitle(this.state.job_title);
           if(this.state.is_student){
                if(jt){
                   this.saveData(param[1]);
                   if(param[2]===true){
                        this.setSaveMessage();
                    }else{
                         this.nextStep(param[0],param[1]);
                         this.removeSaveMessage();
                    }
                }
               
           }else{
            var ce = this.validateCurrentEmployer(this.state.current_employer);
                var ep = this.validateEmpPhoneNumber(this.state.employer_phone_number);
                if(ce && jt && ep===true){
                 this.saveData(param[1]);
                  if(param[2]===true){
                       this.setSaveMessage();
                  }else{
                       this.nextStep(param[0],param[1]);
                       this.removeSaveMessage();
                  }
                }
           }
          
          
    }
    stepSupervisor= param =>e =>{
        e.preventDefault();
        this.setState({annual_income_error:'',supervisor_name_error:'',length_of_employment_error:''},()=>{
            this.saveData(param[1]);
            this.nextStep(param[0],param[1]);
        })
      
    }
    stepSix = param =>e =>{
           e.preventDefault();
           this.removeSaveMessage();
             this.saveBankUploadFile();
             console.log("this.state.additional_document_step=>",this.state);
             if(this.state.additional_document_step && !this.state.is_student){
                this.getAppBuildingDoc(this.state.id);
                this.saveData('additional_doc_step');
                this.nextStep(param[0],'additional_doc_step');
             }
             else{
                this.saveData(param[1]);
                this.nextStep(param[0],param[1]);
             }
             this.unsetBankErrorKeys();
    }
    stepSeven = param =>e =>{
           e.preventDefault();
                   
                      this.removeSaveMessage();
                      this.saveAdditionalUploadFile();
                      this.saveData(param[1]);
                      this.nextStep(param[0],param[1]);
                      this.unsetAdditionalErrorKeys();
    }
    stepEight = param =>e =>{
           e.preventDefault();
           this.setState({finalErr:''});
           this.removeSaveMessage();
               this.saveData(param[1]);
               this.nextStep(param[0],param[1]);
                
    }
    
    saveFinal  = e => {
        console.log("Here");
            e.preventDefault();
        if((this.state.is_stripe_completed ==true || this.state.is_skyward_runs_credit==1) && this.state.signature)
        {
            
          
              //  this.checkDocUploaded(this.state.id,(err,res)=>
               // {
                    //console.log("res",res);
               // if(res){
                        var s = this.validateSignature(this.state.signature);
                        if(s){
                        this.saveRaw({'signature':this.state.signature},true);
                        }
              //  }
              //  else{
              //      this.setState({finalErr:'Please re-check required documents uploaded for Landlord.'});
              //  }
                //if(err){
                  //  console.log(err);
                    
               // }
              //  })

        }
            
        };
        async saveRaw(rawData,isFinal=false) {
            if(this.state.id){
                var url = "/applications/"+this.state.id;
                    if(isFinal){
                      rawData.completed_date = getCurrentFormatDate();
                      rawData.status = '2';  
                    }
                    
                    let where = [{
                     url: url
                     }];
                   postApiData(where, rawData,true).then(res => {
                     if (res.data){
                         this.setState({id:res.data.id});
                     }
                        if(isFinal){
                           this.setState({mymsg: "Saved Successfully.",final_redirect:true});
                        }
                     }).catch(error => {
                     console.log('in catch', error);
                     });
            }    
}
        unsetBankErrorKeys(){
            this.setState({
                bank_manual_file_1_error:'',
                bank_manual_file_2_error:'',
                bank_manual_file_3_error:''
            });
        }
         unsetAdditionalErrorKeys(){
            this.setState({
                additional_manual_file_1_error:'',
                additional_manual_file_2_error:'',
                additional_manual_file_3_error:''
            });
        }

        checkBuilding(){
            this.setState({showLoader:true});
            let whereFind = [{
                url: "/searchBuilding",
                where: {
                    "where":{
                        "apartment_address":this.state.apartment_address,
                        "unit_number":this.state.unit_number,
                        "clientId":getUserId(),
                            }
                        }
             }];
            getApiData(whereFind,true).then(res => {
                console.log("---res----",res.data.status)
                if(res.data.status==false){
                    this.setState({
                        
                        myerrormsg:<span>Similar application exists. Continue on the application from <a href="/client/dashboard">here</a></span>,
                        showLoader:false
                    })
                        
                }else{
                    this.setState({
                        myerrormsg:'',
                        showLoader:false
                    })
                }
                    
            })
            .catch(error => {
                console.log(error);
            }); 
        }

    handleChange = event => {
      let key = event.target.id;
    this.formValidate(event.target.id,event.target.value);
        if(event.target.id =='landlord_management_company_name' && event.target.value.length > 30)
        {

        }
        else if(event.target.id =='job_title' && event.target.value.length > 30){

        }else{
            this.setState({[event.target.id]: event.target.value},()=>{
                this.disableSubmit();
            });
        }
        
    }

    handledisclousreCheck = event =>{
        
        if(event.target.value==1){
             document.getElementById('disclousreCheck').checked=false;
             this.setState({[event.target.id]: 0},()=>{
                this.disableSubmit();
             });
            
        }
        else{
           document.getElementById('disclousreCheck').checked=true; 
           this.setState({[event.target.id]: 1},()=>{
               this.disableSubmit();
           });
            
        }
        
    }

    handleDOBValidation= event => {

    }
        handleDOBChange = event => {
            this.setState({
                date_of_birth:event.target.value
                },()=>{
                    console.log("this.state.date_of_birth",this.state.date_of_birth);
                });
           console.log("event.target.value",event.target.value);
            let dob=event.target.value;
            dob=event.target.value.split('/');
            console.log("dob",dob,dob.length);
            let dob_of_user='';
            let flag=0;
            if(dob.length==3 && dob[2].length==4)
            {
                let _MM_=parseInt(dob[0]);
                let _DD_=parseInt(dob[1]);
                let _YYYY_=parseInt(dob[2]);
                let dob_of_user=dob[0]+'/'+dob[1]+'/'+dob[2];
                var maxBirthDate = new Date();
                maxBirthDate = maxBirthDate.getFullYear()-17;
                console.log("3=>", dob[2]);
                if(_MM_>12 || _MM_<1) {
                flag=1;
                }else if((_MM_==1 || _MM_==3 || _MM_==5 || _MM_==7 || _MM_==8 || _MM_==10 || _MM_==12) && (_DD_>31 || _DD_<1)){
                    flag=1;
                }else if(( _MM_==4 || _MM_==6 || _MM_==9 || _MM_==11) && (_DD_>30 || _DD_<1)){
                    flag=1;
                }else if((_MM_==2) && ((((_YYYY_%4)==0) && (_DD_>29 || _DD_<1)) || (((_YYYY_%4)!=0) && (_DD_>28 || _DD_<1))))
                {
                    flag=1;
                    console.log("I am here");
                }else if((_YYYY_ > maxBirthDate) || (_YYYY_<1)){
                    console.log("_YYYY_=>",_YYYY_,"maxBirthDate",maxBirthDate)
                    flag=1;
                }
                this.setState({
                    date_of_birth:dob_of_user,
                    dob_error:(flag==0)?'':'Invalid Date'
                    });

            }else if (dob.length==2 && dob[1].length==2){
                let _MM_=parseInt(dob[0]);
                let _DD_=parseInt(dob[1]);
                let dob_of_user=dob[0]+'/'+dob[1]+'/';
                if(_MM_>12 || _MM_<1) {
                flag=1;
                }else if((_MM_==1 || _MM_==3 || _MM_==5 || _MM_==7 || _MM_==8 || _MM_==10 || _MM_==12) && (_DD_>31 || _DD_<1)){
                    flag=1;
                }else if(( _MM_==4 || _MM_==6 || _MM_==9 || _MM_==11) && (_DD_>30 || _DD_<1)){
                    flag=1;
                }else if((_MM_==2) && (_DD_>29 || _DD_<1)){
                    flag=1;
                }
                this.setState({
                    date_of_birth:dob_of_user,
                    dob_error:(flag==0)?'':'Invalid Date'
                    });
                    
            }else if (dob.length==1 && dob[0].length==2){
                let _MM_=parseInt(dob[0]);
                let dob_of_user=dob[0]+'/';
                    if(_MM_>12 || _MM_<1) {
                        flag=1;
                    }else{
                        this.setState({
                            date_of_birth:dob_of_user,
                            dob_error:(flag==0)?'':'Invalid Date'
                            });
                    }
            

            }else{
                this.setState({
                    date_of_birth:event.target.value,
                    dob_error:(flag==0)?'':'Invalid Date'
                    });
            }



                if(dob.length!=3 || ((dob[2]!=undefined && dob[2].length!=4) || (dob[1]!=undefined && dob[1].length!=2) || (dob[0]!=undefined && dob[0].length!=2))){
                    this.setState({
                        dob_error:'Invalid Date'
                    })
                }
            
                this.disableSubmit();
            
  }

  handleStartDateChange= event =>{
    
    this.setState({
        start_date:event
        });
        // if(event.target.value==""){
        //     this.setState({request_start_date_error:"Date required."})
        // }
        // else{
        //     this.setState({request_start_date_error:""})
        // }
  }
  handleValidationDateChange= event =>{
    console.log("event start=>",event);
    this.setState({
        intended_graduation_date:event
        });
  }

  handleValidation=event=>{
        console.log("event=>",event.target.id,event.target.value);
        
        let key=event.target.id;
        switch(key){
            case 'supervisor_name':
            if((event.target.value!='') && /\d/.test(event.target.value)){
                this.setState({
                    supervisor_name_error:'Only alphabets are allowed',
                });
            }
            else if(event.target.value==''){
                this.setState({
                    supervisor_name_error:'Supervisor name is required.',
                    supervisor_name:event.target.value
                });
            }else{
                this.setState({
                    supervisor_name_error:'',
                    supervisor_name:event.target.value
                },()=>{
                     this.disableSubmit();
                });
               
            }
            break;
            case 'annual_income':
            if((event.target.value!='') && isNaN(event.target.value)){
                this.setState({
                    annual_income_error:'Only numbers are allowed',
                });
            }else if((event.target.value<0 || event.target.value>99999999) && (event.target.value!='')){
                this.setState({
                    annual_income_error:'Range should be 0 to 99,999,999',
                });
            }else if(event.target.value==''){
                this.setState({
                    annual_income_error:'Required.',
                    annual_income:event.target.value
                });
            }else{
                this.setState({
                    annual_income_error:'',
                    annual_income:event.target.value
                },()=>{
                    this.disableSubmit();
                });
                
            }
            
            break;
            case 'length_of_employment':
            if((event.target.value!='') && isNaN(event.target.value)){
                this.setState({
                    length_of_employment_error:'Only numbers are allowed',
                });
            }else if((event.target.value<1 || event.target.value>999) && (event.target.value!='')){
                this.setState({
                    length_of_employment_error:'Range should be 1 to 999'
                });
            }
            else if(event.target.value==''){
                this.setState({
                        length_of_employment_error:'Length of employment required.',
                        length_of_employment:event.target.value
                    });
            }else{
                this.setState({
                    length_of_employment_error:'',
                    length_of_employment:event.target.value
                },()=>{
                    this.disableSubmit();
                });
                
            }
            
            break;

            case 'current_rent_amount':
            if((event.target.value!='') && isNaN(event.target.value)){
                this.setState({
                    current_rent_amount_error:'Only numbers are allowed',
                });
            }else if((event.target.value<0 || event.target.value>99999999) && (event.target.value!='')){
                this.setState({
                    current_rent_amount_error:'Range should be 0 to 99,999,999',
                });
            }else if(event.target.value==''){
                this.setState({
                    current_rent_amount_error:'Required',
                    current_rent_amount:event.target.value
                });
            }else{
                this.setState({
                    current_rent_amount_error:'',
                    current_rent_amount:event.target.value
                });
            }
            break;

            case 'long_live':
            if((event.target.value!='') && isNaN(event.target.value)){
                this.setState({
                    long_live_error:'Only numbers are allowed',
                });
            }else if((event.target.value<1 || event.target.value>999) && (event.target.value!='')){
                this.setState({
                    long_live_error:'Range should be 1 to 999',
                });
            }else{
                this.setState({
                    long_live_error:'',
                    long_live:event.target.value
                });
            }
            
            break;

            case 'university_name':
                
            if( (/\d/.test(event.target.value)==true))
            {
                this.setState({
                    university_name:event.target.value,
                    university_name_error:'Only alphabets are allowed',
                });
            }
            else if((event.target.value=='') || (event.target.value.trim()=='')){
                this.setState({
                    university_name:event.target.value,
                    university_name_error:'University name is required.',
                });
            }else{
                console.log("here i masas")
                this.setState({
                    university_name_error:'',
                    university_name:event.target.value
                });
            }
            break;

            case 'major_program':
            if((event.target.value!='') && (event.target.value.trim().length<1)){
                this.setState({
                    major_program:event.target.value,
                    major_program_error:'Required',
                });
            }
            else if(event.target.value==''){
                this.setState({
                    major_program:event.target.value,
                    major_program_error:'Required',
                });
            }
            else{
                this.setState({
                    major_program_error:'',
                    major_program:event.target.value
                });
            }
            break;

            case 'hourly_course_load':
            if((event.target.value!='') && (event.target.value.trim().length<1)){
                this.setState({
                    hourly_course_load:event.target.value,
                    hourly_course_load_error:'Required',
                });
            }
            else if(event.target.value==''){
                this.setState({
                    hourly_course_load:event.target.value,
                    hourly_course_load_error:'Required',
                });
            }else{
                this.setState({
                    hourly_course_load_error:'',
                    hourly_course_load:event.target.value
                });
            }
            break;


            case 'intended_graduation_date':
            if((event.target.value!='') && (event.target.value.trim().length<1)){
                this.setState({
                    intended_graduation_date:event.target.value,
                    intended_graduation_date_error:'Required',
                });
            }else{
                this.setState({
                    intended_graduation_date_error:'',
                    intended_graduation_date:event.target.value
                });
            }
            break;
            case 'current_salary':
            if((event.target.value!='') && isNaN(event.target.value)){
                this.setState({
                    current_salary_error:'Only numbers are allowed',
                });
            }else if((event.target.value<0 || event.target.value>99999999) && (event.target.value!='')){
                this.setState({
                    current_salary_error:'Range should be 0 to 99,999,999',
                });
            }else if(event.target.value==''){
                this.setState({
                    current_salary_error:'Required',
                    current_salary:event.target.value
                });
            }else{
                this.setState({
                    current_salary_error:'',
                    current_salary:event.target.value
                },()=>{
                    this.disableSubmit();
                });
                
            }
            
            break;

        }
  }

    handleChangeWork = event => {
            this.formValidate(event.target.id,event.target.value);
            if(event.target.id=='current_employer' && event.target.value.length > 30)
            {

            }else{
            this.setState({
                    [event.target.id]: event.target.value
            },()=>{
                this.disableSubmit();  
            });
            document.getElementById("is_student_1").checked =false;
            this.state.is_student = 0
        }
         
    }
    handleLease = event => {
          this.setState({
            lease_months: event.target.value
            });
    }
        
    nextStep(current,next){
       document.getElementById(current).style.display = "none";
       document.getElementById(next).style.display = "block";
    }
    
    prevStep = param => e => {
       this.setState({"webCamStatus":false});
       e.preventDefault();
       document.getElementById(param[0]).style.display = "none";
       if(param[0]=='application_form_section_six' && param[1]=='application_form_section_supervisor' && this.state.is_student){
        this.saveData('application_form_section_student_upload');
        document.getElementById('application_form_section_student_upload').style.display = "block";
       }else{
        this.saveData(param[1]);
        document.getElementById(param[1]).style.display = "block";
       }
        this.unsetBankErrorKeys();
        this.unsetAdditionalErrorKeys();
    };
    handlePets = event => {
        this.setState({
            is_pets: event.target.value
        });
        this.showPetsCont(event.target.value,true);
}
additionalUploadYes = event => {
     this.showAdditionalUpload(true);
}
showBankContainer(param){
    // if(this.state.is_plaid==1){
    // this.removePdf();
    // }
    //param=true
    if(param=='1' || param==true){
        console.log('--in if--',param);
        this.setState({is_bank_doc_manual:'1',is_plaid:'0'});
        document.getElementById('bank_file_upload_container').style.display = "block";
        document.getElementById('bank_manual_link').classList.add("btn-secondary");
    }else{
        console.log('--in else--',param);
        this.setState({is_bank_doc_manual:'0'});
        document.getElementById('bank_file_upload_container').style.display = "none";
        document.getElementById('bank_manual_link').classList.remove("btn-secondary");
    }
}
bankShowManualContainer = event => {
    this.showBankContainer(true);
}
usePlaid = event => {
   this.showBankContainer(false);
}
additionalUploadNo = event => {
      this.showAdditionalUpload(false,true);
}
showAdditionalUpload(param,showNext=false){
    if(param=='1' || param==true){
          document.getElementById('additional_file_upload_container').style.display = "block";
          this.setState({is_additional_document: '1'});
          document.getElementById('additional_button_yes').classList.add("btn-secondary");
          document.getElementById('additional_button_no').classList.remove("btn-secondary");
    }else{
        this.setState({is_additional_document: '0'});
          document.getElementById('additional_button_yes').classList.remove("btn-secondary");
          document.getElementById('additional_button_no').classList.add("btn-secondary");
          document.getElementById('additional_file_upload_container').style.display = "none";
          if(showNext){
               this.saveData("application_form_section_eight");
               this.nextStep("application_form_section_seven","application_form_section_eight");
          }
    }
}
handleStudent = event => {
  this.setState({
            is_student: (document.getElementById("is_student_1").checked)?1:0,
            employer_phone_number:'',
            job_title:'Student',
            current_employer:'',
            job_title_error:''
        });
           if(document.getElementById("is_student_1").checked){
                this.state.is_student = 1;
                this.state.job_title = 'Student';
                this.saveRaw({'is_student':this.state.is_student,'job_title':this.state.job_title,'stage':'application_form_section_six'});
                this.nextStep("application_form_section_five","application_form_section_supervisor");
                this.setState({current_employer:'',current_employer_error:'',
                current_salary_error:'', current_salary:'',length_of_employment:'',length_of_employment_error:'',
                supervisor_name:'',supervisor_name_error:'',annual_income:'', annual_income_error:'',
                employer_phone_number_error:'',employer_phone_number:'', work_address:'',
                work_address_error:''
            })
           }
           else{
            this.setState({job_title:'',
                           job_title_error:'Job Title is required.',
                           current_employer:'',
                           current_employer_error:'Current Employer is required.',
                           current_salary:'',
                           current_salary_error:'Required',
                           work_address:'',
                           work_address_error:'Address is required.',
                           employer_phone_number:'',
                           employer_phone_number_error:'Phone number is required.',
                           length_of_employment:'',
                           length_of_employment_error:'Length of employment required.',
                           supervisor_name:'',
                           supervisor_name_error:'Name is required.',
                           annual_income:'',
                           annual_income_error:'Required',

                        })
           }
}
showStudentCont(param){
        if(param=='1' || param==true){
            document.getElementById("is_student_1").checked = true;
        }
}
showPetsCont(param,proceed=false){
        if(param=='1' || param==true){
           document.getElementById('pets_wrapper_cont').style.display = "block";
           document.getElementById("is_pets_1").checked = true;
    }else if(param=='0' || param==false){
           this.setState({pets_description:''});
           document.getElementById("is_pets_0").checked = true;
           document.getElementById('pets_wrapper_cont').style.display = "none";
           if(proceed){
                this.saveRaw({'is_pets':0,'pets_description':'','stage':'application_form_section_five'});
                this.nextStep("application_form_section_four","application_form_section_five");
           }
    }
}
fileChangedHandler = (event) => {
        if(event.target.files[0]){
    var fileName = event.target.files[0].name;  
        var file_Ext = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
        
        if(event.target.files[0]){
         let imgFlag = true;
        const maxFileSize = 5242880;   //5mb
        const value = event.target.files[0].name;
        const ext = value.substring(value.lastIndexOf('.')).toLowerCase();
        if(!['.jpg','.jpeg','.JPG','.png','.pdf'].includes(ext)){
            this.setState({gov_id_error: ext+' is not supported file extension.'})
            imgFlag = false;
        }
        if(event.target.files[0].size>maxFileSize){
            this.setState({gov_id_error:"File size is too big, upto 5MB allowed."})
            imgFlag = false;
            }
        if(imgFlag) {
            if(file_Ext =='.pdf'){
                this.setState({uploadedPdf:fileName,gov_id_error:''})
          }else{
              this.setState({uploadedPdf:'',gov_id_error:''})
          }   
        this.fileGovIdUpload(event.target.files[0]);
        }
     }            
    this.disableSubmit(); 
    
}
}
uploadFileValidate(name,size,key,allowedFiles=['.jpg','.jpeg','.png']){
    let imgFlag = true;
    const maxFileSize = 1e+7;   //10mb
    const ext = name.substring(name.lastIndexOf('.')).toLowerCase();
    if(!allowedFiles.includes(ext)){
             this.setState({[key+'_error']:ext+' is not supported file extension.'});
            imgFlag = false;
    } 
    if(imgFlag){
         if(size>maxFileSize){
         this.setState({[key+'_error']:'File size is too big, permitted range is 1MB - 10MB.'});
         imgFlag = false;
     } 
    }
    return imgFlag;
}
additionalManualFileUpload = (event) =>{
    var r = this.uploadFileValidate(event.target.files[0].name, event.target.files[0].size, event.target.id, ['.jpg','.jpeg','.png','.pdf']);
    if(r){
            this.fileAdditionalManualUpload(event.target.files[0],event.target.id);
    } 
}
bankManualFileUpload = (event) =>{
    var r = this.uploadFileValidate(event.target.files[0].name,event.target.files[0].size, event.target.id, ['.jpg','.jpeg','.png','.pdf']);
    if(r){
            this.fileBankManualUpload(event.target.files[0],event.target.id);
            this.setState({
                is_bank_doc_manual:1
            },()=>{
                this.disableSubmit();
            })
    } 
}

bankManageAddMore(){
    
      if(document.getElementById('row_bank_file_manual_2').style.display == 'none'){
          document.getElementById('row_bank_file_manual_2').style.display = "flex";
      }else if(document.getElementById('row_bank_file_manual_3').style.display == 'none'){
         document.getElementById('row_bank_file_manual_3').style.display = "flex";
         document.getElementById('row_bank_file_manual_add_more').style.display = "none";
      }

}
manageBankDelete(row,AddButton){
    console.log('row---',row);
   document.getElementById(row).style.display = "none"; 
   document.getElementById(AddButton).style.display = "grid"; 
}
additionalManageAddMore(){
    
      if(document.getElementById('row_additional_file_manual_2').style.display == 'none'){
          document.getElementById('row_additional_file_manual_2').style.display = "flex";
      }else if(document.getElementById('row_additional_file_manual_3').style.display == 'none'){
         document.getElementById('row_additional_file_manual_3').style.display = "flex";
         document.getElementById('row_additional_file_manual_add_more').style.display = "none";
      }
}

 gameLines() {
        return (
            <div className="row" id="row_additional_file_manual_2" style={{ display: 'none' }}>
                                                
                                                <div className="upload-btn-wrapper col-4">
                                                    <button className="btn btn-small">Choose File</button>
                                                     <input type="file" onChange={this.additionalManualFileUpload} id="additional_manual_file_2"/>
                                                </div>
                                                <div className="col-8">
                                                <div className="clearfix">
                                                    <span className="pull-left small"> {(this.state.additional_manual_file_2_real)?<span>{sliceFileName(this.state.additional_manual_file_2_real)}</span>:""} </span>
                                                    {(this.state.additional_manual_file_2_real)?<span className="pull-right"><button className="btn btn-small delete" type="button" style={{border:'0px',boxShadow:'none'}} onClick={this.deleteAdditionalFile(["additional_manual_file_2",'row_additional_file_manual_2','row_additional_file_manual_add_more'])}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                </div> 
                                                 <span className="inline-error-class col-12"> {this.state.additional_manual_file_2_error !==''?this.state.additional_manual_file_2_error:''}   </span>

                                                </div>
                                                 
           </div>
        )
}
manageadditionalDelete(row,AddButton){
   document.getElementById(row).style.display = "none"; 
   document.getElementById(AddButton).style.display = "grid"; 
}

deleteManualBankFile = param  =>{  
        this.unlinkFile(containerBank,this.state[param[0]],param[0]);
        if(param[1] && param[2]){
                   this.manageBankDelete(param[1],param[2]);
        }
}
deleteBankFile = (param,index) =>e =>{
    this.setState({[param[0]]:'',
                    [param[0]+'_real']:''},()=>{
                        this.saveBankUploadFile();
                    });
    this.unlinkFile(containerBank,this.state[param[0]],param[0]);
        if(index>0){
            delete this.state.fileUpload[index];
        }
}

deleteBankFileFour = (param,index) =>e =>{
    this.setState({[param[0]]:'',
                    [param[0]+'_real']:''},()=>{
                        this.saveBankUploadFile();
                    });
    this.unlinkFile(containerBank,this.state[param[0]],param[0]);
        // if(index>0){
        //     delete this.state.fileUploadFour[index];
        // }
}

unlinkFile(container,filename,key){
      let where = [{
             url: '/containers/'+container+'/files/'+filename
        }];
        deleteApiData(where).then(res => {
               if(res.status==200){
                  this.setState({[key]: '',[key+'_real']:'',[key+'_img']:''});
               }
        }).catch(error => {
                console.log(error);
            });
}
deleteAdditionalFile = param =>e =>{
    if(param[0]=='additional_manual_file_1'){
        this.setState({
            additional_manual_file_1:'',
            additional_manual_file_1_real:''
        },()=>{
            this.saveAdditionalUploadFile();
        })
    }
    if(param[0]=='additional_manual_file_2'){
        this.setState({
            additional_manual_file_2:'',
            additional_manual_file_2_real:''
        },()=>{
            this.saveAdditionalUploadFile();
        })
    }
    if(param[0]=='additional_manual_file_3'){
        this.setState({
            additional_manual_file_3:'',
            additional_manual_file_3_real:''
        },()=>{
            this.saveAdditionalUploadFile();
        })
    }
        this.unlinkFile(containerAdditional,this.state[param[0]],param[0]);
        if(param[1] && param[2]){
                   this.manageadditionalDelete(param[1],param[2]);
        }
}
async fileBankManualUpload(file,key,container=containerBank) {
        let where = [{
             url: '/containers/'+container+'/upload'
        }];
        const formData = new FormData();
        this.setState({showLoader:true});
        formData.append('file',file);
        postApiData(where, formData,true).then(res => {
            if(res.data.success)
            {
                this.setState({[key]: res.data.file_name,[key+'_real']:file.name,[key+'_error']:''},()=>{
                    this.disableSubmit();
                    //this.saveBankUploadFile();
                });
               this.getBankFilePath(this.state[key],key+'_img',containerBank)
               this.setState({showLoader:false});
            }
        }).catch(error => {
                console.log(error);
                this.setState({showLoader:false});
            });
    }
    async fileAdditionalManualUpload(file,key,container=containerAdditional) {
        let where = [{
             url: '/containers/'+container+'/upload'
        }];
        const formData = new FormData();
        formData.append('file',file);
        this.setState({showLoader:true});
        postApiData(where, formData,true).then(res => {
            if(res.data.success)
            {
                this.setState({[key]: res.data.file_name,[key+'_real']:file.name,[key+'_error']:''},()=>{
                    this.saveAdditionalUploadFile();
                });
               this.getBankFilePath(this.state[key],key+'_img',containerAdditional);
               this.setState({showLoader:false});
            }
        })
            .catch(error => {
                console.log(error);
            });
    }
     saveBankUploadFile(){
        if(this.state.bank_manual_id){
            let whereFind = [{
                    url: "/clientBankDocuments/"+this.state.bank_manual_id
                }]; 
             const formData1 = new FormData();
                let data={
                    "clientId":getUserId(),
                    "id":this.state.bank_manual_id,
                    "applicationId":this.state.id,
                    "file_name_1":this.state.bank_manual_file_1,
                    "real_name_1":this.state.bank_manual_file_1_real,
                    "file_name_2":this.state.bank_manual_file_2,
                    "real_name_2":this.state.bank_manual_file_2_real,
                    "file_name_3":this.state.bank_manual_file_3,
                    "real_name_3":this.state.bank_manual_file_3_real,
                };
                 postApiData(whereFind,data,true).then(res => {
                })
                .catch(error => {
                    console.log(error);
                }); 
        }else{
           let whereFind = [{
                    url: "/clientBankDocuments",
                }]; 
             const formData1 = new FormData();
                let data={
                    "clientId":getUserId(),
                    "applicationId":this.state.id,
                    "file_name_1":this.state.bank_manual_file_1,
                    "real_name_1":this.state.bank_manual_file_1_real,
                    "file_name_2":this.state.bank_manual_file_2,
                    "real_name_2":this.state.bank_manual_file_2_real,
                    "file_name_3":this.state.bank_manual_file_3,
                    "real_name_3":this.state.bank_manual_file_3_real,
                };
                 postApiData(whereFind,data,true).then(res => {
                     this.setState({
                        bank_manual_id:res.data.id
            });
                })
                .catch(error => {
                    console.log(error);
                }); 
        }      
}
 async saveAdditionalUploadFile(){
        if(this.state.additional_manual_id){
            let whereFind = [{
                    url: "/clientAdditionalDocuments/"+this.state.additional_manual_id
                }]; 
             const formData1 = new FormData();
                let data={
                    "clientId":getUserId(),
                    "id":this.state.additional_manual_id,
                    "applicationId":this.state.id,
                    "file_name_1":this.state.additional_manual_file_1,
                    "real_name_1":this.state.additional_manual_file_1_real,
                    "file_name_2":this.state.additional_manual_file_2,
                    "real_name_2":this.state.additional_manual_file_2_real,
                    "file_name_3":this.state.additional_manual_file_3,
                    "real_name_3":this.state.additional_manual_file_3_real,
                };
                 postApiData(whereFind,data,'patch',true).then(res => {
                })
                .catch(error => {
                    console.log(error);
                }); 
        }else{
           let whereFind = [{
                    url: "/clientAdditionalDocuments",
                }]; 
             const formData1 = new FormData();
                let data={
                    "clientId":getUserId(),
                    "applicationId":this.state.id,
                    "file_name_1":this.state.additional_manual_file_1,
                    "real_name_1":this.state.additional_manual_file_1_real,
                    "file_name_2":this.state.additional_manual_file_2,
                    "real_name_2":this.state.additional_manual_file_2_real,
                    "file_name_3":this.state.additional_manual_file_3,
                    "real_name_3":this.state.additional_manual_file_3_real,
                };
                 postApiData(whereFind,data,true).then(res => {
                     this.setState({
                        additional_manual_id:res.data.id
            });
                })
                .catch(error => {
                    console.log(error);
                }); 
        }  
}
async getBankUploadFile(){
        let whereFind = [{
            url: "/clientBankDocuments",
            where: {"where":{"applicationId":this.state.id}}
         }];
               
                getApiData(whereFind,true).then(res => {
                     this.setState({
                        bank_manual_id:res.data.id,
                        bank_manual_file_1:res.data.file_name_1,
                        bank_manual_file_1_real:res.data.real_name_1,
                        bank_manual_file_2:res.data.file_name_2,
                        bank_manual_file_2_real:res.data.real_name_2,
                        bank_manual_file_3:res.data.file_name_3,
                        bank_manual_file_3_real:res.data.real_name_3
            });
            this.getBankFilePath(this.state.bank_manual_file_1,'bank_manual_file_1_img',containerBank);
            this.getBankFilePath(this.state.bank_manual_file_2,'bank_manual_file_2_img',containerBank);
            this.getBankFilePath(this.state.bank_manual_file_3,'bank_manual_file_3_img',containerBank) 
            
            if(this.state.bank_manual_file_2){
                document.getElementById('row_bank_file_manual_2').style.display = "flex";
            }
            if(this.state.bank_manual_file_3){
                document.getElementById('row_bank_file_manual_3').style.display = "flex";
            }
            if(this.state.bank_manual_file_2 && this.state.bank_manual_file_3){
               document.getElementById('row_bank_file_manual_add_more').style.display = "none";
            }

        })
                .catch(error => {
                    console.log(error);
                }); 
}
async getAdditionalUploadFile(){
        let whereFind = [{
            url: "/clientAdditionalDocuments",
            where: {"where":{"applicationId":this.state.id}}
         }];
               
                getApiData(whereFind,true).then(res => {
                     this.setState({
                        additional_manual_id:res.data.id,
                        additional_manual_file_1:res.data.file_name_1,
                        additional_manual_file_1_real:res.data.real_name_1,
                        additional_manual_file_2:res.data.file_name_2,
                        additional_manual_file_2_real:res.data.real_name_2,
                        additional_manual_file_3:res.data.file_name_3,
                        additional_manual_file_3_real:res.data.real_name_3
            });
            this.getAdditionalPath(this.state.additional_manual_file_1,'additional_manual_file_1_img',containerAdditional);
            this.getAdditionalPath(this.state.additional_manual_file_2,'additional_manual_file_2_img',containerAdditional);
            this.getAdditionalPath(this.state.additional_manual_file_3,'additional_manual_file_3_img',containerAdditional);
             if(this.state.additional_manual_file_2){
                document.getElementById('row_additional_file_manual_2').style.display = "flex";
            }
            if(this.state.additional_manual_file_3){
                document.getElementById('row_additional_file_manual_3').style.display = "flex";
            }
            if(this.state.additional_manual_file_2 && this.state.additional_manual_file_3){
               document.getElementById('row_additional_file_manual_add_more').style.display = "none";
            }
        })
                .catch(error => {
                    console.log(error);
                }); 
}

    async fileAdditionalUpload(indexOfFile,file,container,list) {
        let where = [{
            url: '/containers/'+container+'/upload'
        }];
        const formData = new FormData();
        formData.append('file',file);
        postApiData(where, formData,true).then(res => {
            if(res.status==200 && res.statusText=='OK' && res.data.result.files.file[0].name!='undefined' && res.data.result.files.file[0].name!='')
            {
               this.list.push(res.data.result.files.file[0].name);
               this.setState({additional_file:res.data.result.files.file[0].name});
              this.state.filesList.splice(indexOfFile, 1);
              this.getAdditionalPath(this.state.additional_file);
            }
        })
            .catch(error => {
                console.log(error);
            });
    }

    async fileGovIdUpload(file,container='client-gov-id') {
        let where = [{
             url: '/containers/'+container+'/upload'
        }];
        const formData = new FormData();
        formData.append('file',file);
        this.setState({showLoader:true});
        postApiData(where, formData,true).then(res => {
            if(res.data.success)
            {
               this.setState({gov_issued_id: res.data.file_name });
               this.getGovIdImagePath(this.state.gov_issued_id);
               this.setState({'webCamStatus':false,'imagescr':'',showLoader:false});
               this.disableSubmit();
            }
        })
            .catch(error => {
                console.log(error);
            });
    }
    getGovIdImagePath(image){
        var imgsrc='';
        if(image!=''){
            imgsrc=getBaseUrl()+"/containers/client-gov-id/download/"+image;
        }
          
                this.setState({"govIdimage": imgsrc,"webCamStatus":false});
    }
    getBankFilePath(filename,img_key,container='one-page-document'){
        if(filename){
             const ext = filename.substring(filename.lastIndexOf('.'));
            switch(ext){
                case '.pdf': 
                    this.setState({[img_key]:"/pdf.png",stipeFileName1:filename});
                     break;
                default:
                     var imgsrc=getBaseUrl()+"/containers/"+container+"/download/"+filename;
                  this.setState({[img_key]:imgsrc,stipeFileName1:filename});
                  break;
                 
            }
        }
    }
    getAdditionalPath(filename,img_key,container='client-additional-document'){
        if(filename){
            const ext = filename.substring(filename.lastIndexOf('.'));
            switch(ext){
                case '.pdf': 
                    this.setState({[img_key]:"/pdf.png",additionalFileName1:filename});
                     break;
                default:
                     var imgsrc=getBaseUrl()+"/containers/"+container+"/download/"+filename;
                  this.setState({[img_key]:imgsrc,additionalFileName1:filename});
                  break;
                 
            }
        }
    }
    
    setRef = webcam => {
        this.webcam = webcam;
      };
     
      capture = () => {
        const imageSrc = this.webcam.getScreenshot();
        this.setState({"webCamStatus":false,"imagescr":imageSrc,"uploadType":1,"govIdimage":'',"uploadedPdf":''},()=>{
            this.disableSubmit();
        });
        
      };
      checkWebCam=()=>{
    }
      webcamstart=()=>{
          if(this.state.webCamStatus==true){
            this.setState({"webCamStatus":false});
            document.getElementById("uploadBtn").disabled = false;
            document.getElementById("uploadfile").disabled = false;
          }else if(this.state.webCamStatus==false){
            document.getElementById("uploadBtn").disabled = true;
            document.getElementById("uploadfile").disabled = true;
            
            this.setState({"webCamStatus":true,"uploadType":0});
          }
      };
    
    handleStripeSubmit(token,stripeData){
                        let whereFind = [{
                                url: "/clients/stripe/save"
                            }];
                        let data = {'clientId': getUserId(), 'applicationId': this.state.id, 'request_data': JSON.stringify(token), 'response_data': JSON.stringify(stripeData)};
                        postApiData(whereFind, data,true).then(res => {
                            //console.log("here=>",res);
                            this.setState({is_stripe_completed:true});
                        })
                        .catch(error => {
                            console.log("here1=>",error);
                        });
    }
      //plaid
      handleOnSuccess=(token, metadata)=> {
        this.setState({
            plaidError:'',
            is_bank_doc_manual:'0'
        })
        document.getElementById('show_loader_on_click').style.display = "block";
         
        let filename=Date.now()+'_'+this.state.id;
        let where = [{
              url: "/applications/get_access_token",
          }];
          let data={
              "public_token":token,
              "app_id":this.state.id,
              "filename":filename
          }
          postApiData(where,data).then(res => {
            var _this=this;
            var count=0;
            if(this.state.is_plaid==0){
                plaid_pdf_response= setInterval(function() {
                    if(_this.state.is_plaid==0 && count<240001)
                    {
                        count=count+15000;
                        console.log("count=>",count);
                        _this.CheckFile('plaid_pdf',_this.state.id,filename);
                    }
                    else{
                        _this.setState({is_plaid:0, plaidError: 'Your bank seems to have an issue connecting to us. Please upload your last 2 bank statements manually or try again later'},()=>{
                            // document.getElementById("bank_manual_link").disabled = false;
                         document.getElementById('show_loader_on_click').style.display = "none";

                        });
                    }
                }, 15000);
            }
  
              })
              .catch(error => {
                  console.log("error.response",error,error.response);
                    this.setState({is_plaid:0, plaidError: 'Your bank seems to have an issue connecting to us. Please upload your last 2 bank statements manually or try again later'},()=>{
                    });
                    document.getElementById('show_loader_on_click').style.display = "none";
                  });

    }

    CheckFile(container,app_id,filename){
        let where = [{
               url: '/containers/'+container+'/checkFile/'+app_id+'.pdf',
          }];
          getApiData(where).then(res => {
                 if(res.data.status==200){
                    console.log("res200=>",res)
                    this.setState({is_plaid:1},()=>{
                      
                      this.deleteManualBankFile(["bank_manual_file_1"]);
                      this.deleteManualBankFile(["bank_manual_file_2",'row_bank_file_manual_2','row_bank_file_manual_add_more']);
                      this.deleteManualBankFile(["bank_manual_file_3",'row_bank_file_manual_3','row_bank_file_manual_add_more']);

                      // document.getElementById("bank_manual_link").disabled = true;
                      document.getElementById('bank_file_upload_container').style.display = "none";
                        document.getElementById('plaid_manual_link').classList.add("btn-secondary");
                        document.getElementById('show_loader_on_click').style.display = "none";
                        clearInterval(plaid_pdf_response);
                        
                      });
                 }
                 if(res.data.status==400){
                    console.log("res400=>",res)
                    let where = [{
                        url: "/applications/plaid_webhook/"+filename,
                        where: {"app_id":app_id},
                    }];
                    getApiData(where,true).then(plaid_report => {
                        console.log("plaid_report=>",plaid_report);
                    }).catch(error => {
                        console.log("error=>",error);
                        document.getElementById('show_loader_on_click').style.display = "none";

                    });
                 }
          }).catch(error => {
                  console.log(error);
                  document.getElementById('show_loader_on_click').style.display = "none";

              });
  }

    onOpenModal = () => {
        this.setState({ open: true });
    };
     
    onCloseModal = () => {
        this.setState({ open: false });
    };
    handleClick = param => e => {
     
        if(this.state.device_type=='mobile'){
            var url =getBaseUrl()+"/containers/plaid_pdf/download/"+this.state.id+".pdf";
            window.open(url);
            this.onCloseModal();
        }
        else{
            this.onOpenModal();
        }
    }




    async listingOpt(){
        let agentParam = [{
            url: "/showings/listingCreator"
        }];
        getApiData(agentParam,true).then(res => {
        this.setState({'listingCreator':res.data});
        }).catch(error => {
            if(document.getElementById('show_loader_on_click'))
            document.getElementById('show_loader_on_click').style.display = "none";

        if(error.response){
            console.log("error----",error.response);
            //this.setState({myerrormsg: error.response.data.error.message});
        }
        });
    }



    removePdf=()=>{
        let where=[{
            url:"/applications/removepdf/"+this.state.id
        }];
    
       getApiData(where,true).then(res=>{
                console.log("--res",res);
                this.checkApp2(0);
        }).catch(error=>{
            document.getElementById('show_loader_on_click').style.display = "none";

        })
    }

    
    deletePlaidStatement=()=>{
       
        confirmAlert({
            title: '',
            message: <h4 style={{fontFamily: 'Cormorant Garamond',  fontSize: '24px', marginTop: '10px'}}>
            Are you sure you want to upload your bank statement manually? 
            Your downloaded plaid statement will be discarded.</h4>,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    this.removePdf();
                }
              },
              { 
                label: 'No',
                onClick: () => {
                    //alert('Click No')
                }
              }
            ]
            
          })
    }
   

    checkApp2(value){
        this.setState({is_plaid:value});
        }

      //plaid

/**
 * additional doc file upload
 */
AdditionalDocDelete=param=>event=>{
    let filename=param[0]
           let where = [{
               url: "/deleteAddBuildDoc"
               }];
               var method = 'post';
               let data={
                   fileName:filename,
                   app_id:this.state.id
               };
              postApiData(where, data, true).then(res_save => {
                  console.log("res_save",res_save);
                   if (res_save.data){
                       this.getAppBuildingDoc(this.state.id);
                   }
                   this.setState({showLoader:false});
               }).catch(error => {
                   this.setState({showLoader:false});
               console.log('in catch', error);
               });
       
}
AdditionalDocHandler = param => event =>{
    let filename=param[0]
    let file_id=param[1]
    //.replace(/^\s*/, "-");
    if(event.target.files[0]){
     let imgFlag = true;
    const maxFileSize = 5242880;   //5mb
    const value = event.target.files[0].name;
    const ext = value.substring(value.lastIndexOf('.')).toLowerCase();
    if(!['.jpg','.jpeg','.JPG','.png','.pdf','.PNG'].includes(ext)){
        this.setState({add_doc_error: ext+' is not supported file extension.'})
        imgFlag = false;
    }
    if(event.target.files[0].size>maxFileSize){
        this.setState({add_doc_error:"File size is too big, upto 5MB allowed."})
        imgFlag = false;
        }
    if(imgFlag) {
        this.setState({add_doc_error:""})
        this.fileAddDocUpload(event.target.files[0],filename,ext,file_id);
        this.clientMasterDocUpload(event.target.files[0],filename,ext,file_id);
    }
 }            
}



async clientMasterDocUpload(file,filename,ext,file_id="") {
console.log("file_id",file_id);
    if(file_id!=''){

        let where = [{
            url: "/Clients/upload/deleteuploadeddoc"
            }];
           
           postApiData(where, {'id':file_id,client_id:this.state.clientId}, true).then(res_save => {
                console.log("res_save=>",res_save);
                if (res_save.data){
                    let where = [{
                        url: '/containers/client-uploaded-doc/upload'
                   }];
                   const formData = new FormData();
                   formData.append('file',file);
                   this.setState({showLoader:true});
               
                   postApiData(where, formData,'post',true).then(res => {
                       if(res.data.success)
                       {
                           console.log('res.data.file_name',res.data.file_name)
                           let where = [{
                               url: "/Clients/upload/clientuploaddoc"
                               }];
                              
                              postApiData(where, {'uploadedFile':res.data.file_name,'doc_id':file_id,'client_id':this.state.clientId}, true).then(res_save => {
                                   console.log("res_save=>",res_save);   
                               }).catch(error => {
                                   this.setState({showLoader:false});
                               console.log('in catch', error);
                               });
                       }
                   })
                       .catch(error => {
                           console.log(error);
                           this.setState({showLoader:false});
                       });
                }
                this.setState({showLoader:false});
     })

    }
    
}



studentDocHandler = param => event =>{
    let filename=param[0]
    //.replace(/^\s*/, "-");
    if(event.target.files[0]){
     let imgFlag = true;
    const maxFileSize = 5242880;   //5mb
    const value = event.target.files[0].name;
    const ext = value.substring(value.lastIndexOf('.')).toLowerCase();
    if(!['.jpg','.jpeg','.JPG','.png','.pdf','.PNG'].includes(ext)){
        this.setState({student_doc_error: ext+' is not supported file extension.'})
        imgFlag = false;
    }
    if(event.target.files[0].size>maxFileSize){
        this.setState({student_doc_error:"File size is too big, upto 5MB allowed."})
        imgFlag = false;
        }
    if(imgFlag) {
        this.setState({student_doc_error:""})
        console.log("event.target.files[0],filename,ext",event.target.files[0],filename,ext);
       this.fileAddDocUpload(event.target.files[0],filename,ext);
    }
 }            
}

async fileAddDocUpload(file,filename,ext,file_id="") {
    let where = [{
         url: '/containers/building-additional-doc/upload'
    }];
    const formData = new FormData();
    formData.append('file',file);
    this.setState({showLoader:true});

    postApiData(where, formData,'post',true).then(res => {
        if(res.data.success)
        {
            let where = [{
                url: "/documentSave"
                }];
                var method = 'post';
                let data={
                    uploadedFile:res.data.file_name,
                    fileName:filename,
                    file_id:file_id,
                    ext:ext,
                    app_id:this.state.id
                };
               postApiData(where, data, true).then(res_save => {
                    console.log("res_save=>",res_save);
                    if (res_save.data){
                        this.getAppBuildingDoc(this.state.id);
                        //call a function to get and update image
                    }
                    this.setState({showLoader:false});
                }).catch(error => {
                    this.setState({showLoader:false});
                console.log('in catch', error);
                });
        }
    })
        .catch(error => {
            console.log(error);
            this.setState({showLoader:false});
        });
}

getAppBuildingDoc(app_id){
    this.setState({
        showLoader:true
    })
    let where = [{
        url: "/getDocList",
        where: {"app_id":app_id},
        }];
       getApiData(where,true).then(res => {
           console.log("uploaded_additional_doc",res.data);
            if (res.data){
              this.setState({uploaded_additional_document:res.data,uploaded_additional_document_count:res.data.length,showLoader:false},()=>{
                  //do stuff here
              });
            }
        }).catch(error => {
        console.log('in catch', error);
        });
}
/**
 * end
 */
preventKeystroke=(event)=>{
    console.log("keycode---", event.keyCode);
    if (event.keyCode) {
        event.preventDefault();
    }
}
displaySwitch=()=>{
    this.setState({
        displaySwitch:true,
        apartment_address:'',
        unit_number:''
    })
}


//Save one page application
saveOnePageApplication= e => {
    e.preventDefault();
    this.setState({linkingLoader: true});
    console.log("in saveOnePageApplication---")
    if(!this.state.uploadedPdf || !this.state.gov_issued_id || !this.state.imagescr){
        this.setState({gov_id_error:'Required.'})
    }
    else{
        this.setState({gov_id_error:''})
    }

    if(this.state.fileUploadFlag){
        this.setState({fileUploadFlag_error:''})
    }else{
        this.setState({fileUploadFlag_error:'Required.'})
    }
    
        let where = [{
            url: "/onePageApplication"         //applications
            }];
            var method = 'post';
             //Encrypting SSN number to save in database
            let key = encHex.parse("0123456789abcdef0123456789abcdef");
            let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
            this.state.social_security_number_encrypt=aes.encrypt(this.state.social_security_number, key, {iv:iv, padding:padZeroPadding}).toString();
            this.state.email_encrypt=aes.encrypt(this.state.email_address, key, {iv:iv, padding:padZeroPadding}).toString();
            this.state.your_phone_encrypt=aes.encrypt(this.state.your_phone, key, {iv:iv, padding:padZeroPadding}).toString();
            
            
            let data = {
                //agent details
               'agent_broker_name':this.state.agent_broker_name, 
               'agent_broker_firm':this.state.agent_broker_firm, 
               'agent_broker_email':this.state.agent_broker_email, 
               'agent_broker_phone':this.state.agent_broker_phone, 
       
               //Apartment details
               'apartment_address':this.state.apartment_address,
               'unit_search_id':this.state.unit_search_id, 
               'lease_months':this.state.lease_months, 
               'start_date':this.state.start_date,
       
               //Info for future landlord
               'add_name':this.state.add_name,
               'email_address':this.state.email_encrypt,
               'your_phone':this.state.your_phone_encrypt,
               'date_of_birth':this.state.date_of_birth,

               'social_security_number':this.state.social_security_number_encrypt,
               'gov_issued_id':this.state.gov_issued_id,
               'uploadTypeFlag':this.state.uploadType,
               'imageData':this.state.imagescr,
               
               //Current Address details
               'current_address': this.state.current_address,
               'city':this.state.city,
               'state':this.state.state,
               'zip':this.state.zip,
               'current_rent_amount': this.state.current_rent_amount,
               'landlord_management_company_name': this.state.landlord_management_company_name,
               'long_live':this.state.long_live,
               'landlord_contact':this.state.landlord_contact,
               'co_applicant':this.state.co_applicant,
               
               'other_occupants':this.state.other_occupants,
       
               //pets details
               'have_pets':this.state.is_pets,
               'pets_description':this.state.pets_description,
       
               //Current working details
               'current_employer':this.state.current_employer?this.state.current_employer:'',
               'job_title':this.state.job_title?this.state.job_title:'',
               'employer_phone_number':this.state.employer_phone_number?this.state.employer_phone_number:'',
               'current_salary':this.state.current_salary?this.state.current_salary:'',
               'work_address':this.state.work_address?this.state.work_address:'',
               'supervisor_name':this.state.supervisor_name?this.state.supervisor_name:'',
               'annual_income':this.state.annual_income?this.state.annual_income:'',
               'length_of_employment':this.state.length_of_employment?this.state.length_of_employment:'',
               
               'is_student':this.state.is_student,
               
               'university_name':this.state.university_name,
               'major_program':this.state.major_program,
               'hourly_course_load':this.state.hourly_course_load,
               'intended_graduation_date':this.state.intended_graduation_date,
       
               //client signature
               'signature':this.state.signature,
            };
            postApiData(where, data, true).then(res => {
                if (res.data){
                this.setState({id:res.data.id,is_id_set:true});
                //for transunion one page
                this.saveUploadedDocuments(res.data.id);
                }
            }).catch(error => {
                
            console.log('in catch', error);
            });
            
    
    
}

saveUploadedDocuments(applicationId){
    if(applicationId){
        let whereFind = [{
            url: "/onePageApplication/"+applicationId
        }]; 
       
        let data=[];
        if(this.state.fileUpload.length>0){
            this.state.fileUpload.map((file,i)=>{
                data.push({
                    'application_id':applicationId,
                    'file_name':this.state[file],
                    'real_name':this.state[file+'_real']
                })
            })
            
        }
        if(this.state.fileUploadFour.length>0){
            this.state.fileUploadFour.map((file,i)=>{
                data.push({
                    'application_id':applicationId,
                    'file_name':this.state[file],
                    'real_name':this.state[file+'_real']
                })
            })
            
        }
            
        postApiData(whereFind,data,true).then(res => {
            this.setState({successMsg:'Record saved successfully.'});
            window.scrollTo(0, 0);
            window.location.href="/client/onepagemakepayment/"+applicationId;
            })
        .catch(error => {
            
            console.log(error);
        });
    }
         
       
}

    render() {
 
        
       
        console.log('agentBrokerFlag==',this.state.agentBrokerFlag);
        console.log('apartmentFlag==',this.state.apartmentFlag);
        console.log('landlordFlag==',this.state.landlordFlag);
        console.log('currentAddressFlag==',this.state.currentAddressFlag);
        console.log('petsFlag==',this.state.petsFlag);
        console.log('currentlyWorkFlag==',this.state.currentlyWorkFlag);
        console.log('signature==',this.state.signature);
        console.log('disclousreCheck==',this.state.disclousreCheck);
        console.log('fileUploadFlag==',this.state.fileUploadFlag);


        let date =new Date();
        date.setDate(date.getDate() - 1);
        
        //let ht=<span onClick={this.displaySwitch} style={{"cursor": "pointer"}}>No suggestions available from Skyward Building.Click here to continue</span>;
        let ht=<span>No suggestions available.</span>;
       
        const { value } = this.state;
        const {open} = this.state;
         var handleStripeSubmit  =   this.handleStripeSubmit;
        
        
        let imgsource="";
        if(this.state.uploadType==1){
            imgsource=this.state.imagescr;
        }else if(this.state.uploadType==0){
            imgsource=this.state.govIdimage;
        }
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "environment"
          };
          function contains (arr, key, val) {
            for (var i = 0; i < arr.length; i++) {
                if(arr[i][key] === val) return 'Checked';
            }
            return false;
        }
        const stateDropDown = this.state.usStates.map((value)=>{
            return  <option value={value}>{value}</option>
          })	
        const unitDropDown = this.state.unitListingCreator.map((value)=>{
            return  <option value={value.unit}>{value.unit}</option>
          })
       
        const listingDWUnitList = this.state.unitListingCreator.map((un,index) => 
        (un.flag==1) ? <li id="searchkey_unit" key={un.id} value={un.id} name={un.unit} onClick={this.handleListingCreatorUnitSearch}>{un.unit}</li>:'');
        let listingOfAddDocs='';
          if(this.state.additional_doc_info_loaded && this.state.additional_document_step && this.state.additional_doc_count>0){
            let arrayOfDocs=this.state.additional_document;
            listingOfAddDocs = arrayOfDocs.map((docName,index) =>{
            return <div>
              {/* <p>{docName}</p><p><input type="file" id={`addDoc_`+index} onChange={this.AdditionalDocHandler([docName])}/></p>*/}  
              {/* <p className="docNameHead">{docName}</p>
              {(contains(this.state.uploaded_additional_document, "fileName", docName))?<div className="successMsgUpload">Success <i class="fa fa-check" aria-hidden="true"></i>
              <input type="file" id={`addDoc_`+index} onChange={this.AdditionalDocHandler([docName])}/> 
              {docName} <span onClick={this.AdditionalDocDelete([docName])}>Delete</span>
            </div>:<div className="upload-btn-wrapper" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Upload</button>
              <input type="file" id={`addDoc_`+index} onChange={this.AdditionalDocHandler([docName])}/></div>} */}
               <p className="docNameHead">{docName.document_name}</p>
              {(contains(this.state.uploaded_additional_document, "file_id", docName.id))?<div>
              <div className="upload-btn-wrapper successMsgUpload" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Success <i class="fa fa-check" aria-hidden="true"></i></button>
              <input type="file" id={`addDoc_`+index} onChange={this.AdditionalDocHandler([docName.document_name,docName.id])}/></div> 
              <p className="deleteBtnUpload" >{docName.document_name} <span onClick={this.AdditionalDocDelete([docName.document_name])}>Delete</span></p></div>
            :<div className="upload-btn-wrapper" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Upload</button>
              <input type="file" id={`addDoc_`+index} onChange={this.AdditionalDocHandler([docName.document_name,docName.id])}/></div>}
               {/*
                   this.state.uploaded_additional_document.map((docValue,index) =>{
                  return  (docValue.fileName==docName)?(docValue.ext!='.pdf')?<img src={getBaseUrl()+"/containers/building-additional-doc/download/"+docValue.uploadedFile} height="130px" width="210px"/>:<img src={getBaseUrl()+"/containers/editor_images/download/pdf.png"} height="130px" width="210px"/>:''
                   })
                */} 

                {/* {
                   this.state.uploaded_additional_document.map((docValue,index) =>{
                  return  (docValue.fileName==docName)?(docValue.ext!='.pdf')?<div className="successMsgUpload">Success <i class="fa fa-check" aria-hidden="true"></i>
                  </div>:<div className="successMsgUpload">Success <i class="fa fa-check" aria-hidden="true"></i></div>:''
                   })
                }  */}
            
            </div>
            
            });    
          }

        
        return (
            <div>
            <Header />
            
        <main className="strip-bg newappform">
    {(this.state.linkingLoader)?<div className="show_loader_on_click" id="show_loader_on_click"></div>:''} 
    
    <div className="container">
                <div className="form-panel">
        {this.state.successMsg?(<h2 style={{color:'red', textAlign:'center'}}>{this.state.successMsg}</h2>):null}
                    <h2 className="text-center">Apply for an apartment</h2>
                    Please fill out only one application per applicant. If you are applying with a roommate or partner, please have them submit a separate application.
                    <div className="form-container">
                        <div className='form-widget login-widget'>
                            <div className="message">Agent/Broker Details</div>
                                <div className="form-section">
                                <Row>
                                    <Col md={6}> 
                                    <label> Agent/Broker Name</label>
                                            <FormGroup controlId="agent_broker_name">
                                                <FormControl
                                                autoFocus
                                                value={this.state.agent_broker_name}
                                                onChange={this.handleAgentBrokerName}
                                                type="text"
                                                required 
                                                />      
                                                <span className="inline-error-class"> {this.state.agent_broker_name_error !==''?this.state.agent_broker_name_error:''}   </span>                                   
                                            </FormGroup>
                                    </Col>
                                    <Col md={6}> 
                                    <label> Agent/Broker Firm</label>
                                            <FormGroup controlId="agent_broker_firm">
                                                <FormControl
                                                autoFocus
                                                type="Email"
                                                value={this.state.agent_broker_firm}
                                                onChange={this.handleAgentBrokerFirm}
                                                required 
                                                />                                         
                                            </FormGroup>
                                    </Col>

                                    <Col md={6}> 
                                    <label> Agent/Broker Email</label>
                                            <FormGroup controlId="agent_broker_email">
                                                <FormControl
                                                autoFocus
                                                value={this.state.agent_broker_email}
                                                onChange={this.handleAgentEmail}
                                                type="text"
                                                required 
                                                />     
                                                <span className="inline-error-class"> {this.state.agent_email_error !==''?this.state.agent_email_error:''}</span> 
                                            </FormGroup>
                                    </Col>
                                    <Col md={6}> 
                                    <FormGroup controlId="agent_broker_phone">
                                    <label> Agent/Broker Phone</label>
                                            <input 
                                                type='text'
                                                id="agent_broker_phone"
                                                required 
                                                className="form-control"
                                                maxLength="12"
                                                placeholder="XXX-XXX-XXXX"
                                                value={this.state.agent_broker_phone}
                                                onChange={this.handleAgentBrokerPhone}
                                            /> 
                                            <span className="inline-error-class"> {this.state.agent_broker_phone_error !==''?this.state.agent_broker_phone_error:''}</span>                                       
                                           </FormGroup>
                                    </Col>
                                </Row>
                                </div>
                            </div>
                                     
                    </div> 
                    {this.state.mymsg!==''?(<div className="alert alert-success"><strong>{this.state.mymsg}</strong></div>):(null)}
        {this.state.myerrormsg!==''?(<div className="alert alert-danger"><strong>{this.state.myerrormsg}</strong></div>):(null)}
                    <div className="form-container" id="application_form_section_one">
                    <div className='form-widget login-widget'>
                        <div className="message">Which apartment would you like to apply for today?</div>
                        <div className="form-section">

                        

                        <form >
                            <div className="form-wrap">
                          
                            
                                <Row>
                                    <Col md={4}> 
                                    <label> Apartment Address</label>
                                    {/* <FormGroup controlId="apartment_address" style={{display:(this.state.displaySwitch)?'none':''}}>
                                <div>
                                <input 
                                type="text" 
                                id="listing_search_id" 
                                onChange={this.handleChange} 
                               
                                autoComplete="off" 
                                value={this.state.listing_search_id}  
                                className="form-control"
                                />
                                <input 
                                type="hidden" 
                                name="listing_selected_id" 
                                id="listing_selected_id" 
                                value="0" 
                                />
                                <div className="box agentNameOnBoard" id="listing_search_id_values" style={{display:'none',overflow:'auto','margin-bottom':'20px',maxHeight:'275px'}} >
                                  
                                </div>
                            </div>
                             </FormGroup>  */}
                             <FormGroup controlId="apartment_address" style={{display:(this.state.displaySwitch)?'none':''}}>
                            <PlacesAutocomplete
                                value={this.state.apartment_address}
                                onChange={this.handleApartmentChange}
                                onSelect={this.handleApartmentSelect}
                                searchOptions={searchOptionsNewYork}
                                maxLength={100}
                                // autoComplete="off" 
                            
                            >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                {...getInputProps({
                                    placeholder: '',
                                    className: 'form-control'
                                })}
                                />
                                <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {

                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                        })}
                                    >
                                        <span>{
                                        suggestion.description.substring(0,suggestion.description.lastIndexOf(','))
                                        }
                                        </span>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>
      <span className="inline-error-class"> {this.state.apartment_address_error !==''?this.state.apartment_address_error:''} </span>
                                            </FormGroup>  
                             
                            </Col>
                            <Col md={4}>
                           

                                            <label> Unit Number</label>
                                            <FormGroup controlId="unit_number" style={{display:(this.state.displaySwitch)?'none':''}}>
                                            <div>
                                               <input 
                                                type="text" 
                                                id="unit_search_id" 
                                                onChange={this.handleUnitNumber} 
                                                autoComplete="off" 
                                                value={this.state.unit_search_id}  
                                                className="form-control"
                                                 />
                                                {/* <select
                                                id="unit_number" className="form-control"
                                                value={this.state.unit_number}
                                                onChange={this.handleChange}
                                               >
                                                {this.state.unit_number?<option>{this.state.unit_number}</option>:<option>Select Unit</option>}
                                                {unitDropDown}
                                                </select>
                                                <input 
                                                type="hidden" 
                                                name="unit_selected_id" 
                                                id="unit_selected_id" 
                                                value="0" 
                                                />  */}
                                             
                                            </div>
                                            <span className="inline-error-class"> {this.state.unit_error !==''?this.state.unit_error:''}</span>
                                            </FormGroup>

                                            <FormGroup controlId="unit_number" style={{display:(this.state.displaySwitch)?'':'none'}}>
                                            <div>
                                                <input 
                                                type="text" 
                                                id="unit_search_id" 
                                                onChange={this.handleListingCreatorUnitSearch} 
                                                autoComplete="off" 
                                                value={(this.state.unit_number!='')?this.state.unit_number:''}  
                                                className="form-control"
                                                />
                                            </div>
                                            </FormGroup>
                            </Col>
                            <Col md={4}>
                            <label> Desired lease duration (months)</label>
                                            <div className="duration-widget">
                                                <FormGroup>
                                                <label className="container-rd">
                                                    <input type="radio" name="lease_months" value="12" id="lease_months_12" onClick={this.handleLease} defaultChecked={this.state.lease_months=='12'?true:false}/>
                                                    <span className="checkmark"></span>
                                                    <div className="txt">12</div>
                                                </label>
                                                <label className="container-rd">
                                                    <input type="radio"  name="lease_months" value="18" id="lease_months_18" onClick={this.handleLease} defaultChecked={this.state.lease_months=='18'?true:false}/>
                                                    <span className="checkmark"></span>
                                                    <div className="txt">18</div>
                                                </label>
                                                <label className="container-rd">
                                                    <input type="radio"  name="lease_months" value="24" id="lease_months_24" onClick={this.handleLease} defaultChecked={this.state.lease_months=='24'?true:false}/>
                                                    <span className="checkmark"></span>
                                                    <div className="txt">24</div>
                                                </label>
                                                <span className="inline-error-class"> {this.state.lease_month_error !==''?this.state.lease_month_error:''}  </span>
                                                </FormGroup>
                                            </div>
                                </Col>

                                </Row>
                          
                                            

                                                  



                                            

                                            
                            <div><label>Requested Start Date</label></div>
                            <DatePicker
                            id="start_date"
                            onChange={this.handleStartDateChange}
                            value={((this.state.start_date)?new Date(this.state.start_date):'')}
                            name="start_date"
                            dateFormat="MM-DD-YYYY"
                            placeholder="MM/DD/YYYY"
                            minDate={new Date()}
                            onKeyDown = {(e)=>this.preventKeystroke(e)}
                            />


                            <span className="inline-error-class"> {(this.state.start_date_error !=='')?this.state.start_date_error:''} </span>



                                        </div>
                                        
                                        <ul className="pagination justify-content-center mt-5">
                                        <li className="page-item left"><button to="" className="page-link" > </button></li>
                                        <li className="page-item mid"><button to=""></button></li>
                                        <li className="page-item right"><button to="#" className="page-link btn active" disabled={!this.validateStepOne()} onClick={this.stepOne(["application_form_section_one", "application_form_section_two",this.state.displaySwitch])}>Next <i className="fa fa-chevron-right" aria-hidden="false"></i></button></li>
                                    </ul>
                                    </form>
                                </div>
                            </div>
                            <div className="progress-widget">
                                <div className="bar step1">
                                </div>
                            </div>
                            
                        </div>

                        <div className="form-container" id="application_form_section_two" style={{ display: 'block' }}>
                        
                            <div className='form-widget login-widget'>
                                <div className="message">Your future landlord would like to know a little more about you</div>
                                <div className="form-section">
                                <form >

                                <Row>

                              
                                    <Col md={4}> 
                                    <label> Legal Name</label>
                                            <FormGroup controlId="add_name">
                                            <FormControl
                                                autoFocus
                                                value={this.state.add_name}
                                                onChange={this.handleAddName}
                                                type="text"
                                                required 
                                                />      
                                                <span className="inline-error-class"> {this.state.add_name_error !==''?this.state.add_name_error:''}   </span>                                   
                                            </FormGroup>
                                    </Col>
                                    <Col md={4}> 
                                    <label> Email Address</label>
                                            <FormGroup controlId="email_address">
                                            <FormControl
                                                autoFocus
                                                value={this.state.email_address}
                                                onChange={this.handleEmailAddress}
                                                type="text"
                                                required 
                                                />     
                                                <span className="inline-error-class"> {this.state.email_address_error !==''?this.state.email_address_error:''}</span> 
                                            </FormGroup>
                                    </Col>
                                    <Col md={4}> 
                                    <label> Phone No.</label>
                                            <FormGroup controlId="your_phone">
                                            <input 
                                                type='text'
                                                id="your_phone"
                                                required 
                                                className="form-control"
                                                maxLength="12"
                                                placeholder="XXX-XXX-XXXX"
                                                value={this.state.your_phone}
                                                onChange={this.handleAgentBrokerPhone}
                                            /> 
                                            <span className="inline-error-class"> {this.state.your_phone_error !==''?this.state.your_phone_error:''}</span>                                       
                                                                                   
                                            </FormGroup>
                                    </Col>
                                    <Col md={6}> 
                                    <div className="form-wrap">
                                            <label> Date of birth</label>
                                            
                                            {/* <DatePicker
                                            onChange={this.handleDOBChange}
                                            value={((this.state.date_of_birth)?new Date(this.state.date_of_birth):'')}
                                            name="date_of_birth"
                                            dateFormat="MM-DD-YYYY"
                                            placeholder="MM/DD/YYYY"
                                            maxDate={new Date()}
                                            onKeyDown = {(e)=>this.preventKeystroke(e)}
                                            /> */}
                                            <input 
                                                type='text'
                                                id="date_of_birth"
                                                required 
                                                className="form-control"
                                                maxLength="10"
                                                placeholder="MM/DD/YYYY"
                                                value={this.state.date_of_birth}
                                                onChange={this.handleDOBChange}
                                                autoComplete="off"
                                            />
                                            
                                            <span className="inline-error-class"> {this.state.dob_error !==''?this.state.dob_error:''} </span>
                                            <div className="clearfix"></div>
                                            <label>Social Security Number</label>
                                            <div className="form-group">
                                            <input 
                                                type='text'
                                                id="social_security_number"
                                                required 
                                                className="form-control"
                                                maxLength="11"
                                                placeholder="XXX-XX-XXXX"
                                                value={this.state.social_security_number}
                                                onChange={this.handleChange}
                                            />
                                            <span className="inline-error-class"> {this.state.social_sn_error !==''?this.state.social_sn_error:''} </span>
                                            </div>
                                            
                                            
                                        </div>
                                    </Col>
                                    <Col md={6}> 
                                    <label>Government issued ID</label>
                                        <div className="form-group">
                                            <div className="clearfix">
                                                <div className="pull-left">
                                                    
                                                    {(this.state.device_type=='mobile') ? (
                                                       <div className="upload-btn-wrapper mtop upload-btn-wrapper-mobile mobilebtn">  <input type="file" className="btn btn-small" onChange={this.fileChangedHandler}  accept="image/*" capture="camera" />Take a picture <i className="fa fa-camera" aria-hidden="true"></i></div>
                                                      ) : (
                                                       
                                                        (this.state.webCamStatus == false) ?
                                                           (<div className="upload-btn-wrapper mtop"><button className="btn btn-small" type="button" onClick={this.webcamstart} >Take a picture <i className="fa fa-camera" aria-hidden="true"></i></button></div>):(<div className="upload-btn-wrapper mtop"><button className="btn btn-small" type="button" onClick={this.webcamstart}>Close Webcam <i className="fa fa-camera" aria-hidden="true"></i></button>
                                                            </div>)
                                                  
                                                    )}
                                                    
                                                    
                                                  
                                                   
                                                </div>
                                                <div className="pull-right">
                                                    <div className="upload-btn-wrapper mtop" id="gov_issued_id_cont">
                                                        <button className="btn btn-small" id="uploadBtn" type="button">Upload</button>
                                                        <FormGroup controlId="gov_issued_id">
                                                         <FormControl type="file" id="uploadfile" onChange={this.fileChangedHandler} required style={{marginTop: '10px'}} />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <br/><br/><br/><span className="inline-error-class"> {this.state.gov_id_error !==''?this.state.gov_id_error:''} </span>
                                                

                                            </div>
                                            {(this.state.webCamStatus == true) ?<div className="webcam-wrap clearfix" id="captrue-img">
                                            Position photo ID within highlighted rectangle. Please make sure the image is clear before clicking the capture button.
                                            <Webcam
                                        audio={false}
                                        autoplay={false}
                                        height={124}
                                        ref={this.setRef}
                                        screenshotFormat="image/jpeg"
                                        width={200}
                                        videoConstraints={videoConstraints}
                                        />         
                                       
                                        <button type="button" className="btn btn-small captrue-btn" onClick={this.capture} style={{marginLeft:'0px'}}>Capture photo</button>
                                            
                                                
                                        {/* {this.state.govIdimage ? <img src={`${this.state.govIdimage}`}  alt="gov_id"/>:''} */}
                                        </div>:''}
                                        {this.state.uploadedPdf=='' ? 
                                        <p className="text-center">{imgsource ? <img src={imgsource} className="prev-Img" /> : null}  </p>
                                        :<span center>{this.state.uploadedPdf}</span>}
                                        </div>
                                    </Col>
                                </Row>    
                                        
                                       
                                        <ul className="pagination justify-content-center">
                                    <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_two", "application_form_section_one"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                                    <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                                    <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepTwo()} onClick={this.stepTwo(["application_form_section_two", "application_form_section_three"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>   </ul>
                                    </form>
                                </div>
                            </div>
                            <div className="progress-widget">
                                <div className="bar step2">

                                </div>
                            </div>

                       
                    </div>
                         <div className="form-container" id="application_form_section_three" style={{ display: 'block' }}>
                       
                    <Modal open={this.state.open1} onClose={this.onCloseModal1} center>
						<button className="close urbanmMocal" onClick={this.onCloseModal1}><img src="/close.png"/></button>
			                       <ClientUrbanform onClick={this.onCloseModal1} listing={{application_id:this.state.id}}/>
                        </Modal>
                            <div className='form-widget login-widget'>
                                <div className="message">Where do you live now? </div>
                                <div className="form-section">
                                    <form >
                                        <div >
                                        <Row controlId="current_address">
                                       
                                        <Col md={5}> 
                                        <FormGroup >
                                        <label> Current Address</label>
                                        <PlacesAutocomplete
                                                value={this.state.current_address}
                                                onChange={this.handleCurrentChange}
                                                onSelect={this.handleCurrentSelect}
                                                searchOptions={searchOptions}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                    {...getInputProps({
                                                        placeholder: '',
                                                        className: 'form-control'
                                                    })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                        );
                                                    })}
                                                    </div>
                                                </div>
                                                )}
                                            </PlacesAutocomplete>
                                        <span className="inline-error-class"> {this.state.current_address_error !==''?this.state.current_address_error:''} </span>
                                        </FormGroup>
                                        </Col>
                                        <Col md={5}> 
                                        <FormGroup >
                                    <div className="row cityStateZip mt-0 ml-0">
                                            <div className="col-sm-4 city" >
                                            <label> City</label>
                                            <FormGroup controlId="city">
                                                <FormControl
                                                autoFocus
                                                type="text"
                                                required 
                                                placeholder=""
                                                value={this.state.city}
                                                onChange={this.handleCityChange}
                                                maxLength = {30}
                                                />
                                            <span className="inline-error-class"> {this.state.city_error !==''?this.state.city_error:''} </span>
                                            </FormGroup></div>
                                            <div className="col-sm-4 state">
                                            <label> State</label>
                                            <select
                                                id="state" className="form-control"
                                                value={this.state.state}
                                                onChange={this.handleStateChange}
                                               >
                                               {this.state.state?<option>{this.state.state}</option>:<option>State</option>}
                                                {stateDropDown}
                                                </select>
                                          
                                            <span className="inline-error-class"> {this.state.state_error !==''?this.state.state_error:''} </span>
                                            </div>
                                            <div className="col-sm-4 zip">
                                            <label> Zip</label>
                                            <FormGroup controlId="zip">
                                                <FormControl
                                                autoFocus
                                                type="text"
                                                required 
                                                placeholder=""
                                                value={this.state.zip}
                                                onChange={this.handleZipChange}
                                                maxLength = {5}
                                                />
                                                 <span className="inline-error-class"> {this.state.zip_error !==''?this.state.zip_error:''} </span>
                                           
                                            </FormGroup></div>
                                            </div>
                                                
                                           

                                            {/**#MS-366 */}
                                    <span className="inline-error-class"> {this.state.current_address_error !==''?this.state.current_address_error:''} </span>
                                    </FormGroup>        
                                    </Col>
                                  
                                    </Row>
                                     
                                           
                                               
                                                
                                             
                           

                                            <Row>
                                    <Col md={5}> 
                                    <label> Current Rent Amount</label>
                                            <FormGroup controlId="current_rent_amount">
                                                <FormControl
                                                autoFocus
                                                type="text"
                                                required 
                                                placeholder="$"
                                                autoComplete="off"
                                                value={this.state.current_rent_amount}
                                                onChange={this.handleValidation}
                                                />
                                            <span className="inline-error-class"> {this.state.current_rent_amount_error !==''?this.state.current_rent_amount_error:''} </span>
                                            </FormGroup>
                                             {/**#MS-366 */}
                                    </Col>
                                    <Col md={5}> 
                                    <label> Landlord / management company name</label>
                                            <FormGroup controlId="landlord_management_company_name">
                                                <FormControl
                                                autoFocus
                                                type="text"
                                                required 
                                                placeholder=""
                                                value={this.state.landlord_management_company_name}
                                                onChange={this.handleChange}
                                                maxLength = {30}
                                                />
                                            <span className="inline-error-class"> {this.state.landlord_error !==''?this.state.landlord_error:''} </span>
                                            </FormGroup>
                                             {/**#MS-366 */}
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md={5}> 
                                    <label>How long did you live at this address?</label>
                                            <FormGroup controlId="long_live">
                                                <FormControl
                                                autoFocus
                                                type="text"
                                                required 
                                                placeholder="In months (Required)"
                                                autoComplete="off"
                                                value={this.state.long_live}
                                                onChange={this.handleValidation}
                                                />
                                            <span className="inline-error-class"> {this.state.long_live_error !==''?this.state.long_live_error:''} </span>
                                            </FormGroup>
                                             {/**#MS-366 */}
                                    </Col>
                                    <Col md={5}> 
                                    <label> Landlord contact</label>
                                            <FormGroup controlId="landlord_contact">
                                            <input 
                                                type='text'
                                                id="landlord_contact"
                                                required 
                                                className="form-control"
                                                maxLength="12"
                                                placeholder="XXX-XXX-XXXX"
                                                value={this.state.landlord_contact}
                                                onChange={this.handleAgentBrokerPhone}
                                            /> 
                                            <span className="inline-error-class"> {this.state.landlord_contact_error !==''?this.state.landlord_contact_error:''}</span>                                       
                                                                                   
                                                                                  
                                            </FormGroup>
                                    </Col>
                                    </Row> 
                                    <Row>
                                    <Col md={5}> 
                                    <label> Co-applicants</label>
                                            <FormGroup controlId="co_applicant">
                                                <FormControl
                                                autoFocus
                                                type="text"
                                                required 
                                                placeholder=""
                                                value={this.state.co_applicant}
                                                onChange={this.handleChange}
                                                maxLength = {30}
                                                />
                                            <span className="inline-error-class"> {this.state.co_applicant_error !==''?this.state.co_applicant_error:''} </span>
                                            </FormGroup>
                                    </Col>
                                    <Col md={5}> 
                                    <label> Other Occupants (if any)</label>
                                            <FormGroup controlId="other_occupants">
                                                <FormControl
                                                autoFocus
                                                type="text"
                                                required 
                                                placeholder=""
                                                value={this.state.other_occupants}
                                                onChange={this.handleChange}
                                                maxLength = {30}
                                                />
                                            <span className="inline-error-class"> {this.state.other_occupants_error !==''?this.state.other_occupants_error:''} </span>
                                            </FormGroup>
                                    </Col>
                                    </Row>    


                                        </div>
                                        <ul className="pagination justify-content-center">
                                  <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_three", "application_form_section_two"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                                  <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                                  <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepThree()} onClick={this.stepThree(["application_form_section_three", "application_form_section_four"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                                        </ul>
                                    </form>
                                    {(this.state.application_template=='Urban American')?
                                    <button  className="btn btn-default"   onClick={this.onOpenModal1}> Urban Form</button>
                                    :(null)}
                                </div>
                                
                            </div>
                            <div className="progress-widget">
                                <div className="bar step3">

                                </div>
                            </div>
                      
                    </div>
                      
                      <div className="form-container" id="application_form_section_four" style={{ display: 'block' }}> 
                            <div className='form-widget login-widget'>
                            <div className="message">Do you have any pets? </div>
                            <form>
                                <div className="form-section">
                                    <div style={{maxWidth:'400px', margin:'0 auto'}}>
                                    <Row>
                                <Col md={6}> 
                                    <label className="container-rd">
                                    <input type="radio" name="is_pets" id="is_pets_0" value="0" onClick={this.handlePets}/>
                                    <span className="checkmark"></span>
                                    <div className="txt">No</div>
                                    </label>
                                </Col>
                                <Col md={6}> 
                                <label className="container-rd">
                                    <input type="radio"  name="is_pets" value="1" id="is_pets_1" onClick={this.handlePets}/>
                                    <span className="checkmark"></span>
                                    <div className="txt">Yes</div>
                                 </label>
                                </Col>
                                </Row>
                                    </div>
                             
                                 
                                <div id="pets_wrapper_cont" style={{display:'block'}}>
                                    <FormGroup controlId="pets_description">
                                    <label> Tell us more about your furry friend!<br/>(type, breed, weight, etc.)</label>
                                            <FormControl
                                                value={this.state.pets_description}
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder=""
                                                />
                                            <span className="inline-error-class"> {this.state.pets_description_error !==''?this.state.pets_description_error:''}   </span>
                                    </FormGroup>
                                </div>
                                    <ul className="pagination justify-content-center">
                                         <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_four", "application_form_section_three"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                                         <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                                         <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepFour()} onClick={this.stepFour(["application_form_section_four", "application_form_section_five"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                                    </ul>
                                </div>
                    </form>   
                            </div>
                            <div className="progress-widget">
                                <div className="bar step4">
                                </div>
                            </div>
                    </div>

                    <div className="form-container" id="application_form_section_five" style={{ display: 'block' }}>
                 
                            <div className='form-widget login-widget'>
                                
                                <div className="message">Where do you currently work?</div>
                                <div className="form-section">
                                    <form >
                                    <div className="form-wrap">
                                    <Row>
                                      <Col md={3}>
                                      <FormGroup controlId="current_employer">
                                    <label>Current Employer</label>
                                            <FormControl
                                                value={this.state.current_employer}
                                                onChange={this.handleChangeWork}
                                                type="text"
                                                placeholder=""
                                                />
                                            <span className="inline-error-class"> {this.current_employer_error !==''?this.state.current_employer_error:''}   </span>
                                    </FormGroup>
                                      </Col>
                                      <Col md={3}>
                                      <FormGroup controlId="job_title">
                                    <label>Job Title</label>
                                            <FormControl
                                                value={this.state.job_title}
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder=""
                                                />
                                            <span className="inline-error-class"> {this.state.job_title_error !==''?this.state.job_title_error:''}   </span>
                                    </FormGroup>
                                      </Col>    
                                      <Col md={6}>
                                      <FormGroup controlId="employer_phone_number">
                                    <label>Employer’s phone number (HR, supervisor)</label>
                                            <input 
                                                type='text'
                                                id="employer_phone_number"
                                                required 
                                                className="form-control"
                                                maxLength="12"
                                                placeholder="XXX-XXX-XXXX"
                                                value={this.state.employer_phone_number}
                                                onChange={this.handleChangeWork}
                                            />
                                            <span className="inline-error-class"> {this.state.employer_phone_number_error !==''?this.state.employer_phone_number_error:''}   </span>
                                    </FormGroup>
                                       </Col>   
                                     </Row>   

                                      <Row>
                                    <Col md={6}> 
                                    <label> Salary</label>
                                            <FormGroup controlId="current_salary">
                                                <FormControl
                                                value={this.state.current_salary}
                                                onChange={this.handleValidation}
                                                type="text"
                                                autocomplete="off"
                                                placeholder="$"
                                                />
                                            <span className="inline-error-class"> {this.state.current_salary_error !==''?this.state.current_salary_error:''}   </span>
                                    </FormGroup>
                                    </Col>
                                    <Col md={6}> 
                                    <label> Address</label>
                                            <FormGroup controlId="work_address">
                                            <PlacesAutocomplete
                                value={this.state.work_address}
                                onChange={this.handleWorkAddressChange}
                                onSelect={this.handleWorkAddressSelect}
                                searchOptions={searchOptionsNewYork}
                                maxLength={100}
                                // autoComplete="off" 
                            
                            >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                {...getInputProps({
                                    placeholder: '',
                                    className: 'form-control'
                                })}
                                />
                                <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {

                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                        })}
                                    >
                                        <span>{
                                        suggestion.description.substring(0,suggestion.description.lastIndexOf(','))
                                        }
                                        </span>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>
      <span className="inline-error-class"> {this.state.work_address_error !==''?this.state.work_address_error:''} </span>
                                            </FormGroup>
                                    </Col>
                                    </Row>     
                                        
                                       
                                    
                                   
                                           
                            <FormGroup style={{maxWidth:'300px', margin:'0 auto'}}>
                            <label className="container-rd">
                                <input type="checkbox" name="is_student" id="is_student_1" onClick={this.handleStudent}/>
                                <span className="checkmark"></span>
                                <div className="txt">I’m a student</div>
                            </label>
                            </FormGroup>
                                        </div>
                                        <ul className="pagination justify-content-center">
                                        <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_five", "application_form_section_four"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                                        <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                                        <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepFive()} onClick={this.stepFive(["application_form_section_five", "application_form_section_supervisor"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                                   </ul>
                                    </form>
                                    
                                </div>
                            </div>
                            <div className="progress-widget">
                                <div className="bar step6">

                                </div>
                            </div>

                       
                    </div>
                        {/**MS-359 */}
                        <div className="form-container" id="application_form_section_supervisor" style={{ display: 'block' }}>
                 <div className='form-widget login-widget'>
                     <div className="message">Where do you currently work?</div>
                     <div className="form-section">
                         
                         <form >
                         {(!this.state.is_student)?
                             <div className="form-wrap">
                                 <FormGroup controlId="supervisor_name">
                         <label>Supervisor's Name</label>
                                 <FormControl
                                     value={this.state.supervisor_name}
                                     onChange={this.handleValidation}
                                     type="text"
                                     autocomplete="off"
                                     placeholder=""
                                     />
                                 <span className="inline-error-class"> {this.supervisor_name_error !==''?this.state.supervisor_name_error:''}   </span>
                         </FormGroup>
                         <FormGroup controlId="annual_income">
                         <label>Annual Income</label>
                                 <FormControl
                                     value={this.state.annual_income}
                                     onChange={this.handleValidation}
                                     type="text"
                                     autocomplete="off"
                                     placeholder="$"
                                     />
                                 <span className="inline-error-class"> {this.state.annual_income_error !==''?this.state.annual_income_error:''}   </span>
                         </FormGroup>
                         <FormGroup controlId="length_of_employment">
                         <label>Length of Employment</label>
                                 <FormControl 
                                     type='text'
                                     placeholder="In months"
                                     className="form-control"
                                     value={this.state.length_of_employment}
                                     onChange={this.handleValidation}
                                 />
                                 <span className="inline-error-class"> {this.state.length_of_employment_error !==''?this.state.length_of_employment_error:''}   </span>
                         </FormGroup>
                 
                            </div>
                             :
                             <div className="form-wrap">
                             <FormGroup controlId="university_name">
                     <label>What university do you attend? </label>
                             <FormControl
                                 value={this.state.university_name}
                                 onChange={this.handleValidation}
                                 type="text"
                                 autocomplete="off"
                                 placeholder="University Name"
                                 />
                             <span className="inline-error-class"> {this.university_name_error !==''?this.state.university_name_error:''}   </span>
                     </FormGroup>
                     <FormGroup controlId="major_program">
                     <label>Intended major or program? </label>
                             <FormControl
                                 value={this.state.major_program}
                                 onChange={this.handleValidation}
                                 type="text"
                                 autocomplete="off"
                                 placeholder="Major Program"
                                 />
                             <span className="inline-error-class"> {this.state.major_program_error !==''?this.state.major_program_error:''}   </span>
                     </FormGroup>
                     <FormGroup controlId="hourly_course_load">
                     <label>Hourly course load?</label>
                             <FormControl 
                                 type='text'
                                 placeholder="Hourly course load"
                                 className="form-control"
                                 autocomplete="off"
                                 value={this.state.hourly_course_load}
                                 onChange={this.handleValidation}
                             />
                             <span className="inline-error-class"> {this.state.hourly_course_load_error !==''?this.state.hourly_course_load_error:''}   </span>
                     </FormGroup>

                     
                     <label>Intended graduation date?</label>
                     <DatePicker
                            id="intended_graduation_date"
                            onChange={this.handleValidationDateChange}
                            value={(this.state.intended_graduation_date)?new Date(this.state.intended_graduation_date):new Date()}
                            name="intended_graduation_date"
                            dateFormat="MM-DD-YYYY"
                            placeholder="MM/DD/YYYY"
                            minDate={new Date()}
                            onKeyDown = {(e)=>this.preventKeystroke(e)}
                            />
                             {/* <FormControl 
                                 type='text'
                                 placeholder="Intended graduation date"
                                 className="form-control"
                                 value={this.state.intended_graduation_date}
                                 onChange={this.handleValidation}
                             /> */}
                             <span className="inline-error-class"> {this.state.intended_graduation_date_error !==''?this.state.intended_graduation_date_error:''}   </span>
                    

                     
             
                        </div>}
                        {(!this.state.is_student)?
                             <ul className="pagination justify-content-center">
                             <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_supervisor", "application_form_section_five"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                             <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                             <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepSupervisor()} onClick={this.stepSupervisor(["application_form_section_supervisor", "application_form_section_six"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                        </ul>
                        :
                        <ul className="pagination justify-content-center">
                             <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_supervisor", "application_form_section_five"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                             <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                             <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepSupervisor()} onClick={this.stepSupervisor(["application_form_section_supervisor", "application_form_section_student_upload"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                        </ul>}
                         </form>
                         
                     </div>
                 </div>
                 <div className="progress-widget">
                     <div className="bar step6">

                     </div>
                 </div>
                </div>

                
                <div className="form-container" id="application_form_section_student_upload" style={{ display: 'none' }}>
                      
                      <div className='form-widget login-widget'>
                          <div className="message">Additional Doc Needed</div>
                          <div className="form-section">
                          <span className="inline-error-class"> {(this.state.student_doc_error !=undefined && this.state.student_doc_error !=='')?this.state.student_doc_error:''} </span>
                              <form >
                                  <div className="btn-wrap" style={{maxWidth:'290px'}}>
              <p className="docNameHead">Acceptance letter*</p>
              {(contains(this.state.uploaded_additional_document, "fileName", 'Acceptance letter'))?<div>
              <div className="upload-btn-wrapper successMsgUpload" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Success <i class="fa fa-check" aria-hidden="true"></i></button>
              <input type="file" id={`student_doc_Acceptance_letter`} onChange={this.studentDocHandler(['Acceptance letter'])}/></div> 
              <p className="deleteBtnUpload" >Acceptance letter <span onClick={this.AdditionalDocDelete(['Acceptance letter'])}>Delete</span></p></div>
            :<div className="upload-btn-wrapper" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Upload</button>
              <input type="file" id={`student_doc_Acceptance_letter`} onChange={this.studentDocHandler(['Acceptance letter'])}/></div>} 


              <p className="docNameHead">Student ID*</p>
              {(contains(this.state.uploaded_additional_document, "fileName", 'Student ID'))?<div>
              <div className="upload-btn-wrapper successMsgUpload" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Success <i class="fa fa-check" aria-hidden="true"></i></button>
              <input type="file" id={`student_doc_Student_ID`} onChange={this.studentDocHandler(['Student ID'])}/></div> 
              <p className="deleteBtnUpload" >Student ID <span onClick={this.AdditionalDocDelete(['Student ID'])}>Delete</span></p></div>
            :<div className="upload-btn-wrapper" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Upload</button>
              <input type="file" id={`student_doc_Acceptance_letter`} onChange={this.studentDocHandler(['Student ID'])}/></div>} 


              <p className="docNameHead">Financial aid documents</p>
              {(contains(this.state.uploaded_additional_document, "fileName", 'Financial aid documents'))?<div>
              <div className="upload-btn-wrapper successMsgUpload" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Success <i class="fa fa-check" aria-hidden="true"></i></button>
              <input type="file" id={`student_doc_Financial_aid_documents`} onChange={this.studentDocHandler(['Financial aid documents'])}/></div> 
              <p className="deleteBtnUpload" >Financial aid documents <span onClick={this.AdditionalDocDelete(['Financial aid documents'])}>Delete</span></p></div>
            :<div className="upload-btn-wrapper" style={{width:'100%', textAlign:'left', marginTop:'5px'}}><button class="btn upload">Upload</button>
              <input type="file" id={`student_doc_Financial_aid_documents`} onChange={this.studentDocHandler(['Financial aid documents'])}/></div>} 


                                  </div>
                                  <ul className="pagination justify-content-center">
                              <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_student_upload", "application_form_section_supervisor"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                               <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                               <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepStudentDoc()}  onClick={this.stepSeven(["application_form_section_student_upload", "application_form_section_six"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                           </ul>
                              </form>
                          </div>
                      </div>
                      <div className="progress-widget">
                          <div className="bar step8">

                          </div>
                      </div>

                 
              </div>





                        {/**MS-359 */}
                    <div className="form-container" id="application_form_section_six" style={{ display: 'block' }}>
                    
                    {this.state.plaidError!==''?(<div className="alert alert-danger"><strong>{this.state.plaidError}</strong></div>):(null)}
                            <div className='form-widget login-widget'>
                           
                                <div className="message">This landlord requests your Bank Statements and other documents. Please upload your documents upto max 10MB size and in format ('.jpg','.jpeg','.png','.pdf')</div>
                                <div className="form-section">
                                    {/* <p className="small">You will be logging in directly with your bank. They will only send us your balances and last 2 statements. None of your login details are sent to us. </p> */}
                                    <div className="show_loader_on_click" id="show_loader_on_click" style={{display: 'none' }}></div>
                                    {/* {(this.state.is_plaid==0)?
                                    <PlaidLink
                                    className="plaid-btn"
                                    clientName="Skyward"
                                    env="production"
                                    product={["assets"]}
                                    publicKey={process.env.REACT_APP_PLAID_PRODUCT_KEY}
                                    onExit={this.handleOnExit}
                                    onSuccess={this.handleOnSuccess} >
                                   Take me to my bank login.
                                    </PlaidLink>:''} */}
                                    <form >
                                    <div className="btn-wrap sign-in-block">
                                    
                                    
                                    
                                           {/* {(this.state.is_plaid==1)?(this.state.device_type=='mobile')?<Link to="#" className="btn" id="plaid_manual_link" onClick={this.handleClick([this.state.id])}>View my downloaded statement from Plaid</Link>:<Link to="#" className="btn" id="plaid_manual_link" onClick={this.handleClick([this.state.id])}>View my downloaded statement from Plaid</Link>:''}
                                                <Modal open={open} onClose={this.onCloseModal} center>
                                                    <PlaidDetails app_id={this.state.id} onClick={this.onCloseModal} handleLocationSubmit = {this.checkApp2.bind(this)}/>
                                                </Modal>
                                                
                                           
                                            {(this.state.is_plaid==0) 
                                            ?  <button className="btn" type="button" id="bank_manual_link" onClick={this.bankShowManualContainer}>I’d like to manually upload my bank statements</button>
                                            : <button className="btn" type="button" id="bank_manual_link" onClick={this.deletePlaidStatement}>I’d like to manually upload my bank statements</button>
                                        } */}
                                          
                                            <div id="bank_file_upload_container" style={{ display: 'block' }} >
                                            <span className="inline-error-class"> {this.state.fileUploadFlag_error !==''?this.state.fileUploadFlag_error:''}   </span>
                                            { this.state.fileUpload.map((filename,i)=>{
                                                return i==0?(<div className="row">
                                                <div className="upload-btn-wrapper col-4">
                                                        <button className="btn btn-small upload">Choose File</button>
                                                        <input type="file" onChange={this.bankManualFileUpload} id={filename}/>
                                                </div> 
                                                <div className="col-8">
                                                        <div className="clearfix">
                                                        <span className="pull-left small upload"> {(this.state[filename+'_real'])?<span>{sliceFileName(this.state[filename+'_real'])}</span>:""} </span>
                                                        {(this.state[filename+'_real'])?<span className="pull-right"><button className="btn btn-small delete" type="button" style={{border:'0px',boxShadow:'none'}} onClick={this.deleteBankFile([filename],i)}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                        </div>
                                                </div>
                                                    <span className="inline-error-class col-12"> {this.state[filename+'_error'] !==''?this.state[filename+'_error']:''}   </span>
                                                </div>)
                                                
                                                :(<div className="row" id={filename}>
                                                <div className="upload-btn-wrapper col-4">
                                                       <button className="btn btn-small upload">Choose File</button>
                                                       <input type="file" onChange={this.bankManualFileUpload} id={filename}/>
                                                   </div> 
                                                   <div className="col-8">
                                                    <div className="clearfix">
                                                       <span  className="pull-left small upload"> {(this.state[filename+'_real'])?<span>{sliceFileName(this.state[filename+'_real'])}</span>:""} </span>
                                                       {(this.state[filename+'_real'])?<span  className="pull-right"><button className="btn btn-small delete" type="button" onClick={this.deleteBankFile([filename,'row_'+filename,'row_bank_file_manual_add_more'],i)}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                    </div>

                                                   </div>
                                               <span className="inline-error-class col-12"> {this.state[filename+'_error'] !==''?this.state[filename+'_error']:''}   </span>
       
                                           </div>)

                                                })
                                            }
                                            
                                            
                                            
                                            
                                            {/* <div className="row" id='row_bank_file_manual_3' style={{ display: 'none' }}>
                                            <div className="upload-btn-wrapper col-4">
                                                        <button className="btn btn-small upload">Choose File</button>
                                                         <input type="file" onChange={this.bankManualFileUpload} id="bank_manual_file_3"/>
                                                    </div> 
                                                    <div className="col-8">
                                                    <div className="clearfix">
                                                        <span  className="pull-left small upload"> {(this.state.bank_manual_file_3_real)?<span>{sliceFileName(this.state.bank_manual_file_3_real)}</span>:""} </span>
                                                        {(this.state.bank_manual_file_3_real)?<span className="pull-right"><button className="btn btn-small delete" type="button" onClick={this.deleteBankFile(["bank_manual_file_3",'row_bank_file_manual_3','row_bank_file_manual_add_more'])}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                    </div>
                                                    </div>
                                            <span className="inline-error-class col-12"> {this.state.bank_manual_file_3_error !==''?this.state.bank_manual_file_3_error:''}   </span>  
  
                                            </div> */}
                                            <div className="add-more-wrap"><button type="button" className="btn add-btn" onClick={this.appendInput} id="row_bank_file_manual_add_more"><i className="fa fa-plus"></i> Add More</button></div>
                                            </div>
                                        </div>
                                        
                                        <ul className="pagination justify-content-center">
                                    <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_six", "application_form_section_supervisor"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                                     <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                                     <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepSix()} onClick={this.stepSix(["application_form_section_six", "application_form_section_seven"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                                </ul>

                                    </form>
                                
                                </div>
                            </div>
                            <div className="progress-widget">
                                <div className="bar step7">

                                </div>
                            </div>   
                    </div>
                    {/**
                    additional doc step
                     */}
                     <div className="form-container" id="additional_doc_step" style={{ display: 'none' }}>
                      
                      <div className='form-widget login-widget'>
                          <div className="message">Additional Doc Needed</div>
                          <div className="form-section">
                          <span className="inline-error-class"> {(this.state.add_doc_error !=undefined && this.state.add_doc_error !=='')?this.state.add_doc_error:''} </span>
                              <form >
                                  <div className="btn-wrap" style={{maxWidth:'290px'}}>
                                  {listingOfAddDocs}
                                  </div>
                                  <ul className="pagination justify-content-center">
                              <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["additional_doc_step", "application_form_section_six"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                               <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                               <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepAddDoc()}  onClick={this.stepSeven(["additional_doc_step", "application_form_section_seven"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                           </ul>
                              </form>
                          </div>
                      </div>
                      <div className="progress-widget">
                          <div className="bar step8">

                          </div>
                      </div>

                 
              </div>

                     {/* 4 new upload option */}
                     <div className="form-container" id="application_form_section_ten" style={{ display: 'block' }}>
                    
                    {this.state.plaidError!==''?(<div className="alert alert-danger"><strong>{this.state.plaidError}</strong></div>):(null)}
                            <div className='form-widget login-widget'>
                           
                                <div className="message"> Please upload your documents upto max 10MB size and in format ('.jpg','.jpeg','.png','.pdf')</div>
                                <div className="form-section">
                                   
                                    <div className="show_loader_on_click" id="show_loader_on_click" style={{display: 'none' }}></div>
                                    
                                    <form >
                                    <div className="btn-wrap sign-in-block">
                                    
                                        <div id="file_upload_container_four" style={{ display: 'block' }} >
                                            { this.state.fileUploadFour.map((filename,i)=>{
                                                return i<17?(<div className="row">
                                                <div className="upload-btn-wrapper col-lg-4 col-sm-12">
                                                        <button className="btn btn-small upload">
                                                            {filename=='four_manual_file_0'?'Latest Pay Stub':
                                                            filename=='four_manual_file_1'?'Employment Letter': 
                                                            filename=='four_manual_file_2'?'Latest Tax Return':
                                                            filename=='four_manual_file_3'?'W2 or 1099':
                                                            filename=='four_manual_file_4'?'Latest Pay Stub 2': 
                                                            filename=='four_manual_file_5'?'Latest Bank Statement 1':
                                                            filename=='four_manual_file_6'?'Latest Bank Statement 2':
                                                            filename=='four_manual_file_7'?'Latest Tax Return 2': 
                                                            filename=='four_manual_file_8'?'Previous Landlord Reference Letter':
                                                            filename=='four_manual_file_9'?'Verification of Additional Income':
                                                            filename=='four_manual_file_10'?'Additional Attachment': 
                                                            filename=='four_manual_file_11'?'Additional Attachment':
                                                            filename=='four_manual_file_12'?'Additional Attachment':
                                                            filename=='four_manual_file_13'?'Additional Attachment': 
                                                            filename=='four_manual_file_14'?'Additional Attachment':
                                                            filename=='four_manual_file_15'?'Additional Attachment':
                                                            filename=='four_manual_file_16'?'Latest Utility Bill':'Choose File'}</button>
                                                        <input type="file" onChange={this.bankManualFileUpload} id={filename}/>
                                                </div> 
                                                <div className="col-lg-8 col-sm-12">
                                                        <div className="clearfix">
                                                        <span className="pull-left small upload"> {(this.state[filename+'_real'])?<span>{sliceFileName(this.state[filename+'_real'])}</span>:""} </span>
                                                        {(this.state[filename+'_real'])?<span className="pull-right"><button className="btn btn-small delete" type="button" style={{border:'0px',boxShadow:'none'}} onClick={this.deleteBankFileFour([filename],i)}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                        </div>
                                                </div>
                                                    <span className="inline-error-class col-12"> {this.state[filename+'_error'] !==''?this.state[filename+'_error']:''}   </span>
                                                </div>)
                                                
                                                :(<div className="row" id={filename}>
                                                <div className="upload-btn-wrapper col-4">
                                                       <button className="btn btn-small upload">Choose File</button>
                                                       <input type="file" onChange={this.bankManualFileUpload} id={filename}/>
                                                   </div> 
                                                   <div className="col-8">
                                                    <div className="clearfix">
                                                       <span  className="pull-left small upload"> {(this.state[filename+'_real'])?<span>{sliceFileName(this.state[filename+'_real'])}</span>:""} </span>
                                                       {(this.state[filename+'_real'])?<span  className="pull-right"><button className="btn btn-small delete" style={{border:'0px',boxShadow:'none'}} type="button" onClick={this.deleteBankFileFour([filename,'row_'+filename,'row_bank_file_manual_add_more'],i)}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                    </div>

                                                   </div>
                                               <span className="inline-error-class col-12"> {this.state[filename+'_error'] !==''?this.state[filename+'_error']:''}   </span>
       
                                           </div>)

                                                })
                                            }
                                            
                                            
                                            <div className="add-more-wrap"><button type="button" className="btn add-btn" onClick={this.appendInputFour} id="row_bank_file_manual_add_more"><i className="fa fa-plus"></i> Add More</button></div>
                                            </div>
                                        </div>
                                        <ul className="pagination justify-content-center">
                                    <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_six", "application_form_section_supervisor"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                                     <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                                     <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepSix()} onClick={this.stepSix(["application_form_section_six", "application_form_section_seven"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                                </ul>
                                    </form>
                                </div>
                            </div>
                    </div>
                    {/**
                    additional doc step
                     */}
                     <div className="form-container" id="additional_doc_step" style={{ display: 'none' }}>
                      
                      <div className='form-widget login-widget'>
                          <div className="message">Additional Doc Needed</div>
                          <div className="form-section">
                          <span className="inline-error-class"> {(this.state.add_doc_error !=undefined && this.state.add_doc_error !=='')?this.state.add_doc_error:''} </span>
                              <form >
                                  <div className="btn-wrap" style={{maxWidth:'290px'}}>
                                  {listingOfAddDocs}
                                  </div>
                                  <ul className="pagination justify-content-center">
                              <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["additional_doc_step", "application_form_section_six"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                               <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                               <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepAddDoc()}  onClick={this.stepSeven(["additional_doc_step", "application_form_section_seven"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                           </ul>
                              </form>
                          </div>
                      </div>
                      <div className="progress-widget">
                          <div className="bar step8">

                          </div>
                      </div>

                 
              </div>
                   {/* end of 4 new upload options */}



              {
                  /**
                   * additional doc step
                   */
              }
                    <div className="form-container" id="application_form_section_seven" style={{ display: 'none' }}>
                      
                            <div className='form-widget login-widget'>
                                <div className="message">Did your agent ask you to upload any additional documents?</div>
                                <div className="form-section">

                                    <form >
                                        <div className="btn-wrap">
                                            <button className="btn" onClick={this.additionalUploadNo} type="button" id="additional_button_no">No</button>
                                            <button className="btn" onClick={this.additionalUploadYes} type="button" id="additional_button_yes">Yes</button>
                                            
                                       
                                </div>
                                <div id="additional_file_upload_container" style={{ display: 'none' }}>
                                            <div className="row" id='row_additional_file_manual_1' >
                                            <div className="upload-btn-wrapper col-4">
                                                    <button className="btn btn-small upload">Choose File</button>
                                                    <input type="file" onChange={this.additionalManualFileUpload} id="additional_manual_file_1"/>
                                                </div>
                                                <div className="col-8">
                                                    <div className="clearfix">
                                                    <span className="pull-left small upload"> {(this.state.additional_manual_file_1_real)?<span>{sliceFileName(this.state.additional_manual_file_1_real)}</span>:""} </span>
                                                    {(this.state.additional_manual_file_1_real)?<span className="pull-right"><button className="btn btn-small delete" style={{border:'0px',boxShadow:'none'}} type="button" onClick={this.deleteAdditionalFile(["additional_manual_file_1"])}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                    </div>
                                                </div>
                                                 
                                               <span className="inline-error-class col-12"> {this.state.additional_manual_file_1_error !==''?this.state.additional_manual_file_1_error:''}   </span>

                                            </div>
                                                <div className="row" id="row_additional_file_manual_2" style={{ display: 'none' }}>
                                                
                                                <div className="upload-btn-wrapper col-4">
                                                    <button className="btn btn-small upload">Choose File</button>
                                                     <input type="file" onChange={this.additionalManualFileUpload} id="additional_manual_file_2"/>
                                                </div>
                                                <div className="col-8">
                                                <div className="clearfix">
                                                    <span className="pull-left small"> {(this.state.additional_manual_file_2_real)?<span>{sliceFileName(this.state.additional_manual_file_2_real)}</span>:""} </span>
                                                    {(this.state.additional_manual_file_2_real)?<span className="pull-right"><button className="btn btn-small delete" style={{border:'0px',boxShadow:'none'}} type="button" onClick={this.deleteAdditionalFile(["additional_manual_file_2",'row_additional_file_manual_2','row_additional_file_manual_add_more'])}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                </div> 
                                                 <span className="inline-error-class col-12"> {this.state.additional_manual_file_2_error !==''?this.state.additional_manual_file_2_error:''}   </span>

                                                </div>
                                                 
                                            </div>
                                                <div className="row" id="row_additional_file_manual_3" style={{ display: 'none' }}>
                                                <div className="upload-btn-wrapper col-4">
                                                    <button className="btn btn-small upload">Choose File</button>
                                                     <input type="file" onChange={this.additionalManualFileUpload} id="additional_manual_file_3"/>
                                                </div> 
                                                <div className="col-8">
                                                <div className="clearfix">
                                                    <span className="pull-left small upload"> {(this.state.additional_manual_file_3_real)?<span>{sliceFileName(this.state.additional_manual_file_3_real)}</span>:""} </span>
                                                    {(this.state.additional_manual_file_3_real)?<span className="pull-right"><button className="btn btn-small delete" style={{border:'0px',boxShadow:'none'}} type="button" onClick={this.deleteAdditionalFile(["additional_manual_file_3",'row_additional_file_manual_3','row_additional_file_manual_add_more'])}><i className="fa fa-times-circle" aria-hidden="true"></i></button></span>:""} 
                                                </div>
                                                <span className="inline-error-class col-12"> {this.state.additional_manual_file_3_error !==''?this.state.additional_manual_file_3_error:''}   </span>

                                                </div>
                                            </div>
                                            <div className="add-more-wrap"><button type="button" className="btn add-btn" onClick={this.additionalManageAddMore} id="row_additional_file_manual_add_more"><i className="fa fa-plus"></i> Add More</button></div>

                                       </div>
                                        
                                        <ul className="pagination justify-content-center">
                                    {(this.state.additional_document_step && !this.state.is_student)?
                                    <li className="page-item left">
                                    <button type="button" className="page-link" onClick={this.prevStep(["application_form_section_seven", "additional_doc_step"])}>
                                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button>
                                    </li>
                                    :<li className="page-item left">
                                    <button type="button" className="page-link" onClick={this.prevStep(["application_form_section_seven", "application_form_section_six"])}>
                                    <i className="fa fa-chevron-left" aria-hidden="true">
                                    </i> Prev</button></li>}
                                    
                                     <li className="page-item mid"><button type="button" onClick={this.saveAsDraft()}>Save as a draft</button></li>
                                     <li className="page-item right"><button type="button" className="page-link btn active" disabled={!this.validateStepSeven()}  onClick={this.stepSeven(["application_form_section_seven", "application_form_section_eight"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                                 </ul>
                                    </form>
                                </div>
                            </div>
                            <div className="progress-widget">
                                <div className="bar step8">

                                </div>
                            </div>

                       
                    </div>
                    <div className="form-container" id="application_form_section_eight" style={{ display: 'none' }}>
                     
                            <div className='form-widget login-widget'>
                                <div className="message">Do you want to go back and double check your application?</div>
                                <div className="form-section">

                                    <form>
                                        <div className="btn-wrap">
                                            <button className="btn" onClick={this.prevStep(["application_form_section_eight", "application_form_section_one"])}>Yes</button>
                                            <button className="btn" onClick={this.stepEight(["application_form_section_eight", "application_form_section_nine"])}>No</button>
                                        </div>
                                        <ul className="pagination justify-content-center">
                                    <li className="page-item left"><button type="button" className="page-link" onClick={this.prevStep(["application_form_section_eight", "application_form_section_seven"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                                     <li className="page-item mid"><button type="button"  onClick={this.saveAsDraft()}>Save as a draft</button></li>
                                     <li className="page-item right"><button type="button" className="page-link btn active" onClick={this.stepEight(["application_form_section_eight", "application_form_section_nine"])}>Next <i className="fa fa-chevron-right" aria-hidden="true"></i></button></li>
                                   </ul>
                                    </form>
                                </div>
                            </div>
                            <div className="progress-widget">
                                <div className="bar step9">

                                </div>
                            </div>

                        
                    </div>
                    <div className="form-container">
                        <div className='form-widget login-widget'>
                            <div className="message">Disclosure and agreement</div>
                                <div className="form-section">
                                <Row className="justify-content-md-center">
                                    <Col md={6} style={{flex:'0 0 55%',maxWidth:'55%'}}> 
                                    <div to="" className="btn signature-btn" style={{padding:'6px'}}><span className="sign-box">{this.state.signature}</span></div>
                                    <p className="add-txt-panel" style={{margin:'10px 0px 0px'}}>By clicking submit, I acknowledge that I have been provided access to <Link to data-toggle="modal" data-target="#fcraModal">A Summary of Your Rights Under the Fair Credit Reporting Act</Link> and that I have had the opportunity to review this document and make an informed decision with regards to any Reports and my application.</p>
                                    <FormGroup controlId="signature">
                                 <label></label>
                                    <FormControl
                                                value={this.state.signature}
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder="Sign your signature"
                                                />
                                       <span className="inline-error-class"> {this.state.signatureorerror !==''? this.state.signatureorerror:''}  </span>
                                      </FormGroup>
                                      <FormGroup>
                                       
                                        <input type="checkbox" name="check" id='disclousreCheck' value={this.state.disclousreCheck} onChange={this.handledisclousreCheck}/> I accept <Link to data-toggle="modal" data-target="#termModal" style={{textDecoration:'underline'}}>Terms and Conditions </Link> and <Link to data-toggle="modal" data-target="#uetaModal" style={{textDecoration:'underline'}}>Disclosure</Link> 
                                    </FormGroup>
                                     
                                  
                                    </Col>
                       
                          
                                </Row>
                                </div>
                            </div>
                                     
                    </div> 
                    <div id="termModal" className="modal fade" role="dialog">
                        <div className="modal-dialog" style={{marginTop:'2em'}}>

                        
                            <div className="modal-content">                           
                            <div className="modal-body">
                             <h5>TERMS AND CONDITIONS OF SERVICE</h5>
                             <p><strong>Thank you for conducting you apartment search with Skyward and Keller Williams NYC!</strong></p>   
                             <p>Company reserves the right to change these terms at any time, effective upon the posting of modified terms. It is Your obligation to ensure that You have read, understood and agree to the most recent terms available on the Website.</p>
                            <p>By registering to use the Service and Website You acknowledge that You have read and understood this Agreement and have the authority to act on behalf of any person for whom You are using the Service. You are deemed to have agreed to this Agreement on behalf of any entity for whom You use the Service.</p>
                            <p>By accessing or otherwise using the Service and/or Website, You agree to be bound by the terms of this Agreement. If You do not agree to the terms of this Agreement, do not access or otherwise use the Website or the Service</p>
                            <p><em>These Terms were last updated on February 22, 2019</em></p>
                            
                            <h5>1. DEFINITIONS</h5>
                            <p><strong>"Agreement"</strong> means these Terms of Use.</p>
                            <p><strong>"Confidential Information"</strong> includes all information exchanged between the parties to this Agreement, whether in writing, electronically or orally, including the Service but does not include information which is, or becomes, publicly available other than through unauthorized disclosure by the other party.</p>
                            <p><strong>“Company”</strong> means Skyward Steel LLC and Keller Williams NYC, Skyward and Keller Williams NYC Holdings Inc. and any of its affiliates</p>
                            <p><strong>"Data"</strong> means any data provided or facilitated through a third-party service by You to the Website.</p>
                            <p><strong>"Intellectual Property Right"</strong> means any patent, trade mark, service mark, copyright, moral right, right in a design, know-how and any other intellectual or industrial property rights, anywhere in the world whether or not registered.</p>
                            <p><strong>"Service"</strong> means the online employee benefit services made available (as the same may be changed or updated from time to time by Company) via the Website.</p>
                            <p><strong>"Website"</strong>means the website located at the domain <a target="_blank" href="https://www.moveskyward.com" style={{textDecoration:'underline'}}>www.moveskyward.com</a> or any other site or application operated by Company.</p>
                            <p><strong>"You"</strong> means the applicable user of the Website and the Services. "Your" has a corresponding meaning.</p>
                            <h5>2. USE OF SERVICE</h5>
                            <p>Provided compliance with this Agreement, Company grants You the right to access and use the Service via the Website. This right is non-exclusive, non-transferable, and limited by and subject to this Agreement. Given the nature of the Service and the Website, we cannot guarantee that the Service and/or Website will not be modified or that any functionalities will not be added, removed or altered at any time. By using this Service and/or the Website, you acknowledge and agree that (a) Company is not performing any background checks or providing any consumer reports itself and is simply providing a platform on which the applicants, consumer reporting agencies and applicable landlord/broker/property manager can interact with each other, (b) Company is not making any decisions with regards to your application, has no role in the process other than to connect the applicable parties and provide information that has been provided by other third parties and is not responsible for ensuring proper authorization, consent or adverse action letters, (c) the applicable third parties (the consumer reporting agency in the case of providing information or the applicable landlord/broker/property manager in the case of reviewing, approving or rejecting any application) are solely responsible for the services they provide and (d) that Company shall not be responsible in any way for any third party’s failure to properly provide services or comply with applicable law.</p>
                            <h5>3. YOUR OBLIGATIONS</h5>
                            <p><strong> Payment obligations:</strong></p>
                            <p>You agree that Company and/or its customers (as applicable) may charge you for Services at the prices set forth on the Website. Company may turn any uncollected amounts over to an outside collection agency. Subscriber is aware that Company may possibly change the site fees on occasion.</p>
                            <p><strong>General obligations:</strong></p>
                            <p>You must only use the Service and Website for Your own lawful purposes, in accordance with this Agreement and any notice sent by Company or condition posted on the Website.</p>
                            <p><strong>Access conditions:</strong></p>
                            <p>You must ensure that all usernames and passwords required to access the Service are kept secure and confidential. You must immediately notify Company of any unauthorized use of Your passwords or any other breach of security.</p>
                            <p>When accessing and using the Services, You must:</p>
                            <ul>
                                <li>not attempt to undermine the security or integrity of Company's computing systems or networks or, where the Service are hosted by a third party, that third party's computing systems and networks; </li>
                                <li>not use, or misuse, the Service in any way which may impair the functionality of the Service or Website, or other systems used to deliver the Service or impair the ability of any other user to use the Service or Website;</li>
                                <li>not attempt to gain unauthorized access to any materials other than those to which You have been given express permission to access or to the computer system on which the Service and Website are hosted;</li>
                                <li>not transmit, or input into the Website, any: files that may damage any other person's computing devices or software, content that may be offensive, or material or Data in violation of any law (including Data or other material protected by copyright or trade secrets which You do not have the right to use); and </li>
                                <li>not attempt to modify, copy, adapt, reproduce, disassemble, decompile or reverse engineer any computer programs used to deliver the Service or to operate the Website except as is strictly necessary to use either of them for normal operation.</li>
                           </ul>
                           <p><strong>Indemnity:</strong></p>
                            <p>You will indemnify, defend and hold harmless Company against all claims, costs, damage and loss arising from Your breach of any of this Agreement or any obligation You may have to Company.</p>
                            <h5>4. INTELLECTUAL PROPERTY</h5>
                            <p><strong>General:</strong></p>
                            <p>Company retains all right, title and interest in and to the Service and Website, including all Intellectual Property Rights associated therewith. Company does not grant to You any license, by implication or otherwise, to use or copy the Service and Website or any subsequent version of the Service and Website, or any license rights in any patent, copyright or other Intellectual Property Rights owned by or licensed to Company.</p>
                            <p><strong>Ownership of Data:</strong></p>
                            <p>You grant Company a license to use, copy, transmit, store, and back-up Your information and Data for the purposes of enabling You to access and use the Service and for any other purpose related to provision of services to You, other users or our customers.</p>
                            <h5>5. WARRANTIES AND ACKNOWLEDGEMENTS</h5>
                            <p>THE SERVICE AND WEBSITE ARE PROVIDED “AS IS,” WITH NO WARRANTIES WHATSOEVER WITH RESPECT TO THEIR FUNCTIONALITY, OPERABILITY OR USE, AND COMPANY DOES NOT WARRANT THAT THE SERVICE OR WEBSITE WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE SERVICE OR WEBSITE WILL BE UNINTERRUPTED OR THAT THE SERVICE AND WEBSITE ARE ERROR-FREE. THE ENTIRE RISK REGARDING THE QUALITY AND PERFORMANCE OF THE SERVICE OR WEBSITE WITH YOU. COMPANY HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE AND WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, NONINFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE.</p>
                            <p>IN NO EVENT SHALL COMPANY OR ANY OTHER PARTY WHICH HAS BEEN INVOLVED IN THE CREATION, PRODUCTION, OR DELIVERY OF THE SERVICE OR WEBSITE BE LIABLE FOR ANY DAMAGES WHATSOEVER ARISING FROM OR RELATED TO THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOST PROFITS OR LOSSES RESULTING FROM BUSINESS INTERRUPTION OR LOSS OF DATA), REGARDLESS OF THE FORM OF ACTION OR LEGAL THEORY UNDER WHICH THE LIABILITY MAY BE ASSERTED, EVEN IF ADVISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. WITHOUT, ANYWAY LIMITING OR MODIFYING ANY OF THE FOREGOING, YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE OF COMPANY SERVICES OR THE TERMS OF SERVICE MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER BARRED.</p>
                            <h5>6. ACKNOWLEDGMENTS</h5>
                            <p>You acknowledge and agree that:</p>
                            <ul>
                                <li> Company has no responsibility to any person other than You and nothing in this Agreement confers, or purports to confer, a benefit on any person other than You. </li>
                                <li> The provision of, access to, and use of, the Service is on an "as is" basis and at Your own risk. </li>
                                <li> It is Your sole responsibility to determine that the Service meet the needs of Your business and are suitable for the purposes for which they are used.</li>
                                <li>You remain solely responsible for complying with all applicable laws.</li>
                                <li>If you are entering sensitive personal data including but not limited to social security numbers, dates of birth, names and address information into the Company web-application please understand you do so at your own risk. Although Company takes security very seriously and has safeguards in place, we do not guarantee the security or backup of this or any other data entered into our web-application. We recommend that you obtain an informed consent form from any person whose sensitive data they will be inputting into the Website.</li>

                            </ul> 
                            <h5>7. TERMINATION</h5>
                            <p>Company reserves the right to cancel the account of any customer if, in the judgment of Company, the subscriber's account is the source or target of a violation of any of the other terms of service or for any other reason which Company sees fit in it and our other users’ best interest. Company reserves the right to terminate service without notice for continued and repeated violations of the terms of service. Prior notification to the Customer is not assured.</p>
                            <h5>8. SUPPORT</h5>
                            <p>While we will use commercially reasonable efforts to assist You, we cannot guarantee any specific level of customer support. If You have technical questions, please contact us at support@moveskyward.com</p>
                            <h5>9. GENERAL</h5>
                            <p>If any provision of this Agreement is held to be invalid or unenforceable under any circumstances, its application in any other circumstances and the remaining provisions of this Agreement shall not be affected. No waiver of any right under this Agreement shall be effective unless given in writing by an authorized representative of Company. No waiver by Company of any right shall be deemed to be a waiver of any other right of Company arising under this Agreement. This Agreement shall be governed and construed in all respects by the laws of the State of New York, without giving effect to principles of conflict of laws. This Agreement is the complete and exclusive statement of the agreement between parties regarding the subject matter hereof, and it supersedes any proposal or prior agreement, oral or written, and any other communication relating to the subject matter hereof. You acknowledge that, in providing You access to and use of the Service and Website, Company has relied on Your agreement to be bound by the terms of this Agreement.</p>






                                

                              
                                <p className="text-right"><button type="button" className="btn cl-btn" data-dismiss="modal">Close  <img src="/close.png"/></button></p>
                            </div>
                          
                            </div>

                        </div>
                        </div>       
                        <div id="uetaModal" className="modal fade" role="dialog">
                        <div className="modal-dialog" style={{marginTop:'2em'}}>

                        
                            <div className="modal-content">                           
                            <div className="modal-body">
                                
                            <p>This E Disclosure and Consent is provided in compliance with the Electronic Signatures in Global and National Commerce Act, 15 USC §7001, et seq. (“E-SIGN Act”) and the Uniform Electronic Transactions Act (UETA)
                            </p>
                            <h5>Electronic record and signature disclosure</h5>
                            <p>From time to time, Skyward Steel LLC and Keller Williams NYC (we, us or Company) may be required by law to provide to you certain written notices or disclosures. Described below are the terms and conditions for providing to you such notices and disclosures electronically through the Skyward Steel LLC and Keller Williams NYC electronic signing system. Please read the information below carefully and thoroughly, and if you can access this information electronically to your satisfaction and agree to these terms and conditions, please type your name into the signature box on the attached form.                            </p>
                            <p><strong>Getting paper copies:</strong> At any time, you may request from us a paper copy of any record provided or made available electronically to you by us. You will have the ability to download and print documents we send to you through the Skyward Steel LLC and Keller Williams NYC system during and immediately after signing session and, if you elect to create a Skyward Steel LLC and Keller Williams NYC account, you may access them for a limited period of time (usually 30 days) after such documents are first sent to you. After such time, if you wish for us to send you paper copies of any such documents from our office to you, you will be charged a $1.00 per page fee. You may request delivery of such paper copies from us by following the procedure described below.</p>
                            <p><strong>Withdrawing your consent:</strong> If you decide to receive notices and disclosures from us electronically, you may at any time change your mind and tell us that thereafter you want to receive required notices and disclosures only in paper format. How you must inform us of your decision to receive future notices and disclosure in paper format and withdraw your consent to receive notices and disclosures electronically is described below.</p>
                            <p><strong>Consequences of changing your mind:</strong> If you elect to receive required notices and disclosures only in paper format, it will slow the speed at which we can complete certain steps in transactions with you and delivering services to you because we will need first to send the required notices or disclosures to you in paper format, and then wait until we receive back from you your acknowledgment of your receipt of such paper notices or disclosures. To indicate to us that you are are changing your mind, you must withdraw your consent using the Skyward Steel LLC and Keller Williams NYC ‘Withdraw Consent’ form on the signing page of a Skyward Steel LLC and Keller Williams NYC envelope instead of signing it. This will indicate to us that you  have withdraw your consent to receive required notices and disclosures electronically from us and you will no longer be able to use the Skyward Steel LLC and Keller Williams NYCsystem to receive required notices and consents electronically from us or to sign electronically documents from us.</p>
                            <p><strong>All notices and disclosures will be sent to you electronically:</strong> Unless you tell us otherwise in accordance with the procedures described herein, we will provide electronically to you through the Skyward Steel LLC and Keller Williams NYC system all required notices, disclosures, authorizations, acknowledgments, and other documents that are required to be provided or made available to you during the course of our relationship with you. To reduce the chance of you inadvertently not receiving any notice or disclosure, we prefer to provide all of the required notices and disclosures to you by the same method and to the same address that you have given us. Thus, you can receive all the disclosures and notices electronically or in paper format through the paper mail delivery system. If you do not agree with this process, please let us know as described below. Please also see the paragraph immediately above that describes the consequences of your electing not to receive delivery of the notices and disclosures electronically from us.</p>
                            <h5>How to contact Skyward Steel LLC and Keller Williams NYC</h5>
                            <p>You may contact us at <a href="mailto:support@moveskyward.com" style={{textDecoration:'underline'}}>support@moveskyward.com</a> to let us know your changes as to how we may contact you.</p>
                                

                              
                                <p className="text-right"><button type="button" className="btn cl-btn" data-dismiss="modal">Close  <img src="/close.png"/></button></p>
                            </div>
                          
                            </div>

                        </div>
                        </div>               
                    <div className="form-container" id="application_form_section_nine" style={{ display: 'none' }}>
                        
                    <div className='form-widget login-widget final-widget'>
                                <div className="message ">
                                    <div> This apartment’s application fee: {(this.state.is_skyward_runs_credit==0)?'$20':'$0'}
                                <p className="small">This covers the cost of running your credit check and processing your application.</p>
                                    </div>
                                </div>
                                <div className="form-section">
                                {(this.state.is_skyward_runs_credit==0)?
                                <div>
                                    <div id="stripe_button_container_success" style={{ display: 'none' }}>
                                       <div className="alert alert-success"><i className="fa fa-check-square-o"></i> Payment Successful</div>
                                    </div> 
                                </div>:''}
                                {(this.state.is_skyward_runs_credit==0)?
                                <div id="stripe_button_container_unsuccess" style={{ display: 'block' }}>
                                    <StripeProvider apiKey={process.env.REACT_APP_STRIP_PUBLIC_KEY}>
                                            <div className="example">
                                              <Elements>
                                                <CheckoutForm handleStripeSubmit = {handleStripeSubmit.bind(this)} applicationId={this.state.appId}/>
                                              </Elements>
                                            </div>
                                    </StripeProvider>
                                </div>
                                :''}
                                <form >
                                      <div className="form-group">
                                      <div to="" className="btn signature-btn"><span className="sign-box">{this.state.signature}</span></div>
                                      <p className="add-txt-panel" style={{margin:'10px 0px 0px'}}>By clicking submit, I acknowledge that I have been provided access to <Link to data-toggle="modal" data-target="#fcraModal">A Summary of Your Rights Under the Fair Credit Reporting Act</Link> and that I have had the opportunity to review this document and make an informed decision with regards to any Reports and my application.</p>
                                      <FormGroup controlId="signature">
                                 <label></label>
                                    <FormControl
                                                value={this.state.signature}
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder=""
                                                />
                                       <span className="inline-error-class"> {this.state.signatureorerror !==''? this.state.signatureorerror:''}  </span>
                                      </FormGroup>
                                       </div>
                                       <div className="btn-wrap">
                                       {(this.state.finalErr!=undefined && this.state.finalErr!='')?<div className="alert alert-danger" style={{fontSize:'10px'}}><strong>{this.state.finalErr}</strong></div>:''}
                                       <button className="btn btn-secondary btn-submit" onClick={this.saveFinal} disabled={!this.validateStripe()}>Submit Application</button>
                                       </div>
                                       <ul className="pagination justify-content-center">
                                                <li className="page-item left"><button className="page-link" onClick={this.prevStep(["application_form_section_nine", "application_form_section_eight"])}><i className="fa fa-chevron-left" aria-hidden="true"></i> Prev</button></li>
                                                <li className="page-item mid"></li>
                                                <li className="page-item right"><button className="page-link"></button></li>
                                      </ul>                                                 
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* {this.state.submitDisabled?(<p className="text-center mt-4"><div class="btn-wrap"><button class="btn" onClick={this.saveOnePageApplication} disabled>Final Submit</button></div></p>):(<p className="text-center mt-4"><div class="btn-wrap"><button class="btn" onClick={this.saveOnePageApplication} >Final Submit</button></div></p>)} */}
                        <p className="text-center mt-4"><div class="btn-wrap"><button class="btn" onClick={this.saveOnePageApplication} disabled={this.checkSubmitDisabled()}>Final Submit</button></div></p>
                        </div>
                        

                        

                       
                   </div>
                   
                </main>
                <div id="fcraModal" className="modal fade" role="dialog">
                        <div className="modal-dialog" style={{marginTop:'2em'}}>

                        
                            <div className="modal-content">                           
                            <div className="modal-body">
                            <p>Para informacion en espanol, visite <a target="_blank" href="http://www.consumerfinance.gov/learnmore" style={{textDecoration:'underline'}}>http://www.consumerfinance.gov/learnmore</a> o escribe a la Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20006.</p>
                            <h4 className="text-center">A Summary of Your Rights Under the Fair Credit Reporting Act
</h4>
                            <p>The federal Fair Credit Reporting Act (FCRA) promotes the accuracy, fairness, and privacy of information in the files of consumer reporting agencies. There are many types of consumer reporting agencies, including credit bureaus and specialty agencies (such as agencies that sell information about check writing histories, medical records, and rental history records). Here is a summary of your major rights under the FCRA. <strong>For more information, including information about additional rights, go to <a target="_blank" href="http://www.consumerfinance.gov/learnmore" style={{textDecoration:'underline'}}>http://www.consumerfinance.gov/learnmore</a> or write to: Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20006.</strong></p>
                             <ul>
                                 <li>You must be told if information in your file has been used against you. Anyone who uses a credit report or another type of consumer report to deny your application for credit, insurance, or employment – or to take another adverse action against you – must tell you, and must give you the name, address, and phone number of the agency that provided the information.</li>       
                                 <li>You have the right to know what is in your file. You may request and obtain all the information about you in the files of a consumer reporting agency (your “file disclosure”). You will be required to provide proper identification, which may include your Social Security number. In many cases, the disclosure will be free. You are entitled to a free file disclosure if:
                                    <ul>
                                        <li>a person has taken adverse action against you because of information in your credit report;</li>
                                        <li>you are the victim of identify theft and place a fraud alert in your file;</li>
                                        <li>your file contains inaccurate information as a result of fraud;</li>
                                        <li>you are on public assistance;</li>
                                        <li>you are unemployed but expect to apply for employment within 60 days.</li>
                                    </ul>   
                                        
                                
                                </li>
                            </ul>  
                            <p>In addition, all consumers are entitled to one free disclosure every 12 months upon request from each nationwide credit bureau and from nationwide specialty consumer reporting agencies. See <a href="http://www.consumerfinance.gov/learnmore" style={{textDecoration:'underline'}}>http://www.consumerfinance.gov/learnmore</a> for additional information.</p>
                            <ul>
                                 <li><strong>You have the right to ask for a credit score.</strong> Credit scores are numerical summaries of your credit-worthiness based on information from credit bureaus. You may request a credit score from consumer reporting agencies that create scores or distribute scores used in residential real property loans, but you will have to pay for it. In some mortgage transactions, you will receive credit score information for free from the mortgage lender.</li>       
                                 <li><strong>You have the right to dispute incomplete or inaccurate information.</strong> If you identify information in your file that is incomplete or inaccurate, and report it to the consumer reporting agency, the agency must investigate unless your dispute is frivolous. See <a href="http://www.consumerfinance.gov/learnmore" style={{textDecoration:'underline'}}>http://www.consumerfinance.gov/learnmore</a> for an explanation of dispute procedures.                   </li>
                                 <li><strong>Consumer reporting agencies must correct or delete inaccurate, incomplete, or unverifiable information.</strong> Inaccurate, incomplete or unverifiable information must be removed or corrected, usually within 30 days. However, a consumer reporting agency may continue to report information it has verified as accurate.</li>
                                 <li>Consumer reporting agencies may not report outdated negative information. In most cases, a consumer reporting agency may not report negative information that is more than seven years old, or bankruptcies that are more than 10 years old.</li>
                                 <li>Access to your file is limited. A consumer reporting agency may provide information about you only to people with a valid need – usually to consider an application with a creditor, insurer, employer, landlord, or other business. The FCRA specifies those with a valid need for access.</li>
                                 <li>You must give your consent for reports to be provided to employers. A consumer reporting agency may not give out information about you to your employer, or a potential employer, without your written consent given to the employer. Written consent generally is not required in the trucking industry. For more information, go to <a href="http://www.consumerfinance.gov/learnmore" style={{textDecoration:'underline'}}>http://www.consumerfinance.gov/learnmore</a>.</li>
                                 <li>You may limit “prescreened” offers of credit and insurance you get based on information in your credit report. Unsolicited “prescreened” offers for credit and insurance must include a toll-free phone number you can call if you choose to remove your name and address from the lists these offers are based on. You may opt-out with the nationwide credit bureaus at 1-888-567- 8688.</li>
                                 <li>You may seek damages from violators. If a consumer reporting agency, or, in some cases, a user of consumer reports or a furnisher of information to a consumer reporting agency violates the FCRA, you may be able to sue in state or federal court.</li>
                                 <li>Identity theft victims and active duty military personnel have additional rights. For more information, visit <a target="_blank" href="http://www.consumerfinance.gov/learnmore" style={{textDecoration:'underline'}}>http://www.consumerfinance.gov/learnmore.</a></li>
                            </ul>  
                            <p>States may enforce the FCRA, and many states have their own consumer reporting laws. In some cases, you may have more rights under state law. For more information, contact your state or local consumer protection agency or your state Attorney General. For information about your federal rights, contact:</p>

                            <table className="table table-bordered">
                                   <thead>
                                   <tr>
                                      <th width="50%">TYPE OF BUSINESS</th> 
                                      <th>CONTACT</th>   
                                    </tr> 
                                    </thead> 
                                    <tbody>
                                   <tr>
                                      <td width="50%" valign="top">
                                      <p>1 .a. Banks, savings associations, and credit unions with total assets of over $10 billion and their affiliates.</p>
                                      <p>b. Such affiliates that are not banks, savings associations, or credit unions also should list, in addition to the Bureau:</p>
                                      <p>2. To the extent not included in item 1 above:</p>
                                      <p>a. National banks, federal savings associations, and federal branches and federal agencies of foreign banks</p>
                                      <p>b. State member banks, branches and agencies of foreign banks (other than federal branches, federal agencies, and insured state branches of foreign banks), commercial lending companies owned or controlled by foreign banks, and organizations operating under section 25 or 25A of the Federal Reserve Act</p>
                                     <p>c. Nonmember Insured Banks, Insured State Branches of Foreign Banks, and insured state savings associations</p>
                                     <p>d. Federal Credit Unions</p>
                                     <p>3. Air carriers</p>
                                     <p>4. Creditors Subject to Surface Transportation Board</p>
                                     <p>5. Creditors Subject to Packers and Stockyards Act</p>
                                     <p>6. Small Business Investment Companies</p>
                                     <p>7. Brokers and Dealers</p>
                                     <p>8. Federal Land Banks, Federal Land Bank Associations, Federal Intermediate Credit Banks, and Production Credit Associations</p>
                                     <p>9. Retailers, Finance Companies, and All Other Creditors Not Listed Above</p>
                                    </td> 
                                      <td><p>a. Bureau of Consumer Financial Protection 
                                            1700 G Street NW 
                                            Washington, DC 20006</p>
                                            <p>b. Federal Trade Commission: <br/>Consumer Response Center - FCRA <br/>
                                            Washington, DC 20580 
                                            (877) 382-4357</p>
                                            <p>a. Office of the Comptroller of the Currency<br/> Customer Assistance Group<br/> 
                                            1301 McKinney Street, Suite 3450 <br/>
                                            Houston, TX 77010-9050</p>
                                            <p>b. Federal Reserve Consumer Help Center<br/> P.O. Box 1200<br/> 
                                            Minneapolis, MN 55480</p>
                                            <p>c. FDIC Consumer Response Center <br/>
                                            1100 Walnut Street, Box #11 <br/>
                                            Kansas City, MO 64106</p>
                                            <p>d. National Credit Union Administration Office of Consumer Protection (OCP) 
                                            Division of Consumer Compliance and Outreach (DCCO) <br/>
                                            1775 Duke Street <br/>
                                            Alexandria, VA 22314</p>
                                            <p>Asst. General Counsel for Aviation Enforcement & Proceedings 
                                            Department of Transportation <br/>
                                            400 Seventh Street SW <br/>
                                            Washington, DC 20590</p>
                                            <p>Office of Proceedings, Surface Transportation Board 
                                            Department of Transportation 
                                            1925 K Street NW 
                                            Washington, DC 20423</p>
                                            <p>Nearest Packers and Stockyards Administration area supervisor</p>
                                            <p>Associate Deputy Administrator for Capital Access <br/>
                                            United States Small Business Administration <br/>
                                            406 Third Street, SW, 8th Floor 
                                            Washington, DC 20416</p>
                                            <p>Securities and Exchange Commission<br/> 
                                            100 F St NE <br/>
                                            Washington, DC 20549</p>
                                            <p>Farm Credit Administration <br/>
                                            1501 Farm Credit Drive <br/>
                                            McLean, VA 22102-5090</p>
                                            <p>FTC Regional Office for region in which the creditor operates or Federal Trade Commission: Consumer Response Center - FCRA <br/>
                                            Washington, DC 20580</p>
                                            </td>   
                                    </tr> 
                                    </tbody>    
                            </table>
                                

                              
                                <p className="text-right"><button type="button" className="btn cl-btn" data-dismiss="modal">Close  <img src="/close.png"/></button></p>
                            </div>
                          
                            </div>

                        </div>
                        </div>  
               </div>
           

        );
    }
}
export default Application1;
