import React from 'react';
import {Link} from 'react-router-dom';
import { getApiData, getToken, getUserId,getOnBoardingDone } from '../../services/Api';
import { Redirect } from 'react-router';

class HeaderTop extends React.Component {

    constructor(props) {
        super(props);
        this.nav_bar_col = React.createRef();
        
        this.state = {
            showSign: true,
            user_id: localStorage.getItem('user_id'),
            user_name: "",
            account_verify:''

          }; 
          this.onLoad = this.onLoad.bind(this);
          this.setWrapperRef1 = this.setWrapperRef1.bind(this);
          this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.onLoad();
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    setWrapperRef1(node) {
        this.wrapperRef1 = node;
        }
        handleClickOutside(event) {
         // alert(event.target.id)              
        if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
            document.getElementById('collapsibleNavbar').classList.remove("show");
          } 
       }  
    async onLoad(){
        if(getToken()){
            this.setState({'user_name':localStorage.getItem("user_name"),'showSign':false}); 
        }
    }

    render() {
        var imgUrl=localStorage.getItem('imageUrl');
        var flag=getOnBoardingDone();
        console.log("getOnBoardingDone-----",flag);
        if(imgUrl=="" || imgUrl==null){
                imgUrl="/member.png";
        }
        this.state.account_verify = localStorage.getItem('account_verified');
      
        return (
               <header className="main-header">             
                <nav className="navbar navbar-expand-lg bg-light navbar-dark">
                <Link className="navbar-brand logo" to="/frontend/landing"><img src="/wht-logo.png" width="100%" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <img src="/w-menu-icon.png"/>
                </button>
                <div className="collapse navbar-collapse " id="collapsibleNavbar" ref={this.setWrapperRef1}>
                { this.state.showSign?(
                       
                  
                     <ul className="navbar-nav mr-auto">
                     
                        <li className="nav-item" ><a href="/frontend/landing#experience" className="scroll"> The Experience</a></li>
                        <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                        <li className="nav-item"><Link to="/frontend/join" > Join Us</Link></li>
                        <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> 
                      
                      
                     
                     </ul>   
                         ):(
                    <ul className="navbar-nav mr-auto" id="test">
                        {(flag== 1) ? <li className="visible-xs-block"><Link to="/client/profile" >{imgUrl?(<img alt="Profile" src={imgUrl} width="40" className="user rounded-circle"/>):(null)}</Link></li>:(null)} 
                        <li className="nav-item"><Link to="/client/dashboard"> Home</Link></li>
                        <li className="nav-item"><Link to="/frontend/landing" > The Experience</Link></li>
                        <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                        
                        <li className="nav-item"><Link to="/client/listing/browselist" > Browse</Link></li> 
                        <li className="nav-item"><Link to="/client/listing/savelisting" > Saved Listings</Link></li>
                        

                        {/* {this.state.account_verify == 1  ? (flag== 1) ?<li className="nav-item"><Link to="/client/application" lass="nav-link">Apply</Link></li> :('')  :(null)} */}
 
  {(flag== 1 && this.state.account_verify==1 && this.state.account_verify!="") ?<li className="nav-item"><Link to="/client/application" lass="nav-link">Apply</Link></li> :('')}
                         <li className="nav-item"><Link to="/client/logout" > Logout</Link></li>
                        {(flag== 1) ? <li className="hidden-xs-block"><Link to="/client/profile">{imgUrl?(<img alt="Profile" src={imgUrl} width="40" height="40" className="user rounded-circle"/>):(null)}</Link></li>:(null)}    
                    </ul>
                       )
                    } 
                </div>  
                </nav>
                </header>
                );
    }
}
export default HeaderTop;