import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/FrontHeader';
import { Row, Col, Image, Form, Label} from "react-bootstrap";
import { postApiData} from '../../services/Api';
import {validatePhone} from '../../services/validation';

class Career extends React.Component {
    constructor(props) {
        super(props);     
        this.myRef = React.createRef() ;
        this.state = {
            shown:true,
            first_name:'',
            last_name:'',
            email:'',
            phone:'',
            message:'',

     
        };
      }
      componentDidMount() {
        window.scrollTo(0, 0);
       
      }
      handlechange=(e)=>{
        const id = e.target.id;
        const value = e.target.value;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;
        if(id==="first_name" ||id ==="last_name" || id==="message"){
            if(value.trim()==""){
                this.setState({
                [id+'_error']:"Field cannot be empty",
                [id]: value
                })
               }else{
              this.setState({
                [id+'_error']:'',
                [id]:value,
              })
            }
        }
            if(id==="email"){
                if(value.trim()==""){
                    this.setState({
                    [id+'_error']:"Field cannot be empty",
                    [id]: value
                    })
                   }
                   else if(pattern.test(document.getElementById('email').value)===false){
                    this.setState({
                        [id+'_error']:"Enter valid email format",
                        [id]: value
                        })
                   }
                   else{
                  this.setState({
                    [id+'_error']:'',
                    [id]:value,
                  })
                }
            }

         
    }

    handlePhoneChange = event => {
		this.formValidate(event.target.id,event.target.value);
		if(event.target.id==='phone'){
			if(this.isNum(event.target.value)!=true){
			  this.setState({phone:''})
			}
			else{ 
			  this.setState({phone:event.target.value});
			}
		 }
	}

    isNum(num){
		if(/^[0-9-]*$/.test(num))
		return true;
		else 
		return false;
	}

    validation = () => {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;
        return (
          this.state.first_name.trim() !="" &&
          this.state.last_name.trim() != "" &&
          pattern.test(document.getElementById('email').value)===true &&
          this.state.phone.trim() != "" &&
          this.state.message.trim() != "" 
        );
      }

      formValidate(key,value){
        switch(key){
            case 'phone':        
                 this.validatePhone(value);
                break;
            }
    }

      validatePhone(value){
        let res=validatePhone(value);
                   if(res.status==false){
                       this.setState({phone_error: 'Phone number is not valid.'});
                       document.getElementById("phone").value=res.value;
                   }else{
                       this.setState({phone_error: ''});
                       document.getElementById("phone").value=res.value;
                   }
         return res.status;
   }

   

      contactSubmit=(e)=>{
      
        e.preventDefault();
        let where=[{
          url:'/email/joinUs_form'
        }];
        const formData = new FormData();
        formData.append('first_name',this.state.first_name)
        formData.append('last_name',this.state.last_name)
        formData.append('your_email',this.state.email)
        formData.append('your_msg',this.state.message)
        formData.append('your_phone',this.state.phone)
        postApiData(where,formData).then(res=>{
         if(res){
             this.setState({
                 first_name:'',
                 last_name:'',
                 email:'',
                 phone:'',
                 message:''
             })
             document.getElementById('message').value='';
             alert('Thanks for contacting with us, we will get back to you very shortly.')  
         }
        }).catch(error=>{
    
        })
      }
    render() {
        return (
   

            <div className="career-sections FrontEnd">
                <Header /> 
                <div className="main-wrap">  
                    <div className="container">
                    <Row className="join-wrap">
                         <Col xs={12} md={6} >
                            <h1>Built For Agents, By Agents </h1>
                            <p>Real estate is a local, service-oriented business driven by individual agents and their local image. Our team is here to support agents striving to build profitable, sustainable, fulfilling businesses.</p>
                            <p><a href="#join" className="btn btn-wrap scroll">JOIN OUR TEAM <span><Image src="/b_arrow.png"/></span></a>    </p>

                         </Col>
                         <Col xs={12} md={6} >
                                <Image src="/join-img.png" width="100%" />
                         </Col>
                    </Row>        
                    <Row className="join-wrap sucess-wrap mobileFade" style={{position:'relative'}}>
                        <span className="fadetxt">WE Are</span>
                         <Col xs={12} md={6} >
                            <h2>Success Is Measured By How We Perform Together</h2>
                            <p>At Skyward Real Estate, our goal is to be an organization that builds industry leaders. Our motivating company culture empowers agents to provide clients with world-class service. </p>
                            <Image src="/mb-success.png" width="100%"  className="visible-xs"/>
                            <h6>OUR CORE VALUES</h6>
                            <ul>
                                <li>We care about one another as teammates and human beings. We are an organization that embraces everyone regardless of gender, race, religion, or appearance. </li>
                                <li>We treat everyone with respect. We go out of our way to open ourselves to everyone and make those around us feel that they are on the same level. Our relationships are our greatest asset.</li>
                                <li>We work hard. We are absolutely relentless in learning and expanding our training opportunities. We constantly ask ourselves how we can do better, and seek to build relationships with those around us who strive for the same.  </li>
                                <li>We have remarkable self-discipline. We learn our market, search for new listings and prospective clients daily. Every day we have a plan aimed at improving our business.</li>
                                <li>We are grateful. We understand that life is fragile and recognize how lucky we are to be alive, in business with each other, and living in the greatest city in the world. 
                                </li>                               
                            </ul>
                            {/* <ul id="expend" class="collapse">
                                <li>We care about one another as teammates and human beings. We are an organization that embraces everyone regardless of gender, race, religion, or appearance. </li>
                                <li>We treat everyone with respect. We go out of our way to open ourselves to everyone and make those around us feel that they are on the same   level. Our relationships are our greatest asset</li>
                                                        
                            </ul> */}

                            {/* <Link to data-toggle="collapse" data-target="#expend" className="expend">EXPAND</Link> */}

                         </Col>
                         <Col xs={12} md={6} className="hidden-xs">
                                <Image src="/success.png" width="100%"  className="ml-5 "/>
                         </Col>
                    </Row>        

                    </div>

                    <div className="tesimonial-section">
                         <div className="container">
                              <div className="info">
                                <span className="iconbox"><Image src="/b_comma.png"/></span>
                               <div style={{maxWidth:'800px', margin:'0px auto'}}>
                                   <p><em>I love working at Skyward because the people here are very hard working and willing to help others. Skyward has fought hard to create an environment that breeds success for everyone and not competition against agents. Skyward provides all of the training and coaching right at your fingertips while still allowing each individual agent to create their own goals and strive to create the business that best suits them.</em></p>
                                <p className="name">Brittany Shaffer</p>
                                <p className="duration"> Agent for 4 years</p>
                                </div>
                              </div>
                          </div>
                    </div>
                    <div className="container">
                            <hgroup style={{maxWidth:'800px', margin:'0px auto'}}>
                             <h2 className="text-center">Technology-Driven Solutions For Your Business</h2>
                            <p className="text-center">Per-agent productivity is the most important marker of success at our brokerage. We are here to provide cutting-edge technology and resources to assist you in an ever-changing marketplace. </p>
                            </hgroup>
                    <Row className="join-wrap sucess-wrap">
                         <Col xs={12} lg={4} >                          
                                <div className="solution-box">
                                        <h4>3D Virtual Tours Of All Brokerage Listings</h4>
                                        <p>The ability to create 3D virtual listing tours  saves both our agents and clients the time and effort of traveling to multiple showings a day. </p>    
                                        <span className="iconbox"><Image src="/b_star.png"/></span>                                
                                </div>
                           
                         </Col>
                         <Col xs={12} lg={4} >                          
                                <div className="solution-box">
                                        <h4>Work On Your Business From Anywhere</h4>
                                        <p>With a number of locations around the city, and a cloud-based platform, agents have the freedom to work wherever their business takes them.  </p>    
                                        <span className="iconbox"><Image src="/o_star.png"/></span>                                
                                </div>
                           
                         </Col>
                         <Col xs={12} lg={4} >                          
                                <div className="solution-box">
                                        <h4>Proprietary In-House Agent Dashboard</h4>
                                        <p>Our state-of-the-art agent dashboard is a hub for free advertising, client management, educational resources, and is also RLS/Localize/Renthop syndicated.</p>    
                                        <span className="iconbox"><Image src="/p_star.png"/></span>                                
                                </div>
                           
                         </Col>
                         </Row>
                         <Row className="join-wrap sucess-wrap">
                         <Col lg={2} >         
                         </Col>
                         <Col lg={4} className="col-lg-offset-2">                          
                                <div className="solution-box">
                                        <h4>Automated Application Compiling</h4>
                                        <p>Say goodbye to paperwork. With our automated compiling tools, agents have easy and instant access to their client's documents and complete application.  </p>    
                                        <span className="iconbox"><Image src="/lg-star.png"/></span>                                
                                </div>
                           
                         </Col>
                         <Col xs={12} lg={4} >                          
                                <div className="solution-box">
                                        <h4> On-Demand Showing Application</h4>
                                        <p>Our on-demand showing application allows agents to instantly confirm appointments, distribute extra leads, and claim company provided showings. </p>    
                                        <span className="iconbox"><Image src="/g-star.png"/></span>                                
                                </div>
                           
                         </Col>
                       
                    </Row>
                    </div> 

                      <div className="container">
                    <Row className="join-wrap sucess-wrap">
                         <Col xs={12} md={6} >
                            <h2>Agents That Learn Together Perform Better</h2>
                            <p>With years of experience in the NYC market, and a national partnership with Keller Williams, we are able to share valuable resources and insights to all of our agents. Our success is measured by your growth.</p>
                         

                         </Col>
                         <Col xs={12} md={6} >
                                <Image src="/learn.png" width="100%" />
                         </Col>
                    </Row>

                    <Row className="join-wrap sucess-wrap befefit-wrap">
                       
                       
                        <Col xs={12} md={12} >   
                        <h6>HOW DO WE ASSIST IN YOUR GROWTH?</h6>
                        </Col>
                         <Col xs={12} md={4} >
                             <div className="widget">
                                <Image src="/b_star.png" width="180"/>
                                <div className="info">
                                    <h6>Dedicated Classes</h6>
                                     <p>Our training sessions in partnership with Keller Williams bring unmatched educational opportunities to assist our agents in developing the comprehensive skill set needed to be successful in the fast-paced NYC market and beyond.</p>
                                </div>
                            </div>
                         </Col>
                         <Col xs={12} md={4} >
                         <div className="widget">
                         <Image src="/o_star.png" width="180"/>
                            <div className="info">
                                <h6>Branding Assistance</h6>
                                <p>Skyward is proud to offer professionally designed marketing templates that can be easily used for all your branding needs, whether it's a 10 million dollar listing or a 3 thousand dollar rental.</p>
                            </div>   
                          </div>  
                         </Col>
                         <Col xs={12} md={4} >
                         <div className="widget">
                         <Image src="/p_star.png" width="180"/>
                            <div className="info">
                                <h6>National Referral Network</h6>
                                <p>With over 160,000 agents in the international KW network, our agents are uniquely positioned to assist their clients no matter where their search takes them.</p>
                            </div>   
                         </div>

                         </Col>
                       
                      
                    </Row>         
                    

                    </div> 
                
                    <div className="tesimonial-section get-in-touch" id="join">
                         <div className="container">
                              <div className="info">
                                <span className="iconbox"><Image src="/b_star.png"/></span>
                                <hgroup style={{maxWidth:'800px', margin:'0px auto'}}>
                                    <h2 className="text-center">Get In Touch</h2>
                                    <p className="text-center">We are always looking for bright and professional agents to join our team. With our cutting-edge technology, unparalleled training, and motivating company culture, agents are destined for success in the industry.  </p>
                                 </hgroup>
                                 <Row>  
                                   
                                        <Col xs={12} lg={6}>
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text"  className="form-control" id="first_name" value={this.state.first_name} onChange={this.handlechange}/>
                                            <br />
                                        <span className="inline-error-class">
                                          {" "}
                                          {this.state.first_name_error !== ""
                                            ? this.state.first_name_error
                                            : ""}
                                        </span>  
                                        </div>
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text"  className="form-control" id="last_name" value={this.state.last_name} onChange={this.handlechange}/>
                                            <br />
                                      <span className="inline-error-class">
                                        {" "}
                                        {this.state.last_name_error !== ""
                                          ? this.state.last_name_error
                                          : ""}
                                      </span>  
                                        </div>
                                        <div className="form-group">
                                            <label>Email address</label>
                                            <input type="email"  className="form-control" id="email"
                                            value={this.state.email}
                                            onChange={this.handlechange}/>
                                             <br />
                                          <span className="inline-error-class">
                                            {" "}
                                            {this.state.email_error !== ""
                                              ? this.state.email_error
                                              : ""}
                                          </span>  
                                        </div>
                                        <div className="form-group">
                                            <label>Phone Number</label>
                                            <input type="text"  className="form-control" id="phone"
                                              value={this.state.phone}
                                              onChange={this.handlePhoneChange}
                                              minLength='12' 
                                              maxLength='12'/>
                                              <br />
                                            <span className="inline-error-class">
                                              {" "}
                                              {this.state.phone_error !== ""
                                                ? this.state.phone_error
                                                : ""}
                                            </span>  
                                        </div>
                                        </Col> 
                                        <Col xs={12} lg={6}>
                                        <div className="form-group">
                                            <label>MESSAGE</label>
                                            <textarea className="form-control" style={{height:'398px'}} id="message" onChange={this.handlechange}></textarea>
                                            <br />
                          <span className="inline-error-class">
                            {" "}
                            {this.state.message_error !== ""
                              ? this.state.message_error
                              : ""}
                          </span>  
                                        </div>
                                 
                                        </Col> 
                                     
                                 </Row> 
                                 <p className="text-center mt-3"><a href="" onClick={this.contactSubmit} className={this.validation()?'btn btn-wrap btn-submit':'btn btn-wrap btn-submit disableGetInTouch'} >SUBMIT REQUEST<span><Image src="/b_arrow.png"/></span></a>    </p>

                              </div>
                          </div>
                    </div>
                    <div className="Newskyward-design">
                <footer className>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> 
                     <li className="nav-item"><a href="https://blog.moveskyward.com/" target="_blank" > Blog </a></li> 
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                     
                  
                    
                    </ul>   
                    <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img className='logo_1st' src="/home-icon.png"/></li>
                       </ul>
                       <img src="/NYCFC_AP3_Web.jpg"/>
                       <div className="logo"></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1'>*The Skyward Team @ Keller Williams NYC and Skyward Steel, LLC is committed to adhering to the guidelines of The New York State Fair Housing Regulations. <a style={{textDecoration:'underline'}} href="https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf" target="_blank">To view The Fair Housing Notice-Please click here</a>
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="https://kwnyc.com/app/uploads/2022/03/Standardized-Operating-Procedure-for-Purchasers-of-Real-Estate.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>
<br></br>
*The website to the Team's brokerage (Keller Williams NYC) is <a href="https://kwnyc.com" style={{textDecoration:'underline'}} target="_blank">www.kwnyc.com.</a>
</p>  </div>
               </footer> 

                </div>
                </div>   
            </div>

                );
    }
}
export default Career;
