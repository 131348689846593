import React from 'react';
import { Link } from 'react-router-dom';
import HeaderVR from '../common/HeaderVR'
class Philanthropy extends React.Component {

  constructor(props) {
    super(props);   
    this.state = {     
 
    };
  
  }
 


    render() {

      
        return (
   
          
            <div className="w-bg FrontEnd Newskyward-design" style={{height:'100vh'}} ref={this.myRef}>
             <HeaderVR/> 
             <div className="conSnapScroll" >
                  
             <div className="showroom-section Join-section philanthropy" style={{background:'url(/join-bg.png) no-repeat  center center', backgroundSize:'cover'}}>
                    <div className="caption">
                      <div className="container bg-white philanthropy-box">
                          <img src="/skywardcare-logo.png" className="mb-4"/>
                      <p style={{fontSize:'28px'}}>Service to others is the rent you pay for your room here on Earth.<br/> -Muhammad Ali</p><p>At Skyward, practicing gratitude is one of our core values. We believe that an essential part of this practice is supporting causes we care about. For every transaction our agents close, Skyward donates to organizations that are dedicated to building community, growing leaders, and creating a better future for all. A few of our favorites are listed below. We encourage you to give with us.</p>
                      <a href="#join" className="btnlight scroll">PROJECTS</a>
                      </div>
                     
                                                     
                        </div>
                       
                  
                     
                    </div>
                    <div className="panel-2 joinInfo" id="experience">
                    <div className="container workbody" >
                          
                          
                            <div className="experience-section jointheGiving" id="join">
                                <div className="exp-content">
                                <h1>JOIN THE GIVING</h1>
                                         <div className="row">
                                           <div className="col-md-4 infoWrap">                                            
                                               <h2><a href="https://donatenow.networkforgood.org/potsbronx" target="_blank" >POTS</a></h2>
                                                <p className="InfoTxt"><a href="https://donatenow.networkforgood.org/potsbronx" target="_blank" >POTS (Part of the Solution) is a ‘one-stop shop’ offering a wide array of services designed to help low-income New Yorkers move from crisis to stability and self-sufficiency.</a></p>
                                               
                                            </div>

                                            <div className="col-md-4 infoWrap">                                            
                                               <h2><a target="_blank" href="https://action.aclu.org/give/become-aclu-member-multistep?ms=menu_join&ms_aff=NAT&ms_chan=web&initms=menu_join&initms_aff=NAT&initms_chan=web">ACLU</a></h2>
                                              <p className="InfoTxt"> <a target="_blank" href="https://action.aclu.org/give/become-aclu-member-multistep?ms=menu_join&ms_aff=NAT&ms_chan=web&initms=menu_join&initms_aff=NAT&initms_chan=web">The ACLU works tirelessly in courts, legislatures, and communities to defend and preserve the Constitution’s promise of liberty for everyone in our country.</a></p>
                                              
                                            </div>

                                            <div className="col-md-4 infoWrap">                                            
                                               <h2><a target="_blank" href="https://wingsworldquest.kindful.com/">WINGS</a></h2>
                                               <p className="InfoTxt"><a target="_blank" href="https://wingsworldquest.kindful.com/" > WINGS WorldQuest recognizes and supports extraordinary women in science and exploration through unrestricted grants, building community, and inspiring future leaders.</a></p>
                                            
                                            </div>


                                          
                                         
                                        </div>

                                           

                                </div>
                             
                            </div>
                          
                        </div>
                       
                  
                    </div>
                    <div className="Newskyward-design">
                    <footer  style={{top:'-12px'}}>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> 
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                    
                  
                    
                    </ul>   
                    <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img src="/home-icon.png"/></li>
                       </ul>
                       <img src="/NYCFC_AP3_Web.jpg"/>
                       <div className="logo"></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1'> *The Skyward Team @ Keller Williams NYC and Skyward Steel, LLC is committed to adhering to the guidelines of The New York State Fair Housing Regulations. <a style={{textDecoration:'underline'}} href="https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf" target="_blank">To view The Fair Housing Notice-Please click here</a>
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="https://kwnyc.com/app/uploads/2022/03/Standardized-Operating-Procedure-for-Purchasers-of-Real-Estate.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>
<br></br>
*The website to the Team's brokerage (Keller Williams NYC) is <a href="https://kwnyc.com" style={{textDecoration:'underline'}} target="_blank">www.kwnyc.com.</a>
</p>                </div>
               </footer> 
              
</div>

              </div>
              
    </div>

                );
    }
}
export default Philanthropy;
